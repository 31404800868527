/* eslint-disable no-empty */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useMediaQuery } from '@react-hook/media-query';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { FiArrowLeft, FiLock } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { phoneNumberMask, documentNumberMask } from '../../utils/masks';

import logo from '../../Assets/whiteLogo.png';
import check from '../../Assets/icons/check.svg';
import remove from '../../Assets/icons/remove.svg';

import vip from '../../Assets/icons/vip-card.svg';
import suitcase from '../../Assets/icons/suitcase.svg';
import manager from '../../Assets/icons/manager.svg';
import partner from '../../Assets/icons/partner.svg';

import { Background, SignUpContainer, Container } from './styles';
import Avatar from '../../components/Avatar';
import Tooltip from '../../components/Tooltip';
import SignInInput from '../SignIn/SignInInput';
import SignInButton from '../SignIn/SignInButton';
import { useToast } from '../../hooks/toast';
import { useSignUp, useGetReferrer, GetReferrer } from '../../services/api';

interface ISignUpData {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  phone_number: string;
  document_number: string;
  profile_role: string;
  referrer_id: string;
}

const SignUpPlans: React.FC = () => {
  const signUp = useSignUp();
  const getReferrer = useGetReferrer();
  const history = useHistory();
  const { addToast } = useToast();

  const activateCarousel = useMediaQuery('only screen and (max-width: 991px)');
  const formRef = useRef<FormHandles>(null);

  const hash = history.location.search.replace('?hash=', '');
  const [referrerData, setReferrerData] = useState<GetReferrer['Response']>({
    name: '',
    avatar_url: '',
    referrer_id: '',
  });
  const [inputType, setInputType] = useState({
    password: 'password',
    password_confirmation: 'password',
  });

  const [dummyData, setDummyData] = useState<ISignUpData>({
    name: '',
    document_number: '',
    phone_number: '',
    email: '',
    password: '',
    password_confirmation: '',
    profile_role: 'Seller',
    referrer_id: referrerData.referrer_id,
  });

  const [currentPlanCard, setCurrentPlanCard] = useState<number>(0);
  const updateCarouselCard = (newCardIndex: number) => {
    setCurrentPlanCard(newCardIndex);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getReferrer({ seller_hash: hash });
        setReferrerData(res);
        setDummyData({ ...dummyData, referrer_id: res.referrer_id });
      } catch (error) {}
    })();
  }, [hash]);

  const handleSignUp = async () => {
    try {
      const handleValidation = async () => {
        const schema = yup.object().shape({
          name: yup.string().required('Nome é um campo obrigatório'),
          email: yup.string().email('email inválido'),
          password: yup
            .string()
            .min(8, 'Senha deve conter no mínimo 8 caracteres'),
          password_confirmation: yup
            .string()
            .equals([dummyData.password], 'Confirmação de senha incorreta'),
          phone_number: yup
            .string()
            .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, 'Telefone deve ser válido'),
          document_number: yup
            .string()
            .test('valida-cpf', 'Documento inválido', value => {
              if (value && value.length === 14) {
                console.log('cpf');
                const cpfValidator = cpf.isValid(value || '');
                return cpfValidator;
              }
              if (value && value.length > 14) {
                console.log('cnpj');

                const cnpjValidator = cnpj.isValid(value || '');
                return cnpjValidator;
              }
              return false;
            }),
          profile_role: yup
            .string()
            .equals(
              ['Seller'],
              'Houve um erro interno, por favor contate o suporte',
            ),
        });
        await schema.validate(
          { ...dummyData, name: dummyData.name.trim() },
          { abortEarly: false },
        );
      };
      await handleValidation();

      await signUp(dummyData);
      addToast({
        type: 'success',
        title: '',
        description: 'Recebemos seu cadastro, ele será aprovado em até 24h.',
      });
      history.push('/');
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: `${error.errors[0]}`,
        });
      }
    }
  };

  const PlanCards = [
    <div className="license vip">
      <div className="license-name">
        <img
          style={{ marginTop: '1rem' }}
          src={vip}
          height="65rem"
          alt="tes23"
        />
        <ul>
          <li>
            <span>
              Licença <strong>VIP</strong>
            </span>
          </li>
        </ul>
      </div>
      <h3 className="price">VOUCHER 100%</h3>
      <div className="bonus">
        <ul>
          <img src={check} alt="check" width="20rem" />
          <li>Acesso a Plataforma</li>
          <img src={check} alt="check" width="20rem" />
          <li>Comissão - Venda Direta</li>
          <img src={check} alt="check" width="20rem" />
          <li>Bônus - Recorrência Direta (3% a 7%)</li>
          <img src={remove} alt="check" width="20rem" />
          <li>Bônus - Venda Indireta</li>
          <img src={remove} alt="check" width="20rem" />
          <li>Bônus - Debute</li>
          <img src={remove} alt="check" width="20rem" />
          <li>Bônus - Recorrência Indireta</li>
          <img src={remove} alt="check" width="20rem" />
          <li>
            Bônus - Expansão 10% a 20% <br />
            <small>* Conforme percentual descrito no plano de carreira</small>
          </li>
        </ul>
      </div>
      <div className="border" />
      <p className="title">Cursos e Qualificação</p>
      <div className="course">
        <ul>
          <img src={check} alt="check" width="20rem" />
          <li>Técnicas Avançadas de Vendas</li>
          <img src={check} alt="check" width="20rem" />
          <li>Treinamento de Produtos e Serviços</li>
          <img src={check} alt="check" width="20rem" />
          <li>Descontos e Bolsas de Estudos Parciais</li>
          <img src={remove} alt="check" width="20rem" />
          <li>Curso de Comunicação Profissional</li>
        </ul>
      </div>
      <h4>Licença gratuita por tempo permanente</h4>
      <button
        type="button"
        onClick={() => {
          window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
          });
        }}
      >
        QUERO MINHA LICENÇA
      </button>
    </div>,
    <div className="license sales">
      <span className="embreve">EM BREVE</span>
      <div className="license-name">
        <img height="65rem" src={suitcase} alt="test" />
        <ul>
          <li>
            <span>
              Licença <strong>SALES</strong>
            </span>
          </li>
        </ul>
      </div>
      <h3 className="price"> R$ 1.490</h3>
      <div className="bonus">
        <ul>
          <img src={check} alt="check" width="20rem" />
          <li>Acesso a Plataforma</li>
          <img src={check} alt="check" width="20rem" />
          <li>Comissão - Venda Direta</li>
          <img src={check} alt="check" width="20rem" />
          <li>Bônus - Recorrência Direta (3% a 7%)</li>
          <img src={remove} alt="check" width="20rem" />
          <li>Bônus - Venda Indireta</li>
          <img src={remove} alt="check" width="20rem" />
          <li>Bônus - Debute</li>
          <img src={remove} alt="check" width="20rem" />
          <li>Bônus - Recorrência Indireta</li>
          <img src={remove} alt="check" width="20rem" />
          <li>
            Bônus - Expansão 10% a 20% <br />
            <small>* Conforme percentual descrito no plano de carreira</small>
          </li>
        </ul>
      </div>
      <div className="border" />
      <p className="title">Cursos e Qualificação</p>
      <div className="course">
        <ul>
          <img src={check} alt="check" width="20rem" />
          <li>Técnicas Avançadas de Vendas</li>
          <img src={check} alt="check" width="20rem" />
          <li>Treinamento de Produtos e Serviços</li>
          <img src={check} alt="check" width="20rem" />
          <li>Descontos e Bolsas de Estudos Parciais</li>
          <img src={remove} alt="check" width="20rem" />
          <li>Curso de Comunicação Profissional</li>
        </ul>
      </div>
    </div>,
    <div className="license manager">
      <span className="embreve">EM BREVE</span>
      <div className="license-name">
        <img src={manager} height="65rem" alt="manager" />
        <ul>
          <li>
            <span>
              Licença <strong>MANAGER</strong>
            </span>
          </li>
        </ul>
      </div>
      <h3 className="price"> R$ 2.490</h3>
      <div className="bonus">
        <ul>
          <img src={check} alt="check" width="20rem" />
          <li>Acesso a Plataforma</li>
          <img src={check} alt="check" width="20rem" />
          <li>Comissão - Venda Direta</li>
          <img src={check} alt="check" width="20rem" />
          <li>Bônus - Recorrência Direta (3% a 8%)</li>
          <img src={check} alt="check" width="20rem" />
          <li>Bônus - Venda Indireta</li>
          <img src={check} alt="check" width="20rem" />
          <li>Bônus - Debute</li>
          <img src={check} alt="check" width="20rem" />
          <li>Bônus - Recorrência Indireta</li>
          <img src={remove} alt="check" width="20rem" />
          <li>
            Bônus - Expansão 10% a 20% <br />
            <small>* Conforme percentual descrito no plano de carreira</small>
          </li>
        </ul>
      </div>
      <div className="border" />
      <p className="title">Cursos e Qualificação</p>
      <div className="course">
        <ul>
          <img src={check} alt="check" width="20rem" />
          <li>Técnicas Avançadas de Vendas</li>
          <img src={check} alt="check" width="20rem" />
          <li>Treinamento de Produtos e Serviços</li>
          <img src={check} alt="check" width="20rem" />
          <li>Descontos e Bolsas de Estudos Parciais</li>
          <img src={remove} alt="check" width="20rem" />
          <li>Curso de Comunicação Profissional</li>
        </ul>
      </div>
    </div>,
    <div className="license partner">
      <span className="embreve">EM BREVE</span>
      <div className="tooltip">
        <Tooltip isMultinivelScreen>
          <p>Acesso ao Bônus Partners</p>
          <p>O PARTNER poderá representar a</p>
          <p>Educacional WUP assim que for liberada</p>
          <p>as vendas das licenças. O bônus será de</p>
          <p>10%, 20% das licenças que você vender.</p>
        </Tooltip>
      </div>
      <div className="license-name">
        <img src={partner} height="65rem" alt="partner" />

        <ul>
          <li>
            <span>
              Licença <strong>PARTNER</strong>
            </span>
          </li>
        </ul>
      </div>
      <h3 className="price"> R$ 4.990</h3>
      <div className="bonus">
        <ul>
          <img src={check} alt="check" width="20rem" />
          <li>Acesso a Plataforma</li>
          <img src={check} alt="check" width="20rem" />
          <li>Comissão - Venda Direta</li>
          <img src={check} alt="check" width="20rem" />
          <li>Bônus - Recorrência Direta (3% a 10%)</li>
          <img src={check} alt="check" width="20rem" />
          <li>Bônus - Venda Indireta</li>
          <img src={check} alt="check" width="20rem" />
          <li>Bônus - Debute</li>
          <img src={check} alt="check" width="20rem" />
          <li>Bônus - Recorrência Indireta</li>
          <img src={check} alt="check" width="20rem" />
          <li>
            Bônus - Expansão 10% a 20% <br />
            <small>* Conforme percentual descrito no plano de carreira</small>
          </li>
        </ul>
      </div>
      <div className="border" />
      <p className="title">Cursos e Qualificação</p>
      <div className="course">
        <ul>
          <img src={check} alt="check" width="20rem" />
          <li>Técnicas Avançadas de Vendas</li>
          <img src={check} alt="check" width="20rem" />
          <li>Treinamento de Produtos e Serviços</li>
          <img src={check} alt="check" width="20rem" />
          <li>Descontos e Bolsas de Estudos Parciais</li>
          <img src={check} alt="check" width="20rem" />
          <li>Curso de Comunicação Profissional</li>
        </ul>
      </div>
    </div>,
  ];

  return (
    <Container>
      <Background />
      {/* <Licenses>
        <div className="intro">
          <span />
          <img src={logo} alt="Logo Fael" width="176" className="animeTop" />
        </div>

        {activateCarousel
          ? (
            <div>
              <Carousel
                value={currentPlanCard}
                onChange={updateCarouselCard}
                plugins={[
                  'infinite',
                  'arrows',
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: 4,
                    },
                  },
                ]}
              >
                {PlanCards.map((pc) => (
                  <div style={{ height: '100%' }}>{pc}</div>
                ))}
              </Carousel>

              <Dots
                value={currentPlanCard}
                onChange={updateCarouselCard}
                number={4}
                className="carousel-dots"
              />
            </div>
          )
          : (
            <div className="licenses-container">
              {PlanCards.map((pc) => pc)}
            </div>
          )}

        <button
          className="biggerBtn"
          type="button"
          onClick={() => {
            window.scroll({
              top: document.body.offsetHeight,
              left: 0,
              behavior: 'smooth',
            });
          }}
        >
          QUERO MINHA LICENÇA
        </button>
        <div className="line" />
      </Licenses> */}

      <SignUpContainer>
        <img src={logo} alt="Logo Fael" width="176" className="animeTop" />

        <Form ref={formRef} onSubmit={handleSignUp} className="animeTop">
          {referrerData.name.length ? (
            <p id="text-intro">
              Você está sendo indicado por
              <Avatar
                avatar_url={referrerData.avatar_url}
                className="avatar"
                widthAndHeightPicture="2"
              />
              <span>{referrerData.name}</span>
            </p>
          ) : (
            <span />
          )}
          <SignInInput
            name="name"
            placeholder="Nome completo"
            value={dummyData.name}
            onChange={e =>
              setDummyData({ ...dummyData, name: e.target.value.trimStart() })
            }
          />
          <SignInInput
            name="email"
            type="email"
            placeholder="Digite seu e-mail"
            value={dummyData.email}
            onChange={e =>
              setDummyData({
                ...dummyData,
                email: e.target.value.toLowerCase(),
              })
            }
          />
          <SignInInput
            name="phone"
            type="text"
            placeholder="Telefone"
            value={dummyData.phone_number}
            onChange={e =>
              setDummyData({
                ...dummyData,
                phone_number: phoneNumberMask(e.target.value),
              })
            }
          />
          <SignInInput
            name="document_number"
            type="text"
            placeholder="CPF/CNPJ apenas números"
            value={dummyData.document_number}
            onChange={e =>
              setDummyData({
                ...dummyData,
                document_number: documentNumberMask(e.target.value),
              })
            }
          />
          <SignInInput
            name="password"
            type={inputType.password}
            value={dummyData.password}
            placeholder="Senha"
            icon={FiLock}
            onChange={e =>
              setDummyData({ ...dummyData, password: e.target.value })
            }
          />

          <SignInInput
            name="password_confirmation"
            icon={FiLock}
            type={inputType.password_confirmation}
            value={dummyData.password_confirmation}
            placeholder="Confirmar senha"
            onChange={e =>
              setDummyData({
                ...dummyData,
                password_confirmation: e.target.value,
              })
            }
          />

          <SignInButton type="submit">Finalizar Cadastro</SignInButton>
        </Form>
        <a href="/">
          <FiArrowLeft />
          Voltar para LogOn
        </a>
      </SignUpContainer>
    </Container>
  );
};

export default SignUpPlans;
