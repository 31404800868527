import React from 'react';

import { ContainerIntro } from './styles';

interface IIntroProps {
  image: string;
  name: string;
  text: string;
}

const Intro: React.FC<IIntroProps> = ({ image, name, text }: IIntroProps) => (
  <ContainerIntro>
    <img src={image} alt="imagem" />
    <div>
      <h1>{name}</h1>
      <h2>{text}</h2>
    </div>
  </ContainerIntro>
);

export default Intro;
