import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Intro from '../../../components/Intro';
import Accordion from '../../../components/Accordion';

import iconHelp from '../../../Assets/icons/button-help.svg';
import { ProfileGenericContainer, HelpCenter } from './styles';
import { IFaq } from './models';
import { IFaqBody } from '../../../models/Faq';
import useDataPaginator from '../../../hooks/dataPaginator';
import useDebouncedText from '../../../hooks/debouncedText';
import { useGetFAQ } from '../../../services/api';

interface IEntityGroup {
  [key: string]: IFaqBody[];
}

const groupedByCategory = (info: IFaqBody[]) =>
  info.reduce(
    (categoriesObject: IEntityGroup, item) => ({
      ...categoriesObject,
      [item.category]: [...(categoriesObject[item.category] || []), item],
    }),
    {},
  );

const UserHelpCenter: React.FC = () => {
  const [accordionOn, setAccordionOn] = React.useState(false);
  const [filterName, setFilterName] = useDebouncedText('');

  const getFAQ = useGetFAQ();

  const [data, setData, dataListLoading, checkDataListBottom] =
    useDataPaginator<IFaqBody, [string]>({
      filters: [filterName],
      storeNewData: async filters => {
        const res = await getFAQ({
          filter_name: filters[0],
        });
        console.log('---<<>>', res);
        return {
          nextCursor: res.cursor.afterCursor,
          shouldResetData: !res.cursor.beforeCursor,
          nextData: res.data,
        };
      },
    });

  return (
    <ProfileGenericContainer>
      <HelpCenter>
        <Intro
          image={iconHelp}
          name="Central de Ajuda"
          text="Dúvidas Frequentes"
        />
        <section className="account">
          <div className="search-container">
            <form>
              <input
                placeholder=" Buscar "
                onChange={e => setFilterName(e.target.value)}
              />
            </form>
          </div>
          {data.length ? (
            Object.keys(groupedByCategory(data)).map((category: string) => (
              <>
                <h3>{category}</h3>
                <div className="sales-attendance">
                  {groupedByCategory(data)[category].map(item => (
                    <Accordion
                      key={`${item.id} sales`}
                      title={item.question}
                      content={item.description}
                    />
                  ))}
                </div>
              </>
            ))
          ) : (
            <strong>Não encontramos nenhuma pergunta</strong>
          )}
          <Link style={{ marginTop: '3rem' }} to="/profile" id="back">
            Voltar
          </Link>
        </section>
      </HelpCenter>
    </ProfileGenericContainer>
  );
};

export default UserHelpCenter;
