import styled from 'styled-components';

import iconSearch from '../../../Assets/icons/iconSearch.svg';

export const TrainingContainer = styled.div`
  background-color: #fff;
  border-radius: 2rem;
  color: var(--black-background);

  padding: 5.2rem;
  @media (max-width: 991px) {
    border-radius: 0;
  }
  @media (max-width: 420px) {
    padding: 3.2rem;
  }

  max-width: 120rem;
  margin: 5.4rem auto 4rem;

  @media (max-width: 1200px) {
    padding: 0rem 7rem 2rem;
  }
  @media (max-width: 1100px) {
    padding: 0rem 5.5rem 2rem;
  }
  @media (max-width: 991px) {
    padding: 0;
  }

  .scroll-videos-container {
    ul {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;
      justify-content: center;

      @media (max-width: 1400px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 990px) {
        grid-template-columns: repeat(2, 1fr);
        margin: 0 1rem;
      }
      .video-card {
        display: grid;
        /* width: rem; */
        margin: 1rem 0;
        &:hover {
          cursor: pointer;
        }
      }

      .scroll-video {
        display: block;
        border-radius: 2rem 2rem 0 0;
        overflow: hidden;
        position: relative;
      }

      img.scroll-video-frame {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 15rem;
        background-image: url('https://i.vimeocdn.com/video/1041881642_640x360.jpg?r=pad');
        background-position: center;
        background-size: cover;
        background-color: transparent;
        object-fit: cover;
      }

      .scroll-thumb .scroll-image {
        height: 12rem;
      }

      .scroll-video-name {
        font-size: 1.4rem;
        font-weight: 700;
        padding: 1rem 1.3rem;
        background-color: var(--primary-color);
        border-radius: 0 0 2rem 2rem;
      }
      .scroll-video-name > p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        color: var(--black-background);
      }
    }

    margin: auto 0;
    overflow-y: auto;
    z-index: 1;

    .video-container {
      width: 100%;
      height: 50rem;
      padding: 3rem;

      margin: 0 auto 3rem;

      color: var(--background-color);
      font-size: 3rem;
      font-family: 'Object Sans';

      p {
        display: inline-block;
        margin: 0 1.5rem;
      }

      iframe {
        border-radius: 2rem;
        margin-top: 1rem;

        width: 100%;
        height: 98%;
        z-index: 10;

        @media (max-width: 420px) {
          border-radius: 0;
          height: 80%;
        }
      }

      @media (max-width: 420px) {
        height: 30rem;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        font-size: 2rem;
        margin: 0 auto 1rem;
      }
    }

    @media (max-width: 991px) {
      border-radius: 0;
    }
    ::-webkit-scrollbar-track {
      background-color: #c4c4c4;
      border-radius: 2rem;
    }
    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      background: #646464;
      border-radius: 2rem;
    }
  }
`;
