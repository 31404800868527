import { useState } from 'react';

const useDebouncedText: (
  initialText?: string,
  debounceTimeout?: number,
) => [string, (textValue: string) => void] = (
  initialText = '',
  debounceTimeout = 600,
) => {
  const [debouncedText, setDebouncedText] = useState<string>(initialText);
  const [searchTypingTimeout, setSearchTypingTimeout] = useState<number>(0);

  const onChangeSearchText = (textValue: string) => {
    if (searchTypingTimeout) {
      // console.log('Cleared', searchTypingTimeout)
      clearTimeout(searchTypingTimeout);
    }

    const newTimeout = window.setTimeout(() => {
      // console.log({ old: debouncedText, new: textValue });
      setDebouncedText(textValue);
    }, debounceTimeout);

    // console.log('New timeout', newTimeout);
    setSearchTypingTimeout(newTimeout);
  };

  return [debouncedText, onChangeSearchText];
};

export default useDebouncedText;
