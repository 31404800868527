/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { useLoadingBar } from '../../../hooks/loadingBar';
import {
  ITrainingVideoBody,
  useGetCategorizedVideos,
} from '../../../services/api';

import SkeletonLoader from '../../../components/SkeletonLoader';

import { TrainingContainer } from './styles';

import useDataPaginator from '../../../hooks/dataPaginator';
import Intro from '../../../components/Intro';
import onlineCourse from '../../../Assets/icons/online-course.svg';

interface locationType {
  title: string | undefined;
  link_url: string | undefined;
}

const TrainingCategory: React.FC = () => {
  const { category } = useParams<{ category: string }>();
  const { state } = useLocation<locationType>();

  const { token } = useAuth();

  const getCategorizedVideos = useGetCategorizedVideos();

  const { isBarLoading } = useLoadingBar();
  const [videoURL, setVideoURL] = useState<string>();
  const [videoTitle, setVideoTitle] = useState<string>();
  // const [searchInput, setSearchInput] = useState('');

  const [data, setData, dataListLoading, checkDataListBottom] =
    useDataPaginator<ITrainingVideoBody, [string]>({
      filters: [category],
      storeNewData: async (filters, cursor) => {
        const res = await getCategorizedVideos({
          cursor: cursor as string,
          // filter_name: filters[0],
          filter_category: filters[0],
          token,
        });
        return {
          nextCursor:
            res.cursor.afterCursor === res.cursor.beforeCursor
              ? null
              : res.cursor.afterCursor,
          shouldResetData: !res.cursor.beforeCursor,
          nextData: res.data,
        };
      },
    });

  const handleClick = (link_url: string, title: string) => {
    setVideoURL(link_url);
    setVideoTitle(title);
    window.scrollTo(0, 0);
  };

  const checkWindowScroll = (ev: Event) => {
    const { scrollHeight, scrollTop, clientHeight } = (ev.target as any)
      .scrollingElement;
    const mock = {
      currentTarget: {
        scrollHeight,
        scrollTop,
        clientHeight,
      },
    } as React.UIEvent<HTMLElement, UIEvent>;
    checkDataListBottom(mock);
  };

  useEffect(() => {
    if (state) {
      setVideoTitle(state.title);
      setVideoURL(state.link_url);
    }
  }, [state]);
  useEffect(() => {
    window.addEventListener('scroll', checkWindowScroll);
    return () => window.removeEventListener('scroll', checkWindowScroll);
  }, []);
  const videoDisplay = useMemo(() => {
    if (videoURL && videoURL.length > 0) {
      return 'block';
    }
    return 'none';
  }, [videoURL]);

  return (
    <>
      <Intro image={onlineCourse} name={category} text="" />
      <TrainingContainer>
        {/* <form>
          <input
            placeholder=" Busque o seu treinamento "
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <input type="submit" hidden />
        </form> */}

        <div id="scroll" className="scroll-videos-container">
          <div style={{ display: videoDisplay }} className="video-container">
            <p>{videoTitle}</p>
            <iframe
              title={videoTitle}
              src={videoURL}
              frameBorder="0"
              allow="accelerometer;
autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              onLoad={ev => console.log('iframe bugged', ev)}
            />
          </div>
          <ul className="videos-section">
            <>
              {data.length > 0 &&
                data.map(info => (
                  <>
                    <div
                      className="video-card"
                      onClick={() => handleClick(info.link_url, info.title)}
                    >
                      <li className="scroll-video scroll-thumb scroll-image">
                        <img
                          alt={`Thumbnail do Curso ${info.title} - ${info.id}`}
                          src={info.picture}
                          className="scroll-video-frame"
                          onError={e => {
                            const imgElement = e.target as HTMLImageElement;
                            imgElement.src =
                              'https://www.sinprosp.org.br/images/404.png';
                          }}
                        />
                      </li>
                      <div className="scroll-video-name">
                        <p>{info.title}</p>
                      </div>
                    </div>
                  </>
                ))}
              {isBarLoading && (
                <SkeletonLoader
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '2rem',
                    minWidth: '24rem',
                    maxWidth: '100%',
                    height: '20rem',
                    margin: '0 auto',
                  }}
                />
              )}
            </>
          </ul>
        </div>
      </TrainingContainer>
    </>
  );
};

export default TrainingCategory;
