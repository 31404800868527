/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Autocomplete, TextField } from '@material-ui/core';
import { ICourseBody } from '../../models/Course';

interface ICourseSelectorProps {
  items: ICourseBody[];
  onSelect: (course: ICourseBody) => void;
}

export default function CourseSelector({
  items,
  onSelect,
}: ICourseSelectorProps): JSX.Element {
  const [value, setValue] = React.useState<ICourseBody | undefined>(items[0]);
  const [inputValue, setInputValue] = React.useState('');

  return (
    <div>
      <Autocomplete
        style={{
          paddingTop: '10px',
          background: 'transparent',
        }}
        value={value}
        autoComplete
        onChange={(event, newValue) => {
          setValue(newValue);
          onSelect(newValue);
        }}
        disableClearable
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-state"
        getOptionLabel={item => item.name}
        options={items}
        groupBy={optionItem => optionItem.category}
        renderInput={params => (
          <TextField
            {...params}
            variant="filled"
            SelectProps={{
              variant: 'filled',
              style: { background: 'transparent' },
            }}
            style={{ background: 'transparent' }}
            label="Cursos"
            placeholder="Procure o curso desejado aqui"
            InputLabelProps={{ shrink: true, style: { color: 'white' } }}
          />
        )}
      />
    </div>
  );
}
