import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 100vw;
  max-width: 100vw;
  padding: 0 3rem;

  position: absolute;
  top: 20px;
  @media (min-width: 992px) {
    display: none;
  }
  .avatar-container {
    display: flex;
    align-items: center;
    z-index: 1;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin-top: 0.6rem;
    margin-left: 0.6rem;
    p {
      font-size: 1.5rem;
      font-weight: 900;
      @media (max-width: 330px) {
        font-size: 1.3rem;
      }
    }
    span {
      color: #898399;
      font-size: 1.2rem;
      font-weight: 400;

      margin-top: 0.2rem;
      @media (max-width: 330px) {
        font-size: 1rem;
      }
    }
  }
  .class-menu-container {
    display: flex;
    z-index: 10;
  }
  .profile-class {
    background-color: #646464;
    border-radius: 1rem;
    color: #fff;
    font-weight: 600;
    margin-top: -0.5rem;
    padding: 1rem 1.6rem 0.6rem;
    margin-right: 0.8rem;
  }
`;
