/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// eslint-disable-next-line import/no-duplicates
import { intervalToDuration, formatDuration } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale';

import Tab from '../../../components/Tab';
import { useSaleCheckout } from '../../../hooks/saleCheckout';
import { useGetCheckoutSale } from '../../../services/api';
import { getCurrencyMask } from '../../../utils/currencyFormatter';
import { HomeContainer } from './styles';
import Billet from './billet';
import Card from './card';
import { SalesStatusEnum } from '../../../models/Sale';

const CheckoutHome: React.FC = () => {
  const { saleId } = useParams<{ saleId: string }>();
  const history = useHistory();

  const { saleCheckout, setSaleCheckout, setSaleHash } = useSaleCheckout();

  const getCheckoutSale = useGetCheckoutSale();

  const [isSelect, setIsSelect] = React.useState(true);

  // console.log('Current-SALE_ID', { saleId });

  useEffect(() => {
    (async () => {
      if (saleId) {
        if (saleCheckout?.adherence_agreement) {
          history.push('/checkout/gerar-link');
        } else if (saleCheckout?.student.document_number) {
          history.push('/checkout/termos');
        }
        try {
          const newCheckoutSale = await getCheckoutSale({ saleId });
          setSaleCheckout(newCheckoutSale);
          setSaleHash(saleId);
          if (newCheckoutSale?.adherence_agreement) {
            history.push('/checkout/gerar-link');
          } else if (newCheckoutSale?.student.document_number) {
            history.push('/checkout/termos');
          }
          // if (newCheckoutSale.status === 'Paid') {
          //   throw new Error('Venda já foi realizada');
          // }
        } catch (error) {
          history.push('/404');
        }
      } else {
        history.push('/404');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const splittedText = saleCheckout?.description_of_course.split('*%*') || '';

  return (
    <HomeContainer>
      {saleCheckout && (
        <>
          <div className="top-section">
            <div className="data-course">
              <div className="course-name">
                <h3>{saleCheckout?.name_of_course}</h3>
                <p>
                  {splittedText[0]}
                  <br />
                  <br />
                  {splittedText[1]}
                  <br />
                  <br />
                  {splittedText[2]}
                </p>
              </div>
              <iframe
                title="Video de Venda"
                src="https://www.youtube.com/embed/6Hi0umWkhgQ"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <div className="course-prices">
                <div className="price">
                  <p>VALOR CHEIO: </p>
                  <span className="full-price">
                    {getCurrencyMask(saleCheckout.course.price)}
                  </span>
                </div>
                <div className="price">
                  <p>DESCONTO PROMOCIONAL: </p>
                  <span className="discount-price">
                    {getCurrencyMask(
                      saleCheckout.value_of_course -
                        saleCheckout.promotional_value_of_course,
                    )}
                  </span>
                </div>
                <div className="price">
                  <p>VALOR FINAL: </p>
                  <span className="final-price">
                    {getCurrencyMask(saleCheckout.promotional_value_of_course)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="time">
            <h3>Oferta disponível por:</h3>
            <CountdownLabel
              expirationDate={new Date(Date.parse(saleCheckout.date_of_expiry))}
            />
          </div>
          <div className="bottom-section">
            <Tab
              tab1="Boleto"
              tab2="Cartão"
              isSelect={isSelect}
              setIsSelect={setIsSelect}
              checkout
            />
            {isSelect ? <Billet /> : <Card />}
          </div>
        </>
      )}
    </HomeContainer>
  );
};

const CountdownLabel: React.FC<{
  expirationDate: Date;
}> = ({ expirationDate }) => {
  const [now, setNow] = useState<Date>(new Date());

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setNow(new Date());
    }, 500);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  return (
    <h2>
      {expirationDate > now
        ? formatDuration(
            intervalToDuration({
              start: now,
              end: expirationDate,
            }),
            { locale: ptBR },
          )
        : 'OFERTA INDISPONÍVEL'}
    </h2>
  );
};

export default CheckoutHome;
