import styled from 'styled-components';

interface IProfilePictureProps {
  picture: string;
  widthAndHeightPicture: string;
  marginTop: string;
}

export const ProfilePicture = styled.div<IProfilePictureProps>`
  background-image: url(${({ picture }) => picture});
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: center;
  background-size: cover;

  width: ${({ widthAndHeightPicture }) => widthAndHeightPicture}rem;
  height: ${({ widthAndHeightPicture }) => widthAndHeightPicture}rem;
  border-radius: 100%;
  margin-top: ${({ marginTop }) => marginTop}rem;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
