// eslint-disable-next-line no-shadow
enum CommissionEnum {
  DirectSelling = 'Direct Selling',
  IndirectSelling = 'Indirect Selling',
  DirectRecurrence = 'Direct Recurrence',
  IndirectRecurrence = 'Indirect Recurrence',
  DirectDebut = 'Direct Debut',
  IndirectDebut = 'Indirect Debut',
}
export default CommissionEnum;
