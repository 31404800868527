import React, { useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import GlobalStyles from './styles/globalStyles';
import AppProvider from './hooks/index';

import Routes from './routes';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App: React.FC = () => (
  <BrowserRouter>
    <AppProvider>
      <GlobalStyles />
      <ScrollToTop />
      <Routes />
    </AppProvider>
  </BrowserRouter>
);

export default App;
