import React, { useEffect, useState } from 'react';

import Intro from '../../../components/Intro';

import ranking from '../../../Assets/pages/ranking.svg';

import { LevelContainer, ClassData, CarouselContainer } from './styles';
import Avatar from '../../../components/Avatar';
import SkeletonLoader from '../../../components/SkeletonLoader';

import { useAuth } from '../../../hooks/auth';
import { useLoadingBar } from '../../../hooks/loadingBar';
import { useGetSalesInfo } from '../../../services/api';
import { ProfileClassEnum } from '../../../models/User';

/* eslint-disable no-mixed-operators */

import ArrowLeft from '../../../Assets/icons/arrow-left.svg';
import ArrowRight from '../../../Assets/icons/arrow-right.svg';
import UserProfile from '../Profile/UserProfile';
import { SalesInfo } from '../MySales/styles';

const Level: React.FC = () => {
  const { user } = useAuth();

  const [centerCarousel, setCenterCarousel] = useState<ProfileClassEnum>(
    user.profile_class,
  );
  const [leftCarousel, setLeftCarousel] = useState('');
  const [rightCarousel, setRightCarousel] = useState('');
  const { isBarLoading } = useLoadingBar();
  const getSalesInfo = useGetSalesInfo();

  const [textInfo, setTextInfo] = useState({
    name: '',
    sales: 0,
    recurrence: '',
  });
  const [salesInfo, setSalesInfo] = useState(0);

  function goToLeft() {
    if (centerCarousel === 'M. Supervisor') {
      setCenterCarousel(ProfileClassEnum.master);
    } else if (centerCarousel === 'Master') {
      setCenterCarousel(ProfileClassEnum.pleno);
    } else if (centerCarousel === 'Pleno') {
      setCenterCarousel(ProfileClassEnum.junior);
    } else if (centerCarousel === 'Junior') {
      setCenterCarousel(ProfileClassEnum.trainee);
    }
    verifyCenter();
  }

  function goToRight() {
    if (centerCarousel === 'Trainee') {
      setCenterCarousel(ProfileClassEnum.junior);
    } else if (centerCarousel === 'Junior') {
      setCenterCarousel(ProfileClassEnum.pleno);
    } else if (centerCarousel === 'Pleno') {
      setCenterCarousel(ProfileClassEnum.master);
    } else if (centerCarousel === 'Master') {
      setCenterCarousel(ProfileClassEnum.supervisor);
    }
  }

  function verifyCenter() {
    if (centerCarousel === ProfileClassEnum.junior) {
      setTextInfo({
        name: 'Junior',
        sales: 50,
        recurrence: '5',
      });
    } else if (centerCarousel === ProfileClassEnum.pleno) {
      setTextInfo({
        name: 'Pleno',
        sales: 100,
        recurrence: '7',
      });
    } else if (centerCarousel === ProfileClassEnum.master) {
      setTextInfo({
        name: 'Master',
        sales: 150,
        recurrence: '8',
      });
    } else if (centerCarousel === ProfileClassEnum.supervisor) {
      setTextInfo({
        name: 'M. Supervisor',
        sales: 200,
        recurrence: '10',
      });
    }
  }

  useEffect(() => {
    verifyCenter();
    if (centerCarousel === 'Trainee') {
      setLeftCarousel('');
      setRightCarousel(ProfileClassEnum.junior);
    } else if (centerCarousel === 'Junior') {
      setLeftCarousel(ProfileClassEnum.trainee);
      setRightCarousel(ProfileClassEnum.pleno);
    } else if (centerCarousel === 'Pleno') {
      setLeftCarousel(ProfileClassEnum.junior);
      setRightCarousel(ProfileClassEnum.master);
    } else if (centerCarousel === 'Master') {
      setLeftCarousel(ProfileClassEnum.pleno);
      setRightCarousel(ProfileClassEnum.supervisor);
    } else if (centerCarousel === 'M. Supervisor') {
      setLeftCarousel(ProfileClassEnum.master);
      setRightCarousel('');
    }
  }, [centerCarousel]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getSalesInfo({});
        setSalesInfo(res.sales);
      } catch (error) {}
    })();
  }, []);
  console.log((100 * salesInfo) / 200 - 23);

  return (
    <LevelContainer>
      <Intro
        image={ranking}
        name="Meu Nível"
        text="Acompanhe seu progresso no plano de carreira"
      />
      {isBarLoading ? (
        <SkeletonLoader
          style={{
            backgroundColor: '#fff',
            borderRadius: '2rem',
            minHeight: '60rem',
          }}
        />
      ) : (
        <ClassData>
          <div className="intro">
            <Avatar
              avatar_url={user?.avatar_url}
              widthAndHeightPicture="12"
              marginTop="0"
            />
            <h1>{user.name}</h1>
          </div>
          <div className="class">
            <CarouselContainer>
              {leftCarousel !== '' ? (
                <p
                  className="class-name"
                  id={leftCarousel === user.profile_class ? 'userClass' : ''}
                >
                  {leftCarousel}
                </p>
              ) : (
                <p style={{ backgroundColor: '#fff' }} />
              )}
              <div>
                <button type="button" onClick={goToLeft}>
                  <img src={ArrowLeft} alt="seta para esquerda" />
                </button>
                <span
                  id={centerCarousel === user.profile_class ? 'userClass' : ''}
                >
                  {centerCarousel}
                </span>
                <button type="button" onClick={goToRight}>
                  <img src={ArrowRight} alt="seta para direita" />
                </button>
              </div>
              {rightCarousel !== '' ? (
                <p
                  className="class-name"
                  id={rightCarousel === user.profile_class ? 'userClass' : ''}
                >
                  {rightCarousel}
                </p>
              ) : (
                <p style={{ backgroundColor: '#fff' }} />
              )}
            </CarouselContainer>
            <p className="first-box">
              Você tem
              <b> {salesInfo} </b>
              vendas acumuladas
            </p>
          </div>
          <div className="info">
            {centerCarousel === user.profile_class ? (
              <p>Este é seu nível</p>
            ) : (
              <>
                {textInfo.sales < salesInfo ? (
                  <p>Parabéns, você já superou esse nível!</p>
                ) : (
                  <p>
                    Faça mais
                    <b> {textInfo.sales - salesInfo} </b>
                    {`vendas para se tornar ${textInfo.name} e liberar ${textInfo.recurrence}% em suas recorrências`}
                  </p>
                )}
              </>
            )}
            <div className="premio">
              <h3>Prêmio por Avanço de Carreira:</h3>
              <div className="path-container">
                <p id="title">Matrículas Acumuladas</p>
                {salesInfo <= 50 ? (
                  <div
                    className="path"
                    style={{
                      width: `${(100 * salesInfo) / 200}%`,
                      backgroundColor: '#D7C208',
                    }}
                  >
                    <span>Trainee</span>
                  </div>
                ) : (
                  <>
                    <div
                      className="path"
                      style={{
                        width: '25%',
                        backgroundColor: '#D7C208',
                      }}
                    >
                      <span>Trainee</span>
                    </div>
                    <div
                      className="path"
                      style={{
                        width: `${
                          salesInfo <= 200
                            ? (100 * salesInfo) / 200 - 23
                            : '100'
                        }%`,
                        backgroundColor: '#296394',
                      }}
                    />
                  </>
                )}
              </div>
              <ul>
                <li>
                  <h1>50</h1>
                  <h2>Junior</h2>
                  <span>R$ 250,00 em Prêmios</span>
                </li>
                <li>
                  <h1>100</h1>
                  <h2>Pleno</h2>
                  <span>R$ 500,00 em Prêmios</span>
                </li>
                <li>
                  <h1>150</h1>
                  <h2>Master</h2>
                  <span>R$ 750,00 em Prêmios</span>
                </li>
                <li>
                  <h1>200</h1>
                  <h2>M. Supervisor</h2>
                  <span>R$ 1.000,00 em Prêmios</span>
                </li>
              </ul>
            </div>
          </div>
          {/* <button type="button">Clique aqui e veja as regras</button> */}
        </ClassData>
      )}
    </LevelContainer>
  );
};

export default Level;
