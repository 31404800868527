import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import SkeletonLoader from '../../../components/SkeletonLoader';

import { useLoadingBar } from '../../../hooks/loadingBar';
import Tooltip from '../../../components/Tooltip';
import {
  GetBalance,
  GetPaymentHistory,
  useGetBalance,
  useGetPaymentHistory,
} from '../../../services/api';
import { getCurrencyMask } from '../../../utils/currencyFormatter';
import { PaymentsContainer, Table } from './styles';

interface IPaymentsProps {
  isSelect: boolean;
}

interface IEntity {
  month: string;
  date: string;
  value: string;
}

const Payments: React.FC<IPaymentsProps> = ({ isSelect }) => {
  const [historyData, setHistoryData] =
    useState<GetPaymentHistory['Response'][]>();
  const [balance, setBalance] = useState<GetBalance['Response']>({
    closing_date: '',
    to_receiving: 0,
    current_month_balance: 0,
  });
  const { isBarLoading } = useLoadingBar();
  const getBalance = useGetBalance();
  const getPaymentHistory = useGetPaymentHistory();

  useEffect(() => {
    (async () => {
      try {
        const res = await getPaymentHistory();
        setHistoryData(res);
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await getBalance();
        setBalance(res);
      } catch (error) {}
    })();
  }, []);
  return (
    <PaymentsContainer>
      <div className="intro-data">
        <div>
          <span>PRÓXIMO PAGAMENTO</span>
          {balance?.closing_date && (
            <h3>{format(parseISO(balance?.closing_date), 'dd/MM/yyyy')}</h3>
          )}
        </div>
        <div>
          <div className="info-payments-tooltip">
            <span className="tooltip">A RECEBER </span>
            <Tooltip isMultinivelScreen={false}>
              São os valores apurados entre o dia 1 ao 30 do mês anterior e em
              processo de pagamento ao consultor. Que serão pagos no dia 10 do
              mês atual.
            </Tooltip>
          </div>
          <h3>{getCurrencyMask(balance?.to_receiving || Number('000'))}</h3>
        </div>
        <div>
          <div className="info-payments-tooltip">
            <span className="tooltip">SALDO ATUAL</span>
            <Tooltip isMultinivelScreen={false}>
              São os valores apurados em tempo real, que serão acumulados entre
              o dia 1 ao dia 30 e que serão pagos ao consultor no dia 10 do mês
              subsequente.
            </Tooltip>
          </div>
          <h3 style={{ color: 'red' }}>
            {getCurrencyMask(balance?.current_month_balance || Number('000'))}
          </h3>
        </div>
      </div>
      <Table isSelect={!isSelect}>
        <thead>
          <tr>
            <th className="center">Mês</th>
            <th className="center">Data Pagamento</th>
            <th className="center">Valor</th>
          </tr>
        </thead>
        <tbody>
          {isBarLoading ? (
            <>
              <tr style={{ display: 'flex', flexDirection: 'column' }}>
                <SkeletonLoader
                  style={{
                    minWidth: '50rem',
                    height: '5rem',
                    backgroundColor: 'white',
                  }}
                />
                <SkeletonLoader
                  style={{
                    minWidth: '50rem',
                    height: '5rem',
                    backgroundColor: 'white',
                  }}
                />
                <SkeletonLoader
                  style={{
                    minWidth: '50rem',
                    height: '5rem',
                    backgroundColor: 'white',
                  }}
                />
              </tr>
            </>
          ) : (
            <>
              {historyData?.length ? (
                historyData.map(info => (
                  <>
                    {console.log('aa', info)}
                    <tr>
                      <td className="center">{info.mon}</td>
                      <td className="center">
                        {format(new Date(info.payment_date), 'dd/MM/yyyy')}
                      </td>
                      <td className="center">{getCurrencyMask(info.sum)}</td>
                    </tr>
                  </>
                ))
              ) : (
                <span>Não encontramos o histórico de pagamentos</span>
              )}
            </>
          )}
        </tbody>
      </Table>
    </PaymentsContainer>
  );
};
export default Payments;
