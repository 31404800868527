import styled from 'styled-components';

export const Container = styled.div`
  padding: 0rem 8rem 2rem;
  max-width: 1200px;
  margin: 0px auto;
  @media (max-width: 991px) {
    padding: 0;
  }

  .wrapper-text {
    background-color: #fff;
    border-radius: 2rem;
    color: #fff;
    padding: 24px 10px;
    @media (max-width: 991px) {
      border-radius: 0;
    }
    @media (max-width: 420px) {
      padding: 24px;
    }
    .container-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--black-background);
      border-radius: 20px;
      padding: 16px 32px;
      max-height: 10rem;
      margin: 22px 0;
      @media (max-width: 901px) {
        display: flex;
        flex-direction: column;
      }
      @media (max-width: 420px) {
        display: flex;
      }
      div {
        @media (max-width: 901px) {
          margin-bottom: 20px;
        }
      }

      label {
        font-weight: 800;
        font-size: 21px;
        line-height: 22px;

        letter-spacing: 0.05em;
        margin-left: 8px;
        span {
          color: var(--primary-color);
        }
        @media (max-width: 420px) {
          font-size: 16px;
          line-height: 16px;
        }
        @media (max-width: 380px) {
          font-size: 14px;
          line-height: 14px;
        }
      }
      button {
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
        border-radius: 10px;
        color: var(--black-background);

        font-weight: 800;
        font-size: 16px;
        line-height: 17px;
        margin: 6px;

        width: 200px;
        padding: 4px;
        cursor: pointer;
        @media (max-width: 420px) {
          width: 140px;
        }
        @media (max-width: 380px) {
          max-width: 120px;
        }
        @media (max-width: 368px) {
          max-width: 110px;
        }
        @media (max-width: 350px) {
          max-width: 90px;
        }
        &:disabled {
          color: white;
          border-color: grey;
          background-color: grey;
        }
      }
    }
    .container {
      background-color: var(--black-background);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 32px;
      max-height: 40rem;
      margin: 22px 0;
      overflow-y: auto;
      ::-webkit-scrollbar-track {
        background-color: #c4c4c4;
        border-radius: 20px;
      }
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        padding: 10px 0;
      }
      ::-webkit-scrollbar-thumb {
        background: #646464;
        border-radius: 20px;
      }

      p {
        font-size: 14px;
        line-height: 16px;

        margin-top: 16px;
        @media (max-width: 370px) {
          font-size: 12px;
        }
      }
    }
  }
`;
