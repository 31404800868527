import styled from 'styled-components';

export const TechQuestionsContainer = styled.div`
  padding: 0rem 8rem 2rem;
  max-width: 1200px;
  margin: 0px auto;
  @media (max-width: 991px) {
    padding: 0;
  }
`;

export const TechnicalQuestions = styled.section`
  background-color: #fff;
  border-radius: 2rem;
  color: #fff;
  padding: 32px 54px;
  @media (max-width: 991px) {
    border-radius: 0;
  }
  @media (max-width: 420px) {
    padding: 24px;
  }
  .background-container {
    background-color: var(--black-background);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 32px;

    button.off {
      opacity: 0.5;
    }
    p {
      font-weight: 800;
      font-size: 14px;
      line-height: 16px;
      text-align: center;

      margin-top: 16px;
      @media (max-width: 370px) {
        font-size: 12px;
      }
    }
    textarea {
      border-radius: 10px;

      width: 100%;
      height: 185px;
      padding: 8px;
      margin-top: 16px;
      outline: none;
    }
    .button-container {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      button {
        background-color: var(--primary-color);
        border: none;
        border-radius: 20px;
        color: #fff;
        font-weight: 800;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;

        padding: 8px 54px;
        margin: 0 8px;
        @media (max-width: 420px) {
          padding: 8px 24px;
        }
        @media (max-width: 370px) {
          font-size: 14px;
          padding: 8px 16px;
        }
      }
    }
  }
`;
