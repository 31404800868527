import React from 'react';

import iconTooltip from '../../Assets/icons/iconTooltip.svg';
import iconTooltip2 from '../../Assets/icons/tooltip-2.svg';

import { TooltipIcon, TooltipBox, TooltipCard } from './styles';

interface ITooltipProps {
  isMultinivelScreen: boolean;
}

const Tooltip: React.FC<ITooltipProps> = ({ children, isMultinivelScreen }) => (
  <TooltipCard isMultinivelScreen={isMultinivelScreen}>
    <TooltipIcon>
      {isMultinivelScreen ? (
        <img src={iconTooltip2} alt="Descrição" />
      ) : (
        <img src={iconTooltip} alt="Descrição" />
      )}
    </TooltipIcon>
    <TooltipBox>{children}</TooltipBox>
  </TooltipCard>
);

export default Tooltip;
