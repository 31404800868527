/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable arrow-parens */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef, useState, CSSProperties } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import Modal from 'react-modal';

import downArrow from '../../../Assets/icons/down-arrow.svg';
import { useFullscreenLoading } from '../../../hooks/fullscreenLoading';
import { useLoadingBar } from '../../../hooks/loadingBar';
import { useSaleCheckout } from '../../../hooks/saleCheckout';
import { useToast } from '../../../hooks/toast';
import { CourseCategoryEnum } from '../../../models/Course';
import { IStudent } from '../../../models/Student';
import {
  usePutCreditCardCheckout,
  useStudentAgreement,
} from '../../../services/api';

import { Container, ModalContentContainer, Term } from './styles';
import { colors } from '../../../styles/globalStyles';

interface IBilletData {
  name: string;
  phone: string;
  email: string;
  email2: string;
  cpf: string;
  saleId: string;
  courseId: string;
}

const TermsOfUse: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<IBilletData | undefined>();

  const studentAgreement = useStudentAgreement();

  const { addToast } = useToast();

  const [dummyData, setDummyData] = useState<IBilletData>({
    name: '',
    phone: '',
    email: '',
    email2: '',
    cpf: '',
    saleId: '',
    courseId: '',
  });

  const scrollRef = useRef<HTMLDivElement>(document.createElement('div'));
  const [isFinalScroll, setIsFinalScroll] = useState(true);
  const [isGraduation, setIsGraduation] = useState(true);
  const [checkbox, setCheckbox] = useState(true);
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
  const [chargeDocumentLink, setChargeDocumentLink] = useState<string>('');

  // eslint-disable-next-line operator-linebreak
  const { showFullscreenMessage, eraseFullscreenMessage } =
    useFullscreenLoading();
  const { saleCheckout, setSaleCheckout } = useSaleCheckout();
  const { startBarLoading, completeBarLoading } = useLoadingBar();
  const putCreditCardCheckout = usePutCreditCardCheckout();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  const chargeLinkEl = useRef<HTMLAnchorElement>(null);

  const onScroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = scrollRef.current;

    const bottom = scrollHeight - scrollTop < clientHeight + 10;
    if (bottom) {
      setIsFinalScroll(false);
    } else {
      setIsFinalScroll(true);
    }
  };
  const bottom = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (bottom.current) {
      bottom.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (chargeDocumentLink.length) {
      console.log(
        '🚀 ~ file: billet.tsx ~ line 60 ~ useEffect ~ chargeDocumentLink',
        chargeDocumentLink,
      );
      chargeLinkEl.current?.click();
    }
  }, [chargeDocumentLink]);

  useEffect(() => {
    if (state) {
      console.log(state);
      setDummyData(state);
      console.log(dummyData);
    }
  }, [state]);

  const handleSubmit = async () => {
    console.log('submit');
    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
      phone: yup
        .string()
        .required('Insira o número do seu telefone')
        .matches(
          /^\(\d{2}\) \d{4,5}-\d{4}$/,
          'Telefone deve seguir a seguinte regra "(XX) XXXXX-XXXX',
        ),
      email: yup
        .string()
        .required('Insira o seu email')
        .email('Email inválido'),
      email2: yup
        .string()
        .required('Insira o email de confirmação')
        .oneOf([yup.ref('email')], 'Os emails não são iguais'),
      cpf: yup
        .string()
        .typeError('CPF/CNPJ inválido')
        .required('CPF/CNPJ é obrigatório')
        .matches(
          /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
          'CPF/CNPJ deve seguir a seguinte regra "XXX.XXX.XXX-XX"/"XX.XXX.XXX/XXXX-XX"',
        ),
    });

    try {
      await schema.validate(dummyData, {
        abortEarly: false,
      });

      startBarLoading();
      setIsPaymentLoading(true);
      showFullscreenMessage('Processando pagamento...');

      try {
        const payment = {
          payment_type: 'Boleto' as 'Crédito' | 'Boleto',
          customer: {
            name: dummyData.name,
            email: dummyData.email,
            cpf: dummyData.cpf.replace(/\./g, '').replace('-', ''),
            phone_number: dummyData.phone
              .replace(' ', '')
              .replace('-', '')
              .replace('(', '')
              .replace(')', ''),
          },
        };

        const apiRequest = {
          // from context
          sale_id: dummyData.saleId,
          course: {
            course_id: dummyData.courseId as string,
          },
          student: {
            id: saleCheckout?.student.id,
            name: dummyData.name,
            phone_number: dummyData.phone,
            email: dummyData.email,
            email_confirmation: dummyData.email2,
            document_number: dummyData.cpf,
          } as IStudent,
          //
          payment,
        };

        const res = await putCreditCardCheckout(apiRequest);

        /* const link = document.createElement('a');
        const [isoDate] = (new Date()).toISOString().split('T');
        link.download = isoDate;
        link.href = res.data.pdf.charge;
        link.addEventListener('click', () => {
          window.open(res.data.pdf.charge);
        });
        link.click(); */

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Pagamento realizado',
        });

        setSaleCheckout(
          sc =>
            sc && {
              ...sc,
              student: {
                ...sc.student,
                name: dummyData.name,
                email: dummyData.email,
                document_number: dummyData.cpf,
                phone_number: dummyData.phone,
              },
            },
        );
        if (saleCheckout?.id) {
          await studentAgreement({
            sale_id: saleCheckout.id,
            adherence_agreement: true,
          });
          if (payment.payment_type === 'Boleto') {
            openModal();
          } else {
            history.push('/checkout/cadastro', {
              graduation: true,
            });
          }
        }
      } catch (error) {
        console.log('Erro Pag Back', { error });
      } finally {
        completeBarLoading();
        setIsPaymentLoading(false);
        eraseFullscreenMessage();
      }
    } catch (error) {
      // console.log('Deu ruim');
      if (error instanceof yup.ValidationError) {
        // console.log('Deu ruim com toast');
        const firstErrorMessage = error.errors[0];
        addToast({
          type: 'error',
          title: 'Erro nos dados informados',
          description: firstErrorMessage,
        });
      }
    }
  };

  const handleConfirm = async () => {
    try {
      if (saleCheckout?.id) {
        await studentAgreement({
          sale_id: saleCheckout.id,
          adherence_agreement: true,
        });
        history.push('/checkout/gerar-link');
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (saleCheckout?.course.category === CourseCategoryEnum.PostGrad) {
      setIsGraduation(false);
    }
  }, []);
  const modalContent: CSSProperties = {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // height: '20rem',
    alignItems: 'center',
    backgroundColor: '#141b23',
    color: 'white',
    borderRadius: '1rem',
    justifyContent: 'space',
  };
  const customStyles = {
    content: modalContent,
  };

  return (
    <Container>
      <a
        ref={chargeLinkEl}
        href={chargeDocumentLink}
        target="_blank"
        rel="noreferrer"
        hidden
      >
        &nbsp;
      </a>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Modal"
        style={customStyles}
      >
        <ModalContentContainer>
          <h1>Parabéns! Você acaba de contratar seu curso parcelado.</h1>
          <h3>
            Enviamos seu boleto em seu e-mail cadastrado, verifique inclusive em
            sua caixa de spam e lixo eletrônico.
          </h3>
          <h3>Pague em dia e evite multas e juros</h3>
          <h3>Anote o whatsapp de atendimento ao cliente: (41)3667-6657</h3>

          <button
            type="button"
            id="confirm"
            onClick={() => {
              history.push('/checkout/cadastro', {
                graduation: true,
              });
            }}
          >
            Avançar para o próximo passo
          </button>
        </ModalContentContainer>
      </Modal>
      <Term>
        <div onScroll={onScroll} ref={scrollRef}>
          <>
            <h2>Contrato de Prestação de Serviços Educacionais</h2>
            <p>
              Estou ciente que meu curso foi parcelado em 1 parcela de entrada +
              o volume de meses de duração do curso contratado.
            </p>
            <p>
              Estou ciente que a metodologia de ensino é EAD com a opção de
              encontros presenciais na instituição.
            </p>
            <p>
              Estou ciente que os valores descritos no item 1 se referem ao
              pagamento com o desconto pontualidade conforme segue: parcela 1
              que SERÁ PAGA NO ATO DA CONTRATAÇÃO, demais parcelas tem o
              vencimento dia 05 de cada mês.
            </p>
            <p>
              Declaro que estou ciente que para participar do programa de
              benefícios da unidade contatada, preciso estar em conformidade com
              os seguintes requisitos; 1º Estar adimplente com as minhas
              mensalidades; 2º Indicar 20 amigos; 3º Ser um bom aluno.
            </p>
            <p>
              Estou ciente que o Pacote de Benefícios da unidade contratada é
              limitado ao Campus contratado, sendo exclusivamente um incentivo
              ao aluno deste Campus, e que as regras de utilização tem caráter
              promocional e por tempo indeterminado.
            </p>
            <p>
              Estou ciente de minha responsabilidade em acessar o Portal para
              fazer minhas atividades educacionais e também fazer minha
              Rematrícula ao final do semestre (conforme calendário acadêmico)
              sem custo adicional (somente o parcelamento da semestralidade).
            </p>
            <p>
              Estou ciente que o valor total do pacote educacional passa a ser
              devido independente de circunstâncias pessoais.
            </p>
            <p>
              Estou ciente que o Trancamento de Curso não promove a anulação da
              dívida assumida referente ao pacote educacional, e sim um
              congelamento das disciplinas (o prazo máximo de trancamento é de 6
              meses).
            </p>
            <p>
              Recebi e já cadastrei na minha agenda telefônica o número da
              Assistência Acadêmica (da unidade contratada), canal que será
              utilizado para me passar informações importantes pertinentes aos
              meus estudos.
            </p>
            <p>
              Declaro que aceito as condições acima descritas, e que o mesmo
              corresponda ao meu CONTRATO DE PRESTAÇÃO DE SERVIÇOS EDUCACIONAIS,
              conforme o artigo 10, § 2º da Medida Provisória
              <strong>2.200/2001-2</strong>
              ACEITE DE CONTRATO ELETRÔNICO.
            </p>
            <p>
              Fica eleito o foro da cidade de Pinhais, estado do Paraná, para
              dirimir questões oriundas do presente instrumento, com exclusão de
              qualquer outro, por mais privilegiado que seja.
            </p>
            <p>SOCIEDADE EDUCACIONAL WUP</p>
            <p>
              CNPJ:
              <strong>18.215.802/0007-84</strong>
            </p>

            <p>Aislan Wiland Westphal Diretor Administrativo.</p>
            <p>
              Declaro que a aceito as condições acima descritas, e que o mesmo
              corresponda ao meu CONTRATO DE PRESTAÇÃO DE SERVIÇOS EDUCACIONAIS,
              conforme o artigo 10, § 2º da Medida Provisória 2.200/2001-2.
              <div
                style={{
                  opacity: 0,
                  margin: '-300px',
                  width: 0,
                  height: 0,
                }}
                ref={bottom}
              />
            </p>
          </>
          {/* { isGraduation ? (
          )
            : (
              <>
                <p>
                  Estou ciente que para iniciar a Pós-graduação, preciso ser
                  efetivamente Graduado.

                </p>
                <p>Estou ciente que a metodologia de ensino é totalmente EAD.</p>
                <p>
                  Estou ciente de minha responsabilidade em acessar o Portal
                  para fazer minhas atividades educacionais e após concluir
                  todas as atividades das aulas, realizar a matrícula do TCC.

                </p>
                <p>
                  Estou ciente que meu curso foi parcelado em 1 + a duração do
                  financiamento escolhido
                  (1x, 6x, 9x, 12x 15x e 18x).

                </p>
                <p>
                  Estou ciente que
                  os valores descritos no item 1 se referem ao
                  pagamento com o desconto pontualidade conforme segue: parcela 1
                  que SERÁ PAGA NO ATO DA CONTRATAÇÃO
                  , demais parcelas - vencimento dia 05 de cada mês.

                </p>
                <p>
                  Estou ciente que o valor total do pacote educacional passa a
                  ser devido independente de circunstâncias pessoais.

                </p>

                <p>
                  Estou ciente que o Cancelamento do Curso não promove a anulação
                  da dívida assumida referente
                  ao pacote educacional
                  e que não há possibilidade de Trancamento dos Cursos de Pós
                  Graduação.

                </p>
                <p>
                  Recebi
                  e já cadastrei na minha agenda telefônica o número da Assistência
                  Acadêmica (3732-3350), canal que será utilizado para me passar
                  informações importantes pertinentes aos meus estudos.

                </p>

                <p>
                  Declaro que a aceito as condições acima descritas, e que o mesmo
                  corresponde ao meu
                  CONTRATO DE PRESTAÇÃO DE SERVIÇOS
                  EDUCACIONAIS, conforme o artigo 10, § 2.º da Medida Provisória 2.200/2001-2.

                </p>
                <div
                  style={{
                    opacity: 0, margin: '-300px', width: 0, zIndex: 100, height: 0,
                  }}
                  ref={bottom}
                />
              </>

            )} */}
        </div>
        {isFinalScroll && (
          <img
            src={downArrow}
            alt="scroll"
            id="scroll-to-bottom"
            onClick={scrollToBottom}
          />
        )}
      </Term>
      <div className="container-button">
        <div>
          <input
            type="checkbox"
            id="agree"
            name="agree"
            disabled={isFinalScroll}
            onChange={e => {
              if (e.target.checked) {
                setCheckbox(false);
              } else {
                setCheckbox(true);
              }
            }}
          />
          <label htmlFor="agree">
            Li e aceito os
            <span>Termos de uso</span>
          </label>
        </div>
        <div>
          {saleCheckout?.student.document_number ? (
            <button
              type="button"
              disabled={checkbox}
              onClick={handleConfirm}
              id="confirm"
            >
              Confirmar
            </button>
          ) : (
            <button
              type="button"
              disabled={checkbox}
              onClick={handleSubmit}
              id="confirm"
            >
              Confirmar
            </button>
          )}
        </div>
      </div>
    </Container>
  );
};

export default TermsOfUse;
