import { createGlobalStyle } from 'styled-components';

import ObjectSansRegular from './ObjectSans-Regular.otf';
import ObjectSansHeavy from './ObjectSans-Heavy.otf';

export default createGlobalStyle`
@font-face {
  font-family: Object Sans;
  font-weight: normal;
  font-style: normal;
  src: url(${ObjectSansRegular});
}
@font-face {
  font-family: Object Sans;
  font-weight: bold;
  font-style: normal;
  src: url(${ObjectSansHeavy});
}
:root {
  --primary-color: #296394;
  --black-background: #141B23;
  /* --primary-color: #296394;
  --black-background: #141b23;
   */
  /* --primary-color: #296394;
  --black-background: #141B23;
  */
}
  html, body, div, span, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, a, em, img, dl, dt, dd, ol, ul, li, fieldset, form, label, input, legend, article, footer, header, nav, section, main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;

  }

  html, body, #root {
    min-height: 100%;
  }

  html {
    font-size: 62.5%;
  }

  body, button {
    font-family: Object Sans, sans-serif;
    -webkit-font-smoothing: antialiased !important;
  }
  textarea {
    font-family: Object Sans, sans-serif;
    -webkit-font-smoothing: antialiased !important;

  }

  input[type="date"]{
    font-family: Object Sans, sans-serif;
    -webkit-font-smoothing: antialiased !important;
  }

  ul, ol {
    list-style: none;
  }
  .animeTop {
    opacity: 0;
    transform: translateY(-30px);
    animation: animeTop 0.5s forwards;
  }

  @keyframes animeTop {
    to {
      opacity: 1;
      transform: initial;
    }
  }
`;

export const colors = {
  primary: '#296394',
  secondary: '#141B23',
  white: '#fff',
};
