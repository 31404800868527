import React, { createContext, useState, useContext } from 'react';
import { ISale } from '../models/Sale';

interface SaleCheckoutContextData {
  saleHash: string | undefined;
  setSaleHash: React.Dispatch<React.SetStateAction<string | undefined>>;
  saleCheckout: ISale | undefined;
  setSaleCheckout: React.Dispatch<React.SetStateAction<ISale | undefined>>;
}

const SaleCheckoutContext = createContext<SaleCheckoutContextData>(
  {} as SaleCheckoutContextData,
);

const SaleCheckoutProvider: React.FC = ({ children }) => {
  const [saleCheckout, setSaleCheckout] = useState<ISale | undefined>(
    undefined,
  );

  const [saleHash, setSaleHash] = useState<string | undefined>(undefined);

  return (
    <SaleCheckoutContext.Provider
      value={{
        saleCheckout,
        setSaleCheckout,
        saleHash,
        setSaleHash,
      }}
    >
      {children}
    </SaleCheckoutContext.Provider>
  );
};

function useSaleCheckout(): SaleCheckoutContextData {
  const context = useContext(SaleCheckoutContext);
  if (!context) {
    throw new Error(
      'UseSaleCheckout must be used within an SaleCheckoutProvider',
    );
  }
  return context;
}

export { SaleCheckoutProvider, useSaleCheckout };
