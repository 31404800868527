import styled from 'styled-components';

export const HomeContainer = styled.div`
  @media (max-width: 990px) {
    padding: 0 3rem;
  }
  @media (max-width: 800px) {
    padding: 0 3rem;
  }
  @media (max-width: 600px) {
    padding: 0 2rem;
  }
  .video-container {
    background-color: var(--primary-color);
    border-radius: 2.4rem;
    text-decoration: none;

    display: flex;
    flex-direction: column;

    height: 40rem;
    max-width: 100rem;
    margin: 5.4rem auto 3rem;
    z-index: 1;
    cursor: pointer;
    @media (max-width: 770px) {
      height: 40rem;
    }
    @media (max-width: 540px) {
      height: 32rem;
    }
    @media (max-width: 420px) {
      height: 26rem;
    }
  }
  .skeleton-video {
    background-color: white;
    border-radius: 2.4rem;
    width: 100rem;

    height: 40rem;
    margin: 5.4rem auto 1rem;
    @media (max-width: 770px) {
      height: 40rem;
    }
    @media (max-width: 540px) {
      height: 32rem;
    }
    @media (max-width: 420px) {
      height: 26rem;
    }
  }

  .video-container .square {
    width: 100%;
    max-height: 35rem;
    display: flex;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    cursor: pointer;

    @media (max-width: 770px) {
      height: 40rem;
    }
    @media (max-width: 540px) {
      height: 28rem;
    }
    @media (max-width: 420px) {
      height: 22rem;
    }
  }

  .video-container .bottom {
    display: flex;
    width: 100%;
    height: 15%;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  .video-container .bottom button {
    border: none;
    max-width: 20rem;
    display: flex;
    background: #296394;
    flex-direction: row;
    align-items: center;

    justify-content: center;
    margin-left: 25px;
    cursor: pointer;
  }

  .video-container .bottom button h3 {
    width: 100%;
    margin-left: 1rem;
    font-weight: 800;
    font-size: 2rem;
    color: #141b23;
    @media (max-width: 600px) {
      font-size: 1.3rem;
    }
  }

  h2 {
    font-weight: 800;
    font-size: 2rem;
    line-height: 1.9rem;
    text-align: center;
    margin-top: 2.8rem;
  }

  .infos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.6rem;
    margin-top: 2rem;
    max-width: 100rem;
    margin: 2rem auto 0;
    @media (max-width: 700px) {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .grid-left {
    display: flex;
    flex-direction: column;

    max-width: 100%;
    .select-course {
      display: grid;
      grid-template-columns: 3fr 2fr;
      gap: 1.6rem;

      width: 100%;
      @media (max-width: 350px) {
        display: flex;
        flex-direction: column;

        width: 100%;
      }
      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .MuiSvgIcon-root {
        color: white;
        font-size: 2.5rem;
        margin: -8px;
      }
      .items-left label {
        ::after {
          font-weight: 800;
          font-size: 1.2rem;
          line-height: 1.2rem;
          color: 'white';
        }
        color: 'white';
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 1.2rem;
      }
      .items-left input,
      .items-left select {
        background-color: #fff;
        border: none;
        color: #000;
        border-radius: 1rem;
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 1.2rem;

        width: 100%;
        height: 2.5rem;
        padding: 0.8rem 0.6rem 0.4rem;
        margin: 0.4rem 0;
        outline: none;
        @media (max-width: 600px) {
          height: 3rem;
          padding: 1rem 0.8rem 0.6rem;
        }
        @media (max-width: 350px) {
          width: 100%;
        }
      }
      .items-left select {
        padding: 0;
        outline: none;
        cursor: pointer;
        outline: none;
      }
      .items-right span {
        background-color: #fff;
        border: none;
        color: #000;
        border-radius: 1rem;
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 1rem;
        text-align: center;

        width: 100%;
        height: 2.5rem;
        padding: 0.9rem 0.6rem 0.6rem;
        margin: 0.4rem 0;
        @media (max-width: 600px) {
          height: 3rem;
          padding: 1rem 0.8rem 0.6rem;
        }
      }
      .items-right input {
        background-color: #fff;
        border: none;
        color: #000;
        border-radius: 1rem;
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 1rem;
        text-align: center;
        align-items: center;
        width: 100%;
        height: 2.5rem;
        padding: 1rem;
        margin: 0.4rem 0;
        outline: none;
        @media (max-width: 600px) {
          height: 3rem;
        }
      }
    }
    .description-course {
      background-color: var(--primary-color);
      border-radius: 1rem;
      color: var(--black-background);

      width: 100%;
      height: 14rem;
      padding: 1.2rem;
      margin: 2rem 0;
      overflow: auto;
      ::-webkit-scrollbar-track {
        background-color: #c4c4c4;
        border-radius: 2rem;
      }
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        @media (max-width: 800px) {
          width: 4px;
          height: 4px;
        }
      }
      ::-webkit-scrollbar-thumb {
        background: #646464;
        border-radius: 2rem;
      }
      @media (max-width: 350px) {
        background-size: 30rem;
        max-width: 30rem;
      }
      h3 {
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 1.3rem;

        margin-bottom: 1.2rem;
      }
      p {
        font-size: 1.1rem;
        line-height: 1.1rem;

        margin-bottom: 0.4rem;
        word-break: break-word;
      }
    }
    .create-link {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 1.6rem;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 2rem;
      button {
        background: var(--primary-color);
        border: 1px solid var(--primary-color);
        border-radius: 1rem;
        font-size: 1.2rem;
        line-height: 1.2rem;

        padding: 0.2rem 1.6rem;
        outline: none;
        cursor: pointer;
        @media (max-width: 600px) {
          height: 3rem;
        }
      }
    }
    .copy-clipboard {
      display: flex;
      input {
        border-radius: 1rem 0 0 1rem;
        border: 1px solid var(--primary-color);
        font-size: 1rem;

        width: 100%;
        padding: 0 0.8rem;
        outline: none;
        @media (max-width: 600px) {
          height: 3rem;
          padding: 0.8rem 0.8rem 0.6rem;
        }
      }
      button {
        background: var(--primary-color);

        padding: 0.2rem 1.6rem;
        margin-left: -0.8rem;
        /* transition: all 1s; */

        &:active {
          background: #50ebaf;
        }
      }
    }
    .description-link {
      font-size: 1.25rem;
      line-height: 1.3rem;
      p {
        margin-bottom: 0.8rem;
      }
    }
  }
  .skeleton-grid-left {
    background-color: #fff;
    width: 100%;
    height: 34rem;
  }
  .grid-right {
    background-color: #fff;
    border-radius: 1rem;
    display: grid;
    grid-template-columns: 3fr 2fr;
    justify-content: space-around;
    grid: 1fr 1fr;
    width: 100%;
    max-height: 16.4rem;
    padding: 1.4rem 2.2rem;
    @media (max-width: 700px) {
      max-width: 48rem;
    }
    .results {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      width: 100%;
      margin-bottom: 3rem;
      h3 {
        color: var(--black-background);
        font-size: 2rem;
        line-height: 2rem;
      }
      .select {
        background: #296394;
        border: 1px solid #296394;
        border-radius: 1rem;
        color: var(--black-background);

        width: 12.6rem;
        height: 2.4rem;
      }
    }
    .numbers-of {
      display: flex;
      align-items: center;

      margin-bottom: 2.4rem;
      h3 {
        color: #296394;
        font-size: 2rem;
        line-height: 2rem;
      }
      span {
        color: var(--black-background);
        font-size: 1.6rem;
        line-height: 1.6rem;

        margin-left: 0.8rem;
      }
    }
  }
  .skeleton-grid-right {
    background-color: #fff;
    width: 100%;
    height: 16rem;
  }

  .telegram {
    display: flex;

    border-width: 0;
    outline: none;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    border-radius: 1rem;
    font-size: 2rem;

    min-height: 5rem;

    padding: 1rem;
    outline: none;
    cursor: pointer;
    margin: 1rem 0;
    background-color: var(--primary-color);
    color: var(--black-background);
    justify-content: space-between;
    img {
      width: 10%;
    }

    p {
      width: 90%;
    }
  }
`;
