import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { LoadingBarProvider } from './loadingBar';
import { PaymentHelperServiceProvider } from './paymentService';
import { SaleCheckoutProvider } from './saleCheckout';
import { BackgroundFixedProvider } from './isBackgroundFixed';
import { FullscreenLoadingProvider } from './fullscreenLoading';

const AppProvider: React.FC = ({ children }) => (
  <FullscreenLoadingProvider>
    <ToastProvider>
      <LoadingBarProvider>
        <AuthProvider>
          <PaymentHelperServiceProvider>
            <SaleCheckoutProvider>
              <BackgroundFixedProvider>{children}</BackgroundFixedProvider>
            </SaleCheckoutProvider>
          </PaymentHelperServiceProvider>
        </AuthProvider>
      </LoadingBarProvider>
    </ToastProvider>
  </FullscreenLoadingProvider>
);

export default AppProvider;
