import styled from 'styled-components';

export const Container = styled.div`
  .background {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 2px black;
    border-radius: 1rem;

    padding: 1.4rem 1.4rem 0.6rem;
  }
  label {
    display: block;
    align-self: flex-start;
    color: black;
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 1.2rem;

    margin-bottom: 0.6rem;
  }

  .short-input {
    border: solid 1px black;
    border-radius: 1rem;
    font-size: 1.2rem;
    display: block;
    width: 17rem;
    height: 3.3rem;
    padding: 0.4rem;
    margin-bottom: 1.2rem;
    transition: 0.2s;
    &::placeholder {
      font-size: 1.4rem;
    }
  }
  .short-input:hover,
  .short-input:focus {
    outline: none;
    border: 1px solid var(--primary-color);
    background-color: #fff;
    box-shadow: 0 0 0 1px var(--primary-color);
  }

  .long-input {
    box-sizing: border-box;
    border: solid 1px black;
    border-radius: 1rem;
    font-size: 1.2rem;
    display: block;
    width: 17rem;
    height: 10rem;
    padding: 0.4rem;
    resize: none;
    margin-bottom: 1.2rem;
    transition: 0.2s;
    &::placeholder {
      font-size: 1.4rem;
    }
  }
  .long-input:hover,
  .long-input:focus {
    outline: none;
    border: 1px solid var(--primary-color);
    background-color: #fff;
    box-shadow: 0 0 0 1px var(--primary-color);
  }
  .submit-btn {
    background: #141b23;
    border: 1px solid #296394;
    box-sizing: border-box;
    border-radius: 10px;
    color: white;
    width: 17rem;
    height: 4rem;
    font-family: Object Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    &:hover,
    &:focus {
      color: var(--primary-color);
      outline: none;
      border: 1px solid var(--primary-color);
      box-shadow: 0 0 0 1px var(--primary-color);
    }
  }
  .button-back {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 1.2rem;

    display: block;

    padding: 0.4rem;
    margin: 0.4rem auto 0;
    cursor: pointer;
    outline: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
