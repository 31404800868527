import React, { useRef, useCallback } from 'react';
import { FiLogIn, FiLock, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import logo from '../../Assets/whiteLogo.png';
import { Container, Content, Background } from './styles';
import SignInButton from './SignInButton';
import SignInInput from './SignInInput';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { signIn, user } = useAuth();
  const { addToast } = useToast();

  console.log(user);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail Obrigatório')
            .email('Digite um email válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: true });

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          addToast({
            type: 'error',
            title: 'Ocorreu um erro, cheque as credenciais',
            description: err.message,
          });
        }
      }
    },
    [signIn, addToast],
  );
  return (
    <Container>
      <Content>
        <img src={logo} alt="Logo Fael" width="176" className="animeTop" />

        <Form ref={formRef} onSubmit={handleSubmit}>
          {/* <h1>Faça LogIn</h1> */}
          <SignInInput name="email" icon={FiMail} placeholder="e-mail" />
          <SignInInput
            name="password"
            icon={FiLock}
            placeholder="senha"
            type="password"
          />
          <SignInButton type="submit">Entrar</SignInButton>
          <a href="/recovery">Esqueci minha senha</a>
        </Form>
        <a href="/cadastrar">
          <FiLogIn />
          Criar Conta
        </a>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
