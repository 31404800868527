/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Logo from '../../Assets/logo.png';

import { TermosContainer } from './styles';

const Termos: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [content, setContent] = useState<JSX.Element>(
    <>
      <p>Não encontramos o documento requisitado</p>
    </>,
  );

  useEffect(() => {
    if (id === 'conditions') {
      setContent(
        <>
          <p>
            <strong>Condições de Adesão ao Programa</strong>
          </p>
          <p>
            <strong>Consultor Educacional WUP</strong>
          </p>
          <p>Olá seja bem vindo!</p>
          <p>
            Primeiro precisamos te conhecer e por isso solicitamos o seu
            cadastro, e ao aceitar os termos você se torna guardião junto
            conosco das informações, metodologia, técnicas e detalhes deste
            nosso negócio.
          </p>
          <p>
            É primordial a sua leitura atenta destas Condições de Adesão ao
            Programa de consultor acadêmico WUP, e caso esteja de acordo, a
            manifestação da sua ciência e concordância mediante o click no
            aceite. A leitura das Condições que estamos apresentando é muito
            importante e temos certeza que você absorverá integralmente cada uma
            das informações prestadas.
          </p>
          <p>Muito bem, vamos lá então.</p>
          <p>
            Todo <strong>Consultor Educacional WUP</strong>
          </p>
          <p>
            terá o pleno domínio da atividade, pois será instruído e munido de
            informações produzidas com tecnologia de ponta, capaz de desenvolver
            habilidades e competências para a promoção e venda dos produtos
            educacionais da WUP.
          </p>
          <p>
            Enquanto você estiver vivendo a experiência da imersão nos conteúdos
            e informações produzidos, seria importante a sua prévia organização
            como Micro Empreendedor Individual (MEI). Você pode também ser sócio
            de uma empresa prestadora de serviços, caso prefira. No nosso manual
            você receberá as informações necessárias para a formalização da
            atividade como <strong>Consultor Educacional WUP.</strong>
          </p>

          <p>
            Caso você ainda não seja um MEI ou sócio de uma sociedade prestadora
            de serviços, e tenha interesse em empreender, você poderá encontrar,
            em nosso manual, quais são as informações necessárias para sua
            inscrição/constituição. Assim, sua empresa estará credenciada para a
            promoção dos nossos negócios.
          </p>
          <p>
            Para ser um <strong>Consultor Educacional WUP</strong>, você
            precisará também de uma ferramenta de comunicação que,
            provavelmente, você já tem: smartphone,
            <em>tablet</em>,<em>notebook</em>
            ou similar compatível, com acesso à<em>internet</em>.
          </p>
          <p>
            O seu universo de atuação será em sua cidade, estado e no Brasil e,
            nos dias e horários que quiser.
          </p>
          <p>
            Acreditamos que seus custos serão baixos e, de qualquer modo, não
            reembolsaremos despesas.
          </p>
          <p>
            A renda do <strong>Consultor Educacional WUP</strong>
          </p>
          <p>
            é variável, sem garantia de valor mínimo, e depende, exclusivamente,
            do sucesso das vendas que conseguir concluir. Por isso, sua
            iniciativa pode fazer muita diferença, pois a remuneração
            corresponderá as suas vendas diretas e demais bonificações conforme
            detalhado em seu plano de carreira e negócios. Quanto mais promover
            e fomentar os cursos, maior a chance de captar mais alunos e de
            realizar mais matrículas. Isso refletirá em mais dinheiro no seu
            bolso. A renda poderá ser complementar ou principal. Só depende do
            sucesso do seu esforço.
          </p>
          <p>
            Com o tempo, é possível que seja permitida a subagência, que nada
            mais é que a possibilidade de o{' '}
            <strong>Consultor Educacional WUP</strong>
          </p>
          <p>
            ter outras pessoas empreendendo com ele. As condições constam do
            nosso plano de carreira que prevê níveis de avanço na carreira e
            também atráves da aquisição de &quot; Licenças &quot; que conforme o
            plano de negócios poderá ampliar seus ganhos.
          </p>
          <p>
            Outro aspecto bacana é que nosso vínculo está limitado apenas por
            nossa vontade de estarmos juntos, pois o Contrato de Agência que
            será firmado com o <strong>Consultor Educacional WUP</strong>
          </p>
          <p>
            não terá prazo definido. Não queremos que você se sinta preso (e
            vice-versa), mas que voluntariamente contribua com a realização da
            nossa VISÃO &quot; EDUCAR PARA TRANSFORMAR &quot; executando
            diariamente nossa MISSÃO &quot; INSPIRAR PESSOAS E REALIZAR SONHOS
            &quot; temos a convicção firme que uma graduação, pós ou demais
            cursos são chave para esta realização.
          </p>
          <p>
            Obrigado por acreditar conosco e investir sua vida e talentos neste
            projeto.
          </p>
          <p>Prof Aislan Westphal</p>
        </>,
      );
    } else if (id === 'cookies') {
      setContent(
        <>
          <p>
            <strong>
              Política de
              <em>Cookies</em>
            </strong>
          </p>
          <p>
            <strong>CATEGORIAS DE COOKIES</strong>
          </p>
          <p>
            PREFERÊNCIAS: Categoria de
            <em>cookie</em>
            que permite à Educacional WUP Ltda lembrar informações que mudam a
            forma como o<em>site</em>
            se comporta ou é exibido, como o idioma preferido ou a região em que
            o usuário está localizado.
          </p>
          <p>
            SEGURANÇA: Categoria de
            <em>cookie</em>
            empregada para verificar a identidade do usuário, buscando evitar a
            utilização fraudulenta de credenciais de
            <em>login</em>e proteger os dados do usuário de terceiros não
            autorizados.
          </p>
          <p>
            RASTREAMENTO: Categoria de
            <em>cookie</em>
            que, através do armazenamento de arquivos nos dispositivos dos
            <em>websites</em>
            dos nossos usuários (consultor), registra
            <em>clicks</em>
            em
            <em>links</em>, anúncios e transações comerciais, como vendas e
            <em>leads</em>.
          </p>
          <p>
            PUBLICIDADE: Categoria de
            <em>cookie</em>
            utilizada para tornar a adequar a publicidade ao perfil do usuário
            impactado, melhorando sua experiência e buscando conferir maior
            efetividade para os consultores
          </p>
          <p>
            PROCESSOS: Categoria de
            <em>cookie</em>
            empregada com o objetivo de melhorar o funcionamento da Educacional
            WUP Ltda, buscando que os consultores encontrem os serviços e
            informações esperados.
          </p>
          <p>
            Destaca-se que o único propósito dos
            <em>cookies</em>
            de rastreamento aplicados pela Educacional WUP Ltda é identificar
            uma transação efetivada por um consultor através do seu
            <em>link</em>, permitindo assim o seu comissionamento pela transação
            concretizada.Portanto, o referido
            <em>cookie</em>
            não coleta dados de identificação pessoal, mas tão somente quando e
            em qual dispositivo um click em um determinado
            <em>link</em>
            de consultor ocorreu, sendo essas as informações registradas.
          </p>
          <p>
            A Educacional WUP Ltda não será responsabilizada por eventual falha
            de seus consultores em cumprir as leis e normativos aplicáveis ao
            ato negocial, se reservando ao direito de suspender e/ou cancelar o
            contrato com os consultores que não estejam em conformidade com a
            legislação pátria, bem como reter o saldo de valores a receber de
            tais consultores como ressarcimento parcial dos danos que tiver
            experimentado, ou entregue a terceiros prejudicados, mediante ordem
            judicial.
          </p>
          <p>
            <strong>
              GESTÃO DE
              <em>COOKIES</em>
            </strong>
          </p>
          <p>
            Todos os
            <em>browsers</em>
            (navegadores como
            <em>Google Chrome, Internet Explorer, FireFox,</em>
            etc) permitem ao usuário, ao visitar/acessar um
            <em>website</em>, aceitar, recusar ou apagar
            <em>cookies</em>, nomeadamente através da seleção das definições
            apropriadas no respetivo navegador. O usuário poderá configurar os
            <em>cookies</em>
            no menu “opções” ou “preferências” do seu
            <em>browser</em>.
          </p>
          <p>
            Ressaltamos, contudo, que, desabilitar parcialmente ou totalmente os
            <em>cookies</em>
            poderá acarretar no funcionamento inadequado do
            <em>website</em>.
          </p>
          <p>
            A Educacional WUP Ltda se reserva o direito de, a qualquer momento,
            alterar esta Política de
            <em>Cookies</em>
            atualizando seu conteúdo conforme as necessidades de aperfeiçoamento
            tecnológico ou de suas funcionalidades.
          </p>
        </>,
      );
    } else if (id === 'privacy') {
      setContent(
        <>
          <p>
            <strong>Política de Privacidade EDUCACIONAL WUP</strong>
          </p>
          <p>
            Nós nos preocupamos com sua privacidade e temos o compromisso de
            preservá-la.
          </p>
          <p>
            Assim, além de aceitar os Termos de Uso, você precisa conhecer e
            estar de acordo com a nossa Política de Privacidade, que possui
            informações claras e completas sobre a forma com que suas
            informações são coletadas, recepcionadas, classificadas, utilizadas,
            processadas, controladas e/ou, de qualquer outro modo, tratadas. Por
            isso, servimo-nos desta Política de Privacidade para descrever as
            práticas adotadas pela EDUCACIONAL WUP LTDA com relação aos dados
            coletados dos Usuários na EDUCACIONAL WUP LTDA.
          </p>
          <p>
            <strong>DEFINIÇÕES</strong>
          </p>
          <p>
            As expressões contidas no item DEFINIÇÕES dos Termos de Uso são
            empregadas nesta Política de Privacidade com o mesmo significado.
          </p>
          <p>
            Sempre que as expressões abaixo forem usadas em letra maiúscula
            nesta Política de Privacidade, elas terão os seguintes significados:
          </p>
          <p>
            Dados Cadastrais – conjunto de informações pessoais do Usuário, de
            modo que seja possível sua individualização (incluindo o endereço
            eletrônico), e a qualificação pessoal, entendida como nome, prenome,
            estado civil e profissão do Usuário e dados bancários, que devem ser
            todas verdadeiras, válidas e corretas.
          </p>
          <p>
            Dados Pessoais – dados relacionados à pessoa natural identificada ou
            identificável responsável pela pessoa jurídica usuária da
            EDUCACIONAL WUP LTDA. Trata-se das informações relacionadas à pessoa
            natural identificada ou identificável, por exemplo: nome, fotos,
            e-mail, dados bancários, geolocalização, telefones, postagens em
            redes sociais, endereço de IP, dados cadastrais, etc.
          </p>
          <p>
            Tratamento de Dados Pessoais - toda operação realizada com dados
            pessoais, como as que se referem à coleta, produção, recepção,
            classificação, utilização, acesso, reprodução, transmissão,
            distribuição, processamento, arquivamento, armazenamento,
            eliminação, avaliação ou controle da informação, modificação,
            comunicação, transferência, difusão ou extração.
          </p>
          <p>
            <strong>COLETA DE DADOS PESSOAIS E INFORMAÇÕES</strong>
          </p>
          <p>
            Primeiramente é importante ressaltar ser imprescindível para a
            EDUCACIONAL WUP LTDA o tratamento de dados pessoais obtidos dos
            Usuários, a fim de possibilitar a prestação dos serviços ofertados
            através de sua EDUCACIONAL WUP LTDA.
          </p>
          <p>
            É preciso que você concorde expressamente e de forma inequívoca com
            a possibilidade de a EDUCACIONAL WUP LTDA coletar, receber e
            armazenar, automaticamente, dados pessoais e informações em seus
            servidores sobre as atividades advindas do navegador ou aplicativo
            dos Usuários, incluindo geolocalização, endereços IP,
            <em>cookies</em>, ou outras sequências de caracteres identificadores
            e, informações sobre as transações efetuadas por meio da EDUCACIONAL
            WUP LTDA.
          </p>
          <p>
            Além disso, vale lembrar que, para usufruir das ferramentas
            oferecidas pela EDUCACIONAL WUP LTDA, você deverá efetuar um
            cadastro informando dados pessoais, bem como criar uma senha de
            acesso que será de seu conhecimento exclusivo.
          </p>
          <p>
            Você está ciente que é de sua responsabilidade fornecer informações
            verdadeiras, exatas, atuais e completas quando for realizar seu
            cadastro para acesso à EDUCACIONAL WUP LTDA. Lembramos que a
            indicação de dados incompletos ou falsos é crime tipificado em lei e
            pode acarretar punições severas a quem as fornecer.
          </p>
          <p>
            <strong>CONTROLE DOS DADOS CADASTRAIS PELOS USUÁRIOS</strong>
          </p>
          <p>
            Cada usuário tem controle sobre os dados cadastrais constantes no
            perfil criado na EDUCACIONAL WUP LTDA, podendo acessá-los e
            alterá-los mediante o uso de seu
            <em>login</em>e senha.Lembramos que o<em>login</em>e a senha são
            individuais e conhecidos apenas pelo próprio usuário que deverá
            zelar pelo sigilo desses dados.
          </p>
          <p>
            <strong>
              COLETA E USO DOS DADOS PESSOAIS E INFORMAÇÕES COLETADAS
            </strong>
          </p>
          <p>
            Os dados pessoais coletados, bem como quaisquer outras informações
            coletadas, poderão ser usados para fins de comunicação da nossa
            equipe, a partir de envio de notificações sobre sua atividade com as
            ferramentas da EDUCACIONAL WUP LTDA, bem como alertas e notificações
            do sistema.
          </p>
          <p>
            Além disso, os dados pessoais coletados, bem como quaisquer outras
            informações e registros de acesso às ferramentas da EDUCACIONAL WUP
            LTDA, poderão ser armazenados, tratados, processados e utilizados
            por nós com as seguintes finalidades:
          </p>
          <ul>
            <li>
              possibilitar a prestação dos serviços ofertados de forma eficaz e
              eficiente, permitindo a sua operacionalização de maneira ágil e
              segura;
            </li>
            <li>
              realizar análises e estudos relacionados ao comportamento,
              interesses e demografia dos Usuários, a fim de entender suas
              demandas e necessidades, para, dessa forma, prover serviços mais
              adequados, eficientes e interessantes, melhorando a experiência de
              usabilidade das ferramentas da EDUCACIONAL WUP LTDA;
            </li>
            <li>
              aperfeiçoar nossas estratégias comerciais e de
              <em>marketing</em>
              objetivando aprimorar os serviços ofertados; e enviar aos
              Usuários, através de mensagens, informações relativas aos
              produtos, serviços, publicidade, promoções,
              <em>banners</em>e notícias da EDUCACIONAL WUP LTDA.
            </li>
          </ul>
          <p>
            <strong>DADOS PESSOAIS E DIREITOS DOS USUÁRIOS</strong>
          </p>
          <p>
            Destacamos que é direito dos Usuários receberem informações claras e
            completas, sempre que formalmente solicitado, sobre dados pessoais
            eventualmente coletados e/ou processados pela EDUCACIONAL WUP
            LTDA.Para informações mais detalhadas sobre a forma como os dados
            são colhidos, uso de
            <em>cookies</em>e ferramentas similares, acesse nossa Política de
            <em>Cookies</em>.
          </p>
          <p>
            Além disso, os Usuários sempre poderão entrar em contato com a
            EDUCACIONAL WUP LTDA para requerer o exercício dos seus direitos de
            exclusão, retificação, correção e restrição de uso quanto aos seus
            dados pessoais, inclusive fornecendo os dados pessoais solicitados
            em formatação adequada, estruturada e usual.
          </p>
          <p>
            Para o exercício de qualquer dos seus direitos atrelados aos dados
            pessoais eventualmente colhidos e processados pela EDUCACIONAL WUP
            LTDA, bem como para enviar dúvidas e questionamentos, os Usuários
            deverão entrar em contato através do Canal de Ajuda.
          </p>
          <p>
            Ressaltamos que, alguns dados pessoais colhidos deverão por
            obrigações legais impostas à EDUCACIONAL WUP LTDA pelas autoridades
            competentes, serem mantidos em nossos arquivos por um prazo mínimo
            legalmente determinado, ainda que o Usuário solicite sua exclusão
            e/ou alteração.
          </p>
          <p>
            Mesmo após o término do contrato e consequentemente da conta do
            Usuário, a EDUCACIONAL WUP LTDA poderá manter seus dados pessoais
            (em parte ou totalmente), a fim de poder direcionar e resolver
            problemas e atender solicitações de usuários, bem como para permitir
            que a conta na EDUCACIONAL WUP LTDA seja reativada caso solicitado.
          </p>
          <p>
            Não obstante, garantimos aos Usuários aplicar os melhores esforços
            objetivando manter todos os dados pessoais coletados devidamente
            seguros, protegidos e confidenciais, com a adoção de medidas
            tecnológicas disponíveis em mercado para proteger os dados pessoais,
            mantê-los sob sigilo e confidencialidade.Para isso é adotada a
            criptografia de dados e<em>softwares</em>
            de gestão periódica de vulnerabilidades.
          </p>
          <p>
            Em casos de vulnerabilidade, nos comprometemos a informar os
            Usuários o ocorrido e quais dados foram violados, em prazo razoável,
            dentro do princípio da transparência e confiança que deve reger a
            relação entre EDUCACIONAL WUP LTDA e seus Usuários, nos termos
            exigidos pela legislação aplicável.
          </p>
          <p>
            Destaca-se que o Usuário, ao retirar seu consentimento e/ou requerer
            o cancelamento de sua conta na EDUCACIONAL WUP LTDA, será
            responsável por excluir, dentro do prazo razoável e adequado, todos
            os dados e informações de terceiros que tenha recebido e/ou
            armazenado em virtude do uso da EDUCACIONAL WUP LTDA e suas
            ferramentas tecnológicas, observando de igual forma, sob sua inteira
            e exclusiva responsabilidade, a legislação de proteção de dados
            aplicável.
          </p>
          <p>
            A EDUCACIONAL WUP LTDA poderá armazenar e tratar os dados pessoais e
            demais informações coletadas em servidores localizados no Brasil ou
            no exterior.
          </p>
          <p>
            <strong>
              DIVULGAÇÃO A TERCEIROS DOS DADOS CADASTRAIS E PESSOAIS COLETADOS
            </strong>
          </p>
          <p>
            A EDUCACIONAL WUP LTDApoderá entregar, por ordem judicial, os dados
            pessoais, tais como seu nome completo e endereço eletrônico, em caso
            de infração dos Termos de Uso que possa provocar danos a outros
            Usuários da EDUCACIONAL WUP LTDA ou a terceiros.
          </p>
          <p>
            Do mesmo modo, na tentativa de evitar atividades ilegais, em casos
            de suspeita de fraude, disputas, reclamação quanto ao CONTEÚDO ou
            qualquer indício de ilícito cometido pelos Usuários, incluindo
            disseminação de
            <em>SPAM</em>e situações envolvendo ameaças em potencial à segurança
            física de alguém, os dados pessoais dos Usuários envolvidos poderão
            ser fornecidos sob ordem judicial. Em determinados casos, se
            considerar necessário, a EDUCACIONAL WUP LTDA poderá fornecer os
            dados coletados aos órgãos e entidades que intervenham na
            investigação ou na resolução de disputas entre Usuários.
          </p>
          <p>
            Em eventual operação societária que envolva a reestruturação do
            grupo Wiser Educação, bem como a aquisição e/ou venda de ações e
            ativos, os dados pessoais coletados poderão ser transferidos. Nesses
            casos, a EDUCACIONAL WUP LTDA poderá transmitir os dados pessoais
            dos Usuários se for adquirida, ou se houver fusão com outra(s)
            empresa(s).
          </p>
          <p>
            Excluídas as hipóteses mencionadas nesta Política de Privacidade, os
            dados pessoais, coletados diretamente pela EDUCACIONAL WUP LTDA, bem
            como os registros de acesso à EDUCACIONAL WUP LTDA (endereço de IP,
            data, hora e fuso horário) e os dados referentes às transações
            realizadas por meio da mesma, somente poderão ser revelados a
            terceiros a pedido ou por autorização específica do próprio Usuário
            e em cumprimento de ordem judicial.
          </p>
          <p>
            <strong>
              <em>SPAM</em>
            </strong>
          </p>
          <p>
            A EDUCACIONAL WUP LTDA é contra toda e qualquer prática que promova
            de qualquer espécie e tem o compromisso de assegurar que as
            mensagens enviadas pela EDUCACIONAL WUP LTDA são sempre de interesse
            ou importância para os respectivos destinatários.
          </p>
          <p>
            Da mesma forma, cobramos de nossos Usuários que não pratiquem
            <em>SPAM</em>, que se certifiquem que as mensagens enviadas sejam
            sempre de interesse do destinatário, estando desde já cientes de que
            a não observância das boas práticas da
            <em>Internet</em>
            poderá gerar o cancelamento da conta Usuário infrator na EDUCACIONAL
            WUP LTDA, conforme definido nos Termos de Uso, bem como a revelação
            dos Dados Cadastrais, nos termos desta Política de Privacidade.
          </p>
          <p>
            A EDUCACIONAL WUP LTDA se reserva ao direito de, a qualquer momento,
            alterar esta Política de Privacidade, atualizando seu conteúdo,
            conforme as necessidades de aperfeiçoamento tecnológico ou de suas
            funcionalidades.
          </p>
        </>,
      );
    }
  }, []);
  return (
    <TermosContainer>
      <div className="green-container" />
      <div className="black-container">
        <img src={Logo} height="100rem" alt="logo" />
      </div>
      <div className="container">{content}</div>
      <Link to="/profile/legal-documents" id="back">
        Voltar
      </Link>
      <footer>Copyright © 2021 WUP Sales Platform . All Rights Reserved</footer>
    </TermosContainer>
  );
};

export default Termos;
