import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  max-height: 100vh;
`;

export const SidebarStyle = styled.section`
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;

  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  padding: 2rem;
  @media (min-width: 1600px) {
    min-width: 32rem;
  }
  @media (max-width: 991px) {
    display: none;
  }
  .user-profile {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    h3 {
      font-size: 1.8rem;
      line-height: 1.9rem;
      font-weight: 800;
      text-align: center;
      word-break: break-word;

      max-width: 25rem;
      margin-top: 2rem;
    }
    p {
      font-style: italic;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.2rem;
      text-align: center;

      margin-top: 0.6rem;
    }
    span {
      background: var(--black-background);
      border: 2px solid #fff;
      border-radius: 1rem;
      font-size: 1.3rem;
      text-align: center;
      padding: 0.8rem 3.2rem 0.6rem;
      margin-top: 1.1rem;
      @media (min-width: 1400px) {
        font-size: 1.5rem;
      }
    }
  }
  .container-links {
    margin-top: 4rem;
    ul {
      list-style: none;
      padding: 0.2rem 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      -webkit-box-align: center;
      align-items: center;
    }
    .duvidas {
      padding-top: 2rem;
    }
    li {
      position: relative;
      width: 20rem;
      height: 5rem;
      /* margin-bottom: 2rem; */
    }
    a {
      display: flex;
      flex-direction: row;
      -webkit-box-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      align-items: center;
      text-decoration: none;
      width: 100%;
      height: 100%;
      cursor: default;
      img {
        padding: 0px 2rem;
        max-width: 64px;
      }
      span {
        color: #fff;
        font-size: 1.2rem;
        line-height: 1.2rem;
        padding: 0.4rem 0.2rem;
        cursor: pointer;
        &:hover {
          font-weight: 700;
        }
      }
      @media (min-width: 1600px) {
        font-size: 1.4rem;
        line-height: 1.4rem;
      }
    }
    .active {
      font-weight: 700;
    }
  }
`;
