import React, { createContext, useState, useContext, useEffect } from 'react';

export interface ICreditCard {
  brand: 'visa' | 'mastercard' | 'elo'; // bandeira do cartão
  number: string; // número do cartão
  cvv: string; // código de segurança
  expiration_month: string; // mês de vencimento
  expiration_year: string; // ano de vencimento
}

// * Exemplo
// brand: 'visa',
// number: '4012001038443335',
// cvv: '123',
// expiration_month: '05',
// expiration_year: '2021',

interface IPaymentResponse {
  code: number;
  data: {
    card_mask: string;
    payment_token: string;
  };
}

interface IPaymentHelperService {
  getPaymentToken: (
    creditCard: ICreditCard,
    callback: (error: any, response: IPaymentResponse) => void,
  ) => void;
}

const getPaymentService = (): Promise<IPaymentHelperService> =>
  new Promise(resolve => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    $gn.ready((checkout: IPaymentHelperService) => {
      resolve(checkout);
    });
  });

type IGetCCPToken = (creditCard: ICreditCard) => Promise<IPaymentResponse>;
interface PaymentHelperServiceContextData {
  getCreditCardPaymentToken: IGetCCPToken;
}

const PaymentHelperServiceContext =
  createContext<PaymentHelperServiceContextData>(
    {} as PaymentHelperServiceContextData,
  );

const PaymentHelperServiceProvider: React.FC = ({ children }) => {
  const [paymentHelperService, setPaymentHelperService] = useState<
    IPaymentHelperService | undefined
  >(undefined);

  const getCreditCardPaymentToken: IGetCCPToken = creditCard =>
    new Promise((resolve, reject) => {
      paymentHelperService?.getPaymentToken(creditCard, (error, response) => {
        if (error) {
          reject(error);
        } else {
          resolve(response);
        }
      });
    });

  useEffect(() => {
    (async () => {
      try {
        const initialPaymentService = await getPaymentService();

        setPaymentHelperService(initialPaymentService);
      } catch (error) {}
    })();
  }, []);

  return (
    <PaymentHelperServiceContext.Provider value={{ getCreditCardPaymentToken }}>
      {children}
    </PaymentHelperServiceContext.Provider>
  );
};

function usePaymentHelperService(): PaymentHelperServiceContextData {
  const context = useContext(PaymentHelperServiceContext);
  if (!context) {
    throw new Error(
      'UsePaymentHelperService must be used within an PaymentHelperServiceProvider',
    );
  }
  return context;
}

export { PaymentHelperServiceProvider, usePaymentHelperService };
