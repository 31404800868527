import styled from 'styled-components';

export const ContainerIntro = styled.section`
  background-color: #fff;
  border-radius: 2rem;
  background-position: 100% 100%;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;

  max-width: 1200px;
  height: 132px;
  margin: 5.4rem auto 3rem;
  position: relative;
  @media (max-width: 991px) {
    border-radius: 2rem;
    max-width: 100%;
    margin: 4.5rem 8rem 3rem;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: 3rem 4rem 2rem;
    div {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
  img {
    padding: 2.2rem 1.6rem 3.5rem 0;
    width: 6rem;
    @media (max-width: 600px) {
      padding: 2rem 0;
    }
  }
  h1 {
    color: var(--black-background);
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 2.5rem;

    margin-bottom: 0.8rem;
  }
  h2 {
    color: var(--black-background);
    font-weight: 800;
    font-size: 12.5px;
    line-height: 13px;

    max-width: 20rem;
  }
`;
