import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Administrativo from '../pages/Salesman/Admin';
import { CheckoutRoute } from '../pages/CheckoutPages';
import Cadastro from '../pages/CheckoutPages/StudentSignUp';
import GerarLink from '../pages/CheckoutPages/GenerateLink';
import CheckoutHome from '../pages/CheckoutPages/Home';
import TermosDeUso from '../pages/CheckoutPages/TermsOfUse';
import SignIn from '../pages/SignIn';
import Home from '../pages/Salesman/Home';
import PasswordRecovery from '../pages/Salesman/PasswordRecovery';
import CentralDeAjuda from '../pages/Salesman/Profile/HelpCenter';
import DadosBancarios from '../pages/Salesman/Profile/BankData';
import DadosPessoais from '../pages/Salesman/Profile/UserData';
import MinhaConta from '../pages/Salesman/Profile/UserProfile';
import Ranking from '../pages/Salesman/Ranking';
import TimeDeVendas from '../pages/Salesman/Team';
import Treinamento from '../pages/Salesman/Training';
import { SalesmanRoute } from '../pages/Salesman';
import TechQuestions from '../pages/Salesman/TechQuestions';
import { useAuth } from '../hooks/auth';
import ResetPassword from '../pages/ResetPassword';
import NotFound from '../pages/NotFound';
import TrainingCategory from '../pages/Salesman/TrainingCategory';
import MySales from '../pages/Salesman/MySales';
import Contract from '../pages/Salesman/SalesmanContract';
import LegalDocuments from '../pages/Salesman/Profile/LegalDocuments';

import Termos from '../pages/Termos';
import Level from '../pages/Salesman/Level';
import SignUpPlans from '../pages/SignUpPlans';

const Routes: React.FC = () => {
  const { user } = useAuth();

  const publicRoute = (Component: React.FC) =>
    user ? () => <Redirect to="/home" /> : Component;

  const privateRoute = (Component: React.FC) =>
    user ? Component : () => <Redirect to="/" />;

  return (
    <Switch>
      <Route path="/" exact component={publicRoute(SignIn)} />
      <Route
        path="/cadastrar"
        component={publicRoute(
          // SignUp
          SignUpPlans,
        )}
      />
      <Route path="/cadastrar-planos" component={publicRoute(SignUpPlans)} />
      <Route path="/recovery" component={publicRoute(PasswordRecovery)} />
      <Route path="/reset_password" component={publicRoute(ResetPassword)} />
      <Route path="/termos/:id" component={privateRoute(Termos)} />
      <SalesmanRoute path="/home" component={privateRoute(Home)} exact />
      <SalesmanRoute path="/contrato" component={privateRoute(Contract)} />
      <SalesmanRoute path="/ranking" component={privateRoute(Ranking)} />
      <SalesmanRoute path="/admin" component={privateRoute(Administrativo)} />
      <SalesmanRoute
        path="/treinamento"
        exact
        component={privateRoute(Treinamento)}
      />
      <SalesmanRoute
        path="/treinamento/:category"
        exact
        component={privateRoute(TrainingCategory)}
      />
      <SalesmanRoute
        path="/profile"
        component={privateRoute(MinhaConta)}
        exact
      />
      <SalesmanRoute path="/minhas-vendas" component={privateRoute(MySales)} />
      <SalesmanRoute path="/level" component={privateRoute(Level)} />
      <SalesmanRoute
        path="/profile/FAQ"
        component={privateRoute(CentralDeAjuda)}
      />
      <SalesmanRoute
        path="/profile/bank-data"
        component={privateRoute(DadosBancarios)}
      />
      <SalesmanRoute
        path="/profile/user-info"
        component={privateRoute(DadosPessoais)}
      />
      <SalesmanRoute
        path="/profile/legal-documents"
        component={privateRoute(LegalDocuments)}
        exact
      />
      <SalesmanRoute path="/team" component={privateRoute(TimeDeVendas)} />
      <SalesmanRoute
        path="/duvidas-tecnicas"
        component={privateRoute(TechQuestions)}
      />

      <CheckoutRoute path="/checkout/cadastro" component={Cadastro} />

      <CheckoutRoute path="/checkout/termos" component={TermosDeUso} />

      <CheckoutRoute path="/checkout/gerar-link" component={GerarLink} />

      <CheckoutRoute path="/checkout/:saleId" exact component={CheckoutHome} />

      <Route path="/404" component={NotFound} />

      <Route path="*" component={() => <Redirect to="/404" />} />
    </Switch>
  );
};

export default Routes;
