import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { formatISO } from 'date-fns';
import Intro from '../../../components/Intro';

import iconBank from '../../../Assets/icons/bank.svg';

import { ProfileGenericContainer, BankData } from './styles';
import { useToast } from '../../../hooks/toast';
import {
  GetProfile as IGetProfile,
  useEditBankData,
  useGetProfile,
} from '../../../services/api';
import { banks } from './banks';
import { bankAgencyMask, bankAccountMask } from '../../../utils/masks';
import {
  ProfileClassEnum,
  ProfileRoleEnum,
  ProfileStatusEnum,
} from '../../../models/User';

const BankUserData: React.FC = () => {
  const editBankData = useEditBankData();
  const getProfile = useGetProfile();

  const { addToast } = useToast();

  const [data, setData] = useState<IGetProfile['Response']>({
    id: '',
    name: '',
    email: '',
    password: '',
    phone_number: '',
    document_number: '',
    birth_date: '',
    bank_completed_name: '',
    bank_document_number: '',
    bank: '',
    bank_account_type: 'conta_corrente',
    bank_account: '',
    bank_agency: '',
    postal_code: '',
    state: '',
    city: '',
    neighborhood: '',
    street: '',
    complement: '',
    profile_role: ProfileRoleEnum.seller,
    profile_class: ProfileClassEnum.junior,
    status: ProfileStatusEnum.approved,
    seller_hash: '',
    seller_link: '',
    adherence_agreement: true,
    avatar_url: '',
    created_at: '',
    updated_at: '',
    referred_by: '',
  });
  const [previousData, setPreviousData] =
    useState<IGetProfile['Response']>(data);
  const [isEdit, setIsEdit] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const res = await getProfile();
        setData(res);
        if (res) {
          setData({
            ...res,
            bank: res.bank || banks[0].code,
            bank_account: res.bank_account || '',
            bank_agency: res.bank_agency || '',
          });
        } else {
          throw new Error('Não foi possível recuperar os dados do usuário');
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: `${error.message} `,
        });
      }
    })();
  }, []);

  const handleEditBankData = async () => {
    try {
      const handleValidation = async () => {
        const schema = yup.object().shape({
          bank: yup
            .string()
            .typeError('Banco inválido')
            .required('Banco é obrigatório')
            .min(3, 'Banco inválido')
            .max(3, 'Banco inválido')
            .matches(/^\d{3}$/, 'Banco inválido'),
          bank_account_type: yup
            .string()
            .typeError('Tipo de conta incorreto')
            .required('Tipo de conta é obrigatório')
            .equals(
              ['conta_corrente', 'conta_poupanca'],
              'Tipo de conta inválido',
            ),
          bank_agency: yup
            .string()
            .typeError('Agência inválida')
            .length(
              4,
              'A agência deve conter 4 números. Complete com zeros a esquerda se necessário',
            ),
          bank_account: yup
            .string()
            .typeError('Conta corrente inválida')
            .min(
              7,
              'A conta corrente deve conter no mínimo 6 números. Complete com zeros se necessário',
            ),
        });
        await schema.validate(data, { abortEarly: false });
      };
      await handleValidation();

      await editBankData(data);
      addToast({
        type: 'success',
        title: '',
        description: 'Seus dados foram atualizados',
      });
      setIsEdit(true);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: `${error.errors[0]}`,
        });
      }
    }
  };

  return (
    <ProfileGenericContainer>
      <BankData>
        <Intro
          image={iconBank}
          name="Dados Bancários"
          text="Gerencie seus dados bancários"
        />
        <section className="account">
          <h3>Informações Bancárias</h3>
          <div className="grid-form-data">
            <form>
              <>
                <div className="grid-input">
                  <div>
                    <h4>Nome Completo</h4>
                    <input
                      disabled
                      value={data.name}
                      onChange={e => setData({ ...data, name: e.target.value })}
                    />
                  </div>
                  <div>
                    <h4>Banco</h4>
                    <select
                      disabled={isEdit}
                      onChange={e => setData({ ...data, bank: e.target.value })}
                    >
                      {banks.map(info => (
                        <option
                          selected={data.bank === info.code}
                          value={info.code}
                          key={info.code}
                        >
                          {`${info.code} - ${info.name}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <h4>Agência</h4>
                    <input
                      disabled={isEdit}
                      value={data.bank_agency}
                      onChange={e =>
                        setData({
                          ...data,
                          bank_agency: bankAgencyMask(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="grid-input">
                  <div>
                    <h4>CPF/CNPJ</h4>
                    <input
                      disabled
                      value={data.document_number}
                      onChange={e =>
                        setData({
                          ...data,
                          document_number: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <h4>Tipo de Conta</h4>
                    <select
                      disabled={isEdit}
                      onChange={e =>
                        setData({
                          ...data,
                          bank_account_type: e.target.value,
                        })
                      }
                    >
                      <option
                        selected={data.bank_account_type === 'conta_corrente'}
                        value="conta_corrente"
                      >
                        Conta Corrente
                      </option>
                      <option
                        selected={data.bank_account_type === 'conta_poupanca'}
                        value="conta_poupanca"
                      >
                        Conta Poupança
                      </option>
                    </select>
                  </div>
                  <div>
                    <h4>Conta</h4>
                    <input
                      disabled={isEdit}
                      value={data.bank_account}
                      onChange={e =>
                        setData({
                          ...data,
                          bank_account: bankAccountMask(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
              </>
              {isEdit ? (
                <button
                  onClick={() => {
                    setPreviousData(data);

                    setIsEdit(false);
                  }}
                  type="button"
                  id="edit"
                >
                  Editar
                </button>
              ) : (
                <button
                  onClick={() => {
                    setIsEdit(true);
                    setData(previousData);
                  }}
                  type="button"
                  id="edit"
                >
                  Cancelar
                </button>
              )}
            </form>
          </div>
          {!isEdit ? (
            <button type="submit" id="back" onClick={handleEditBankData}>
              Salvar
            </button>
          ) : (
            <Link to="/profile" id="back">
              Voltar
            </Link>
          )}
        </section>
      </BankData>
    </ProfileGenericContainer>
  );
};

export default BankUserData;
