/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';
import { formatISO, startOfTomorrow } from 'date-fns';
import * as yup from 'yup';
import { FiArrowRight } from 'react-icons/fi';
import { FaRegPlayCircle } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

import whatsapp from '../../../Assets/icons/whatsapp.svg';
import copy from '../../../Assets/icons/copy.svg';

import { HomeContainer } from './styles';
import {
  GetHomeVideo as IGetHomeVideo,
  useGetCourses,
  useGetHomeVideo,
  useLinkGenerator,
  useGetSalesInfo,
} from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { useLoadingBar } from '../../../hooks/loadingBar';

import { ICourseBody, CourseCategoryEnum } from '../../../models/Course';
import { ISale } from '../../../models/Sale';
import { getCurrencyMask } from '../../../utils/currencyFormatter';
import DatePicker from '../../../components/DatePicker';
import SkeletonLoader from '../../../components/SkeletonLoader';
import CourseSelector from '../../../components/CourseSelector';

interface IGeneratorScreenInfo {
  date_of_expiry: string;
  seller_id: string;
  student: string;
  course: ICourseBody;
}

const Home: React.FC = () => {
  const { token, user } = useAuth();
  const { addToast } = useToast();
  const { isBarLoading } = useLoadingBar();

  const getCourses = useGetCourses();
  const linkGenerator = useLinkGenerator();
  const getHomeVideo = useGetHomeVideo();

  const [homeThumbnail, setHomeThumbnail] = useState('');
  const [isClick, setIsClick] = useState(true);
  const [saleHash, setSaleHash] = useState<ISale['hash_link']>();
  const [courses, setCourses] = useState<ICourseBody[]>([]);
  const [generatorScreenInfo, setGeneratorScreenInfo] =
    useState<IGeneratorScreenInfo>({
      date_of_expiry: formatISO(startOfTomorrow(), { representation: 'date' }),
      seller_id: user.id,
      student: '',
      course: {
        id: '',
        name: '',
        description: '',
        price: 0,
        promotional_price: 0,
        category: 'Graduação' as CourseCategoryEnum,
        picture: '',
      },
    });

  const splittedText = generatorScreenInfo.course.description.split('*%*');

  useEffect(() => {
    (async () => {
      try {
        const courseList = await getCourses({ token });
        setCourses(courseList);

        if (courseList.length) {
          setGeneratorScreenInfo({
            ...generatorScreenInfo,
            course: courseList[0],
          });
        }
        const { picture } = await getHomeVideo();
        setHomeThumbnail(picture);
      } catch (error) {}
    })();
  }, []);

  const handleLinkGenerator = async () => {
    try {
      const handleValidation = async () => {
        const schema = yup.object().shape({
          student: yup.string().required('Nome é um campo obrigatório'),
          date_of_expiry: yup.string().required('Data é obrigatória'),
        });
        await schema.validate(generatorScreenInfo, { abortEarly: false });
      };
      await handleValidation();

      const { sale_link } = await linkGenerator({
        ...generatorScreenInfo,
        student: { student_name: generatorScreenInfo.student },
        course_id: generatorScreenInfo.course.id,
      });
      setSaleHash(sale_link);

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Link gerado com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro',
        description: `${error.message}`,
      });
    }
  };
  const handleInputs = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
    reference: string,
  ) => {
    if (reference === 'name') {
      setGeneratorScreenInfo({
        ...generatorScreenInfo,
        student: e.target.value,
      });
    } else {
      const { value } = e.target;
      const course = courses.find(courseFound => courseFound.id === value);
      setGeneratorScreenInfo({
        ...generatorScreenInfo,
        course: course || courses[0],
      });
    }
  };

  const handleCopyLink = () => {
    if (saleHash) {
      setIsClick(false);
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        navigator.share({
          title: document.title,
          text: 'WUP Educacional',
          url: saleHash,
        });
      }
      addToast({
        type: 'success',
        title: '',
        description: 'Link copiado!',
      });
    }
    if (!saleHash) {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro',
        description: 'Primeiro clique em Gerar Link!',
      });
    }
  };

  return (
    <HomeContainer>
      {isBarLoading ? (
        <SkeletonLoader className="skeleton-video" />
      ) : (
        <NavLink to="/treinamento" className="video-container">
          <img
            className="square"
            src={homeThumbnail}
            alt="thumb"
            style={{ objectFit: 'cover' }}
            // onError={(e) => {
            //   const imgElement = e.target as HTMLImageElement;
            //   imgElement.src = 'https://www.sinprosp.org.br/images/404.png';
            // }}
          />
          <div className="bottom">
            <button type="button">
              <FaRegPlayCircle size={38} color="#000" />
              <h3> Assista Agora</h3>
            </button>
          </div>
        </NavLink>
      )}
      <h2>Preparando seu link de venda</h2>
      <section className="infos">
        {isBarLoading ? (
          <SkeletonLoader className="skeleton-grid-left" />
        ) : (
          <div className="grid-left">
            <div className="select-course">
              <div className="items-left">
                <input
                  placeholder="Nome do Aluno"
                  value={generatorScreenInfo.student}
                  onChange={e => {
                    handleInputs(e, 'name');
                  }}
                />

                <CourseSelector
                  items={courses}
                  onSelect={course => {
                    setGeneratorScreenInfo({
                      ...generatorScreenInfo,
                      course: course || courses[0],
                    });
                  }}
                />
                {/* <select
                  onChange={(e) => {
                    handleInputs(e, 'selector');
                  }}
                >
                  {courses.map((info) => (
                    <>
                      <option key={info.id} value={info.id}>
                        {info.name}
                      </option>
                    </>
                  ))}
                </select> */}
              </div>
              <div className="items-right">
                <input
                  type="date"
                  name="date"
                  min={formatISO(startOfTomorrow(), { representation: 'date' })}
                  value={generatorScreenInfo.date_of_expiry}
                  onChange={e => {
                    setGeneratorScreenInfo({
                      ...generatorScreenInfo,
                      date_of_expiry: e.target.value,
                    });
                  }}
                />
                <span>
                  {getCurrencyMask(
                    generatorScreenInfo.course.promotional_price,
                  )}
                </span>
              </div>
            </div>
            <div className="description-course">
              <h3>{generatorScreenInfo.course.name}</h3>
              <p>
                {splittedText[0]}
                <br />
                <br />
                {splittedText[1]}
                <br />
                <br />
                {splittedText[2]}
              </p>
            </div>
            <div className="create-link">
              <button type="button" onClick={handleLinkGenerator}>
                Gerar Link
              </button>
              <div className="copy-clipboard">
                <input
                  readOnly
                  type="text"
                  value={saleHash || 'Link Não Gerado'}
                />
                <CopyToClipboard text={`${saleHash}`}>
                  <button
                    type="button"
                    onClick={handleCopyLink}
                    className="clipboard-button"
                  >
                    <img src={copy} alt="copy" />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            <div className="description-link">
              <p>
                Este é seu link exclusivo. Envie para seu cliente para fechar
                sua venda.
              </p>
              <p>
                Somente as assinaturas realizadas por este link serão
                consideradas em sua conta
              </p>
            </div>
          </div>
        )}
        <div>
          <SalesStats isBarLoading={isBarLoading} />
          {isBarLoading ? (
            <SkeletonLoader height="8rem" />
          ) : (
            <button
              type="button"
              onClick={() => {
                if (window !== undefined) {
                  window.open(
                    'https://chat.whatsapp.com/J00pB2cKpGRCFB7VnflMht',
                  );
                }
              }}
              className="telegram"
            >
              <img src={whatsapp} alt="whatsapp" />
              <p>
                Acesse o WhatsApp e fique ligado nas dicas e informações de
                venda
              </p>
            </button>
          )}
        </div>
      </section>
    </HomeContainer>
  );
};

interface ISalesStatsProps {
  isBarLoading: boolean;
}

const SalesStats: React.FC<ISalesStatsProps> = ({ isBarLoading }) => {
  const getSalesInfo = useGetSalesInfo();
  const [dateRange, setDateRange] = useState<Date[]>([
    new Date(new Date().setDate(new Date().getDate() - 30)),
    new Date(new Date().setDate(new Date().getDate() + 1)),
  ]);
  const [salesInfo, setSalesInfo] = useState<{
    sales: number;
    referrals: number;
  }>({
    sales: 0,
    referrals: 0,
  });

  useEffect(() => {
    (async () => {
      try {
        const res = await getSalesInfo({
          after_date: formatISO(dateRange[0], { representation: 'date' }),
          before_date: formatISO(dateRange[1], { representation: 'date' }),
        });
        console.log(formatISO(dateRange[0], { representation: 'date' }));
        console.log(formatISO(dateRange[1], { representation: 'date' }));

        setSalesInfo(res);
      } catch (error) {}
    })();
  }, [dateRange]);

  return (
    <>
      {isBarLoading ? (
        <SkeletonLoader className="skeleton-grid-right" />
      ) : (
        <div className="grid-right">
          <div className="results">
            <h3>Resultado</h3>
            <div className="numbers-of">
              <h3>{salesInfo.referrals}</h3>
              <span>Vendedores</span>
            </div>
            <div className="numbers-of">
              <h3>{salesInfo.sales}</h3>
              <span>Vendas</span>
            </div>
          </div>
          <DatePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
            heightLarge={false}
          />
        </div>
      )}
    </>
  );
};

export default Home;
