/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';

import SkeletonLoader from '../../../components/SkeletonLoader';
import { useLoadingBar } from '../../../hooks/loadingBar';

import {
  GetSalesRank as IGetSalesRank,
  useGetSalesRank,
} from '../../../services/api';

import { Table } from './styles';
import Avatar from '../../../components/Avatar';

interface ITableProps {
  weekOn: boolean;
}

const TableRanking: React.FC<ITableProps> = ({ weekOn }: ITableProps) => {
  const getSalesRank = useGetSalesRank();
  const { isBarLoading } = useLoadingBar();
  const [data, setData] = useState<IGetSalesRank['Response'] | undefined>();
  useEffect(() => {
    (async () => {
      try {
        const res = await getSalesRank();
        setData(res);
      } catch (error) {}
    })();
  }, []);
  return (
    <Table>
      <thead>
        <tr>
          <th className="center">#</th>
          <th className="left"> </th>
          <th className="left">Nome</th>
          <th className="center">Cidade</th>
          <th className="center">Nível</th>
          <th className="center">Vendas</th>
        </tr>
      </thead>
      <tbody>
        {isBarLoading || data === undefined ? (
          <>
            <tr style={{ display: 'flex', flexDirection: 'column' }}>
              <SkeletonLoader
                className="rank"
                style={{
                  minWidth: '50rem',
                  height: '5rem',
                  backgroundColor: 'white',
                }}
              />
              <SkeletonLoader
                className="rank"
                style={{
                  minWidth: '50rem',
                  height: '5rem',
                  backgroundColor: 'white',
                }}
              />
              <SkeletonLoader
                className="rank"
                style={{
                  minWidth: '50rem',
                  height: '5rem',
                  backgroundColor: 'white',
                }}
              />
              <SkeletonLoader
                className="rank"
                style={{
                  minWidth: '50rem',
                  height: '5rem',
                  backgroundColor: 'white',
                }}
              />
              <SkeletonLoader
                className="rank"
                style={{
                  minWidth: '50rem',
                  height: '5rem',
                  backgroundColor: 'white',
                }}
              />
            </tr>
          </>
        ) : (
          <>
            {weekOn ? (
              <>
                {data.current_week[0] ? (
                  data.current_week.map(info => (
                    <>
                      <tr>
                        <td className="center">{info.rank}</td>
                        <td className="left">
                          <Avatar
                            avatar_url={info.avatar}
                            widthAndHeightPicture="2.8"
                            marginTop="0"
                          />
                        </td>
                        <td className="left">{info.name}</td>
                        <td className="center font-light">
                          {info.city || 'Não Informado'}
                        </td>
                        <td className="level center" id={info.profile_class}>
                          {info.profile_class}
                        </td>
                        <td className="center">{info.sales}</td>
                      </tr>
                    </>
                  ))
                ) : (
                  <span>
                    Não encontramos nada aqui, por favor procure mais tarde
                  </span>
                )}
              </>
            ) : (
              <>
                {data.last_week[0] ? (
                  data.last_week.map(info => (
                    <>
                      <tr>
                        <td className="center">{info.rank}</td>
                        <td className="left">
                          <Avatar
                            avatar_url={info?.avatar}
                            widthAndHeightPicture="2.8"
                            marginTop="0"
                          />
                        </td>
                        <td className="left">{info.name}</td>
                        <td className="center">
                          {info.city || 'Não Informado'}
                        </td>
                        <td className="level center" id={info.profile_class}>
                          {info.profile_class}
                        </td>
                        <td className="center">{info.sales}</td>
                      </tr>
                    </>
                  ))
                ) : (
                  <span>
                    Não encontramos nada aqui, por favor procure mais tarde
                  </span>
                )}
              </>
            )}
          </>
        )}
      </tbody>
    </Table>
  );
};

export default TableRanking;
