import styled from 'styled-components';

interface IDatePickerContainerProps {
  heightLarge: boolean;
}

export const DatePickerContainer = styled.div<IDatePickerContainerProps>`
  .react-daterange-picker__wrapper {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 1rem;
    color: var(--black-background);
    font-size: 1.1rem;

    display: flex;

    min-width: 132px !important;
    max-width: 140px !important;
    min-height: ${props => (props.heightLarge ? '35px' : '24px')};
    max-height: ${props => (props.heightLarge ? '35px' : '24px')};
    min-width: 100%;
    padding: 0.1rem;
    margin: 0.4rem;
    @media (max-width: 600px) {
      min-width: 140px !important;
      max-width: 140px !important;
      min-height: 3.4rem !important;
      max-height: 3.4rem !important;
    }
    @media (max-width: 370px) {
      min-width: 132px !important;
      max-width: 132px !important;
    }
    input,
    button {
      border: none;
      outline: none;
    }
  }
  .react-daterange-picker__inputGroup {
    max-width: 4.3rem;
    min-width: 4.3rem;
    border: none;
  }
  .react-daterange-picker__inputGroup__leadingZero {
    margin: 0;
    padding: 0 3px;

    font-family: sans-serif;
    font-weight: 600;
  }
  .react-daterange-picker__inputGroup__day,
  .react-daterange-picker__inputGroup__month {
    text-align: center;
    font-family: sans-serif;
    font-weight: 600;

    max-width: 1.4rem !important;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    padding: 2px;
    padding: ${props => (props.heightLarge ? '2px 2px 3px' : '1px 2px 3px')};
  }
  .react-daterange-picker__inputGroup__year {
    display: none;
  }
  .react-daterange-picker__range-divider {
    font-family: sans-serif;
    font-weight: 600;

    max-height: 100%;
    min-height: 100%;

    padding: ${props =>
      props.heightLarge ? '12px 6px 4px 0' : '6px 6px 4px 0'};
    @media (max-width: 600px) {
      padding: 11px 3px 4px 0;
    }
  }
  .react-daterange-picker__clear-button {
    display: none;
  }
  .react-daterange-picker__inputGroup__input {
    min-width: 0.5rem;
    max-width: 1rem;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input[type='number']::-moz-inner-spin-button,
  input[type='number']::-moz-outer-spin-button {
    -moz-appearance: none;
  }
  input[type='number']::-moz-inner-spin-button,
  input[type='number']::-moz-outer-spin-button {
    -moz-appearance: none;
  }
  input[type='number']::-ms-inner-spin-button,
  input[type='number']::-ms-outer-spin-button {
    -ms-appearance: none;
  }
  .react-daterange-picker span div {
    top: ${props =>
      props.heightLarge ? '3.4rem !important' : '2.6rem !important'};
    left: 0 !important;
    @media (max-width: 600px) {
      top: 3.1rem !important;
    }
  }
  .react-daterange-picker__calendar {
    width: 16.6rem;
  }

  .react-calendar__navigation {
    margin: 0;
  }
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__navigation button {
    padding: 0;
    margin: 0;
    background-color: var(--primary-color) !important;
    border-radius: 1rem;
    &:hover {
      background-color: rgba(20, 27, 35, 0.5) !important;
    }
  }
  .react-calendar__navigation__arrow button {
    padding: 0;
    margin: 0;
    background-color: var(--primary-color);
  }
  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    padding: 0;
    margin: 0;
    background-color: var(--primary-color) !important;
    border-radius: 1rem;
    &:hover {
      background-color: rgba(20, 27, 35, 0.5) !important;
    }
  }
  .react-calendar {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 1rem 1rem;

    font-size: 1rem;
    font-family: Object Sans, sans-serif !important;
  }
  .react-calendar__navigation__label {
    font-size: 1rem;
    font-weight: 800;
    &:hover {
      background-color: rgba(20, 27, 35, 0.5) !important;
      border-radius: 1rem;
    }
    &:active {
      background-color: rgba(20, 27, 35, 0.5) !important;
      border-radius: 1rem;
    }
  }
  .react-calendar__month-view__days__day {
    background-color: var(--primary-color) !important;
    max-width: 1.9rem !important;
    min-width: 1.9rem !important;
    max-height: 1.9rem !important;
    min-height: 1.9rem !important;
    margin: 2px !important;
    padding: 5.5px 2.5px 3px 3px !important;
    font-family: Object Sans;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    &:hover {
      background-color: rgba(20, 27, 35, 0.5) !important;
      border-radius: 50%;
    }
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--black-background);
  }
  .react-calendar__month-view__days__day--weekend {
    color: var(--black-background);
  }
  .react-calendar__tile--active {
    background-color: var(--black-background) !important;
    border-radius: 100%;
    color: #fff;
  }
  .react-calendar__tile {
    padding: 1rem;
    margin: 0;
  }
  .react-calendar__year-view__months {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    button {
      border-radius: 1rem;
      font-size: 10px;
      font-weight: 600;

      width: 100%;
      margin: 0.1rem 1rem !important;
      padding: 0.8rem 0.3rem !important;
      &:hover {
        background-color: var(--primary-color) !important;
        background-color: rgba(20, 27, 35, 0.5) !important;
      }
    }
  }
  .react-calendar__tile--hasActive {
    background-color: var(--black-background) !important;
    color: #fff;
    &:hover {
      background-color: rgba(20, 27, 35, 0.5) !important;
    }
  }
  .react-calendar__decade-view__years {
    button {
      background-color: var(--primary-color) !important;
      &:hover {
        background-color: rgba(20, 27, 35, 0.5) !important;
        border-radius: 1rem;
      }
    }
    .react-calendar__tile--hasActive {
      background-color: var(--black-background) !important;
      border-radius: 1rem;
      color: #fff;
      &:hover {
        background-color: rgba(20, 27, 35, 0.5) !important;
      }
    }
  }
`;
