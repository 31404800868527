import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

interface BackgroundFixed {
  isBackgroundFixed: boolean;
  setIsBackgroundFixed: React.Dispatch<React.SetStateAction<boolean>>;
}

const BackgroundFixedContext = React.createContext<BackgroundFixed>({
  isBackgroundFixed: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsBackgroundFixed: () => {},
});

const BackgroundFixedProvider: React.FC = ({ children }) => {
  const [isBackgroundFixed, setIsBackgroundFixed] =
    React.useState<boolean>(false);

  const location = useLocation();
  const { pathname } = location;

  React.useEffect(() => {
    setIsBackgroundFixed(false);
  }, [pathname]);

  return (
    <BackgroundFixedContext.Provider
      value={{
        isBackgroundFixed,
        setIsBackgroundFixed,
      }}
    >
      {children}
    </BackgroundFixedContext.Provider>
  );
};

function useIsBackgroundFixed(): BackgroundFixed {
  const context = useContext(BackgroundFixedContext);

  if (!context) {
    throw new Error(
      'useIsBackgroundFixed must be used within a BackgroundFixedProvider',
    );
  }

  return context;
}

export { BackgroundFixedProvider, useIsBackgroundFixed };
