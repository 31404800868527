/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import * as yup from 'yup';

import { formatISO, parse, intervalToDuration } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';

import Input from '../../../components/Input';
import cartao from '../../../Assets/icons/cartao.svg';

import { useLoadingBar } from '../../../hooks/loadingBar';
import { useToast } from '../../../hooks/toast';
import {
  usePaymentHelperService,
  ICreditCard,
} from '../../../hooks/paymentService';
import { useSaleCheckout } from '../../../hooks/saleCheckout';

import { Form } from './styles';

import {
  usePutCreditCardCheckout,
  useGetCheckoutSale,
} from '../../../services/api';
import { IStudent } from '../../../models/Student';
import { ISale, SalesStatusEnum } from '../../../models/Sale';

import { getCurrencyMask } from '../../../utils/currencyFormatter';
import {
  phoneNumberMask,
  documentNumberMask,
  postalCodeMask,
} from '../../../utils/masks';
import { useFullscreenLoading } from '../../../hooks/fullscreenLoading';

interface ICardData {
  name: string;
  phone: string;
  email: string;
  emailConfirmation: string;
  cpf: string;
  cardBrand: 'visa' | 'mastercard' | 'elo';
  cardNumber: string;
  holder: string;
  month: string;
  year: string;
  cvv: string;
  installments: number;
  //
  birthDate: string;
  billingAddress: string;
  addressNumber: string;
  //
  zipCode: string;
  neighborhood: string;
  city: string;
  state: string;
}

const emptyCardData: ICardData = {
  name: '',
  phone: '',
  email: '',
  emailConfirmation: '',
  // 5589386622469865
  cpf: '',
  cardBrand: 'mastercard',
  cardNumber: '',
  holder: '',
  month: '',
  year: '',
  cvv: '',
  installments: 1,
  birthDate: '',
  billingAddress: '',
  addressNumber: '',
  zipCode: '',
  neighborhood: '',
  city: '',
  state: '',
};

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const Card: React.FC = () => {
  const history = useHistory();

  const { showFullscreenMessage, eraseFullscreenMessage } =
    useFullscreenLoading();
  const { saleCheckout, setSaleCheckout, saleHash } = useSaleCheckout();
  const { startBarLoading, completeBarLoading } = useLoadingBar();
  const { addToast } = useToast();
  const { getCreditCardPaymentToken } = usePaymentHelperService();

  const putCreditCardCheckout = usePutCreditCardCheckout();

  const [checkbox, setCheckbox] = useState(true);
  const [dummyData, setDummyData] = useState<ICardData>({
    ...emptyCardData,
    name: saleCheckout?.student.name || '',
  });
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);

  const updateDataPropertyFromInput =
    (cleaner: (s: string) => string = a => a) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value } = e.target;
      const propName = e.target.name as keyof ICardData;
      setDummyData(currentDummyData => ({
        ...currentDummyData,
        [propName]: cleaner(value),
      }));
    };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
      phone: yup
        .string()
        .typeError('Telefone inválido')
        .required('Telefone é obrigatório')
        .matches(
          /^\(\d{2}\) \d{4,5}-\d{4}$/,
          'Telefone deve seguir a seguinte regra "(XX) XXXXX-XXXX',
        ),
      email: yup
        .string()
        .email('Email inválido')
        .required('Email é obrigatório'),
      emailConfirmation: yup
        .string()
        .oneOf([yup.ref('email')], 'Os emails não são iguais')
        .required('Email de confirmação é obrigatório'),
      cpf: yup
        .string()
        .typeError('CPF inválido')
        .required('CPF é obrigatório')
        // .matches(
        //     /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
        //     'CPF deve seguir a seguinte regra "XXX.XXX.XXX-XX"',
        // ),
        .test('valida-cpf', 'CPF inválido', value =>
          cpfValidator.isValid(value || ''),
        ),
      cardNumber: yup.string().required('O número do cartão é obrigatório'),
      holder: yup
        .string()
        .required('O nome do titular é obrigatório')
        .min(5, 'Nome do titular deve ter no mínimo 5 caracteres'),
      month: yup
        .number()
        .required('Mês é obrigatório')
        .lessThan(13, 'Mês inválido')
        .moreThan(0, 'Mês inválido'),
      year: yup
        .number()
        .required('Ano é obrigatório')
        .moreThan(new Date().getFullYear() - 1, 'Ano inválido'),
      cvv: yup
        .string()
        .required('O código de segurança é obrigatório')
        .matches(/^[0-9]{3,4}$/, 'Código de segurança inválido'),
      birthDate: yup
        .string()
        .required('Data de nascimento é obrigatória')
        .test(
          'birthDate',
          'Data de nascimento inválida, estudante deve ter no mínimo 18 anos',
          () =>
            (intervalToDuration({
              start: parse(dummyData.birthDate, 'yyyy-MM-dd', new Date()),
              end: new Date(),
            }).years as number) >= 18,
        ),
      billingAddress: yup
        .string()
        .required('Endereço de cobrança é obrigatório'),
      addressNumber: yup
        .number()
        .typeError('Número de endereço inválido')
        .required('Número do endereço é obrigatório'),
      zipCode: yup
        .string()
        .required('CEP é obrigatório')
        .matches(/^\d{5}[-]{0,1}\d{3}$/, 'CEP inválido'),
      neighborhood: yup.string().required('Bairro é obrigatório'),
      city: yup.string().required('Cidade é obrigatório'),
      state: yup
        .string()
        .required('Estado é obrigatório')
        .oneOf(
          [
            'AC',
            'AL',
            'AP',
            'AM',
            'BA',
            'CE',
            'DF',
            'ES',
            'GO',
            'MA',
            'MT',
            'MS',
            'MG',
            'PA',
            'PB',
            'PR',
            'PE',
            'PI',
            'RJ',
            'RN',
            'RS',
            'RO',
            'RR',
            'SC',
            'SP',
            'SE',
            'TO',
          ],
          'Estado inválido! Deve seguir formato curto, por exemplo: PR',
        ),
    });

    try {
      await schema.validate(dummyData, {
        abortEarly: false,
      });

      startBarLoading();
      setIsPaymentLoading(true);
      showFullscreenMessage('Processando pagamento...');
      try {
        // TODO: algoritmo que avalia qual é a bandeira do cartão

        const creditCard: ICreditCard = {
          brand: dummyData.cardBrand, // bandeira do cartão
          number: dummyData.cardNumber, // número do cartão
          cvv: dummyData.cvv, // código de segurança
          expiration_month: dummyData.month, // mês de vencimento
          expiration_year: dummyData.year, // ano de vencimento
        };

        const paymentHelperResponse = await getCreditCardPaymentToken(
          creditCard,
        );
        // console.log('handleSubmit -> paymentHelperResponse', paymentHelperResponse);

        // fazer request para o back (PUT Checkout);
        const [birthYear, birthMonth, birthDay] = dummyData.birthDate
          .split('-')
          .map(n => Number(n));

        const payment = {
          card_mask: paymentHelperResponse.data.card_mask,
          payment_type: 'Crédito' as 'Crédito' | 'Boleto',
          credit_card: {
            installments: dummyData.installments,
            payment_token: paymentHelperResponse.data.payment_token,
            billing_address: {
              street: dummyData.billingAddress,
              number: Number(dummyData.addressNumber),
              neighborhood: dummyData.neighborhood,
              zipcode: dummyData.zipCode.replace('-', ''),
              city: dummyData.city,
              state: dummyData.state,
            },
          },
          customer: {
            name: dummyData.holder,
            email: dummyData.email,
            cpf: dummyData.cpf.replace(/\./g, '').replace('-', ''),
            birth: formatISO(new Date(birthYear, birthMonth - 1, birthDay)),
            phone_number: dummyData.phone
              .replace(' ', '')
              .replace('-', '')
              .replace('(', '')
              .replace(')', ''),
          },
        };
        console.log(
          dummyData.phone
            .replace(' ', '')
            .replace('-', '')
            .replace('(', '')
            .replace(')', ''),
        );
        const apiRequest = {
          // from context
          sale_id: saleCheckout?.id as string,
          course: {
            course_id: saleCheckout?.course.id as string,
          },
          student: {
            ...saleCheckout?.student,
            name: dummyData.name,
            phone_number: dummyData.phone,
            email: dummyData.email,
            email_confirmation: dummyData.emailConfirmation,
            document_number: dummyData.cpf,
            birth_date: dummyData.birthDate,
            address: `${dummyData.billingAddress}, ${dummyData.addressNumber} - ${dummyData.city}, ${dummyData.state}`,
            postal_code: dummyData.zipCode,
          } as IStudent,
          //
          payment,
        };

        try {
          // console.log('Payment Start!', { payment, apiRequest });

          // const paymentRes = await putCreditCardCheckout(apiRequest);
          await putCreditCardCheckout(apiRequest);

          // console.log({ paymentRes });

          // console.log('Payment Response', { paymentRes });

          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: 'Pagamento realizado',
          });

          setSaleCheckout(
            sc =>
              sc && {
                ...sc,
                student: {
                  ...sc.student,
                  name: dummyData.name,
                  email: dummyData.email,
                  address: `${dummyData.billingAddress}, ${dummyData.addressNumber}`,
                  document_number: dummyData.cpf,
                  birth_date: dummyData.birthDate,
                  postal_code: dummyData.zipCode,
                  phone_number: dummyData.phone,
                  country_of_birth: `${dummyData.city} - ${dummyData.state}`,
                },
              },
          );

          history.push('/checkout/termos', {
            cpf: dummyData.cpf,
            email: dummyData.email,
            email2: dummyData.emailConfirmation,
            name: dummyData.name,
            phone: dummyData.phone,
            saleId: saleCheckout?.id,
            courseId: saleCheckout?.course_id,
          });
        } catch (error) {
          console.log('Erro Pag Back', { error });
        }
      } catch (error) {
        // console.log('Deu boa ruim');

        console.error('creditCardError', { error });

        const errorDescription =
          error.code === 3500058
            ? 'Número do cartão de crédito inválido'
            : 'Falha na prepraração do pagamento, tente mais tarde';

        addToast({
          type: 'error',
          title: 'Erro no pagamento',
          description: errorDescription,
        });
      } finally {
        completeBarLoading();
        setIsPaymentLoading(false);
        eraseFullscreenMessage();
      }
    } catch (error) {
      // console.log('Deu ruim');
      if (error instanceof yup.ValidationError) {
        // console.log('Deu ruim com toast');
        const firstErrorMessage = error.errors[0];
        addToast({
          type: 'error',
          title: 'Erro nos dados informados',
          description: firstErrorMessage,
        });
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* <button
        type="button"
        onClick={async () => {
          const creditCard: ICreditCard = {
            brand: 'visa',
            number: '4012001038443335',
            cvv: '123',
            expiration_month: '05',
            expiration_year: '2021',
          };

          console.log('01010001001');

          const paymentHelperResponse = await getCreditCardPaymentToken(creditCard);
          console.log('---<>--->', { paymentHelperResponse });
        }}
      >
        Teste
      </button> */}
      <div>
        <Input
          label="Nome completo"
          type="text"
          name="name"
          placeholder="Nome"
          value={dummyData.name}
          onChange={updateDataPropertyFromInput()}
          required
        />
        <Input
          label="Telefone"
          type="text"
          name="phone"
          placeholder="(XX) XXXXX-XXXX"
          value={dummyData.phone}
          onChange={updateDataPropertyFromInput(p => phoneNumberMask(p))}
          required
        />
        <Input
          label="E-mail"
          type="email"
          name="email"
          placeholder="email@email.com"
          value={dummyData.email}
          onChange={updateDataPropertyFromInput()}
          required
        />
        <Input
          label="Confirme seu e-mail"
          type="email"
          name="emailConfirmation"
          placeholder="email@email.com"
          value={dummyData.emailConfirmation}
          onChange={updateDataPropertyFromInput()}
          required
        />
        <Input
          label="CPF"
          type="text"
          name="cpf"
          placeholder="XXX.XXX.XXX-XX"
          value={dummyData.cpf}
          onChange={updateDataPropertyFromInput(d =>
            documentNumberMask(d, { allowCompany: false }),
          )}
          required
        />
        <Input
          label="Data de Nascimento"
          type="date"
          name="birthDate"
          value={dummyData.birthDate}
          onChange={updateDataPropertyFromInput()}
          required
        />
        <Input
          label="Endereço de Cobrança"
          type="text"
          name="billingAddress"
          placeholder="Av. XXX"
          value={dummyData.billingAddress}
          onChange={updateDataPropertyFromInput()}
          required
        />
        <Input
          label="Número do Endereço"
          type="text"
          name="addressNumber"
          placeholder="Número"
          value={dummyData.addressNumber}
          onChange={updateDataPropertyFromInput(n => n.replace(/\D/g, ''))}
          required
        />
      </div>
      <div>
        <label>Bandeira</label>
        <select
          defaultValue="mastercard"
          name="cardBrand"
          onChange={updateDataPropertyFromInput()}
        >
          <option value="mastercard">MasterCard</option>
          <option value="visa">Visa</option>
          <option value="elo">Elo</option>
        </select>

        <Input
          label="Número do Cartão"
          type="number"
          name="cardNumber"
          placeholder="Apenas números"
          value={dummyData.cardNumber}
          onChange={updateDataPropertyFromInput()}
          required
        />
        <Input
          label="Titular do cartão"
          type="text"
          name="holder"
          placeholder="Nome no Cartão"
          value={dummyData.holder}
          onChange={updateDataPropertyFromInput()}
          required
        />
        <div className="data-card">
          <div className="date">
            <label htmlFor="month-year">Data de validade</label>
            <div className="input-date">
              <input
                type="number"
                name="month"
                id="month-CC"
                placeholder="MM"
                value={dummyData.month}
                onChange={updateDataPropertyFromInput(y =>
                  ''.concat(y).replace(/^(\d{2})\d*$/, '$1'),
                )}
                required
              />
              <input
                type="number"
                name="year"
                id="year-CC"
                placeholder="AA"
                min={new Date().getFullYear() - 2000}
                maxLength={2}
                minLength={2}
                value={dummyData.year
                  .replace(/^(20)/, '')
                  .replace(/(\d{2})\d*$/, '$1')}
                onChange={updateDataPropertyFromInput(y =>
                  '20'.concat(y).replace(/^20(\d{2})\d*$/, '20$1'),
                )}
                required
              />
            </div>
          </div>
          <div className="security-code">
            <label htmlFor="security-code">CVV</label>
            <input
              type="text"
              name="cvv"
              id="security-code"
              placeholder="000"
              maxLength={4}
              minLength={3}
              value={dummyData.cvv}
              onChange={updateDataPropertyFromInput()}
              required
            />
          </div>
        </div>

        <label>Pagamento da Primeira Parcela</label>
        <select
          disabled
          name="installments"
          onChange={updateDataPropertyFromInput()}
        >
          <option value={1}>
            Parcela 1:
            {` ${getCurrencyMask(
              saleCheckout?.promotional_value_of_course || 0,
            )}`}
          </option>
        </select>

        <Input
          label="CEP"
          type="text"
          name="zipCode"
          placeholder="CEP"
          minLength={9}
          maxLength={9}
          value={dummyData.zipCode}
          onChange={updateDataPropertyFromInput(z => postalCodeMask(z))}
          required
        />

        <Input
          label="Bairro"
          type="text"
          name="neighborhood"
          placeholder="Bairro"
          value={dummyData.neighborhood}
          onChange={updateDataPropertyFromInput()}
          required
        />

        <Input
          label="Cidade"
          type="text"
          name="city"
          placeholder="Cidade"
          value={dummyData.city}
          onChange={updateDataPropertyFromInput()}
          required
        />

        <label>Estado</label>
        <select
          required
          name="state"
          onChange={updateDataPropertyFromInput(state => state.toUpperCase())}
          defaultValue="UNKNOWN"
        >
          <option value="UNKNOWN" disabled>
            Selecione
          </option>
          <option value="AC">Acre</option>
          <option value="AL">Alagoas</option>
          <option value="AP">Amapá</option>
          <option value="AM">Amazonas</option>
          <option value="BA">Bahia</option>
          <option value="CE">Ceará</option>
          <option value="DF">Distrito Federal</option>
          <option value="ES">Espírito Santo</option>
          <option value="GO">Goiás</option>
          <option value="MA">Maranhão</option>
          <option value="MT">Mato Grosso</option>
          <option value="MS">Mato Grosso do Sul</option>
          <option value="MG">Minas Gerais</option>
          <option value="PA">Pará</option>
          <option value="PB">Paraíba</option>
          <option value="PR">Paraná</option>
          <option value="PE">Pernambuco</option>
          <option value="PI">Piauí</option>
          <option value="RJ">Rio de Janeiro</option>
          <option value="RN">Rio Grande do Norte</option>
          <option value="RS">Rio Grande do Sul</option>
          <option value="RO">Rondônia</option>
          <option value="RR">Roraima</option>
          <option value="SC">Santa Catarina</option>
          <option value="SP">São Paulo</option>
          <option value="SE">Sergipe</option>
          <option value="TO">Tocantins</option>
        </select>
      </div>
      <div className="card">
        <div className="checked-container">
          <div className="checkbox">
            <input
              type="checkbox"
              name="termos"
              id="terms-checked"
              onChange={e => {
                if (e.target.checked) {
                  setCheckbox(false);
                } else {
                  setCheckbox(true);
                }
              }}
            />
            <label htmlFor="terms-checked">
              Declaro ser elegível a cursar graduação/pós conforme diretrizes da
              LDB/MEC (Nº 9.394,Cap IV - Art.nº44/I/III)
            </label>
          </div>
          <button type="submit" disabled={isPaymentLoading || checkbox}>
            Finalizar Pagamento
          </button>
        </div>
      </div>
    </Form>
  );
};

export default Card;
