import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 100vw;
  min-height: 100vh;
  img {
    margin-bottom: 500px;
    width: 176px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      display: flex;
      align-items: center;

      input {
        border: none;
        background: rgba(196, 196, 196, 0.2);
        border-radius: 15px;
        color: #fff;
        font-size: 14px;
        line-height: 14px;
        text-align: center;

        width: 330px;
        height: 54px;
        padding: 0 8px;
        transition: 200ms;
        &::placeholder {
          color: #fff;
          font-size: 15px;
          line-height: 15px;
          text-align: center;
        }
      }
      input:hover,
      input:focus {
        outline: none;
        box-shadow: 0 0 0 2px #296394;
        ::placeholder {
          opacity: 0.5;
        }
      }

      img {
        position: absolute;
        align-self: center;
        height: 26px;
        margin-top: -0.2rem;
        margin-left: 18rem;
        cursor: pointer;
      }
    }
    input {
      border: none;
      background: rgba(196, 196, 196, 0.2);
      border-radius: 15px;
      color: #fff;
      font-size: 14px;
      line-height: 14px;
      text-align: center;

      width: 330px;
      height: 54px;
      padding: 0 8px;
      margin-bottom: 8px;
      transition: 200ms;
      &::placeholder {
        color: #fff;
        font-size: 15px;
        line-height: 15px;
        text-align: center;
      }
    }
    input:hover,
    input:focus {
      outline: none;
      box-shadow: 0 0 0 2px #296394;
    }
    button {
      border: none;
      background: #296394;
      border-radius: 37px;
      font-size: 14px;

      width: 271px;
      height: 54px;
      margin-top: 2px;
    }
    @media (max-width: 350px) {
      input {
        width: 290px;
        height: 40px;
      }
      button {
        height: 40px;
      }
    }
  }
  #esquecer-senha {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    margin: 21px 0 64px 0;
  }
  #cadastro {
    background-color: #141b23;
    border: 1.5px solid #296394;
    border-radius: 15px;
    color: #fff;
    font-weight: 800;
    font-size: 19px;
    line-height: 20px;
    text-align: center;
    text-decoration: none;

    width: 330px;
    height: 54px;
    padding: 14px;
    transition: 200ms;
    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #296394;
    }
    @media (max-width: 350px) {
      font-size: 16px;

      width: 290px;
      height: 44px;
      padding: 12px;
    }
  }
`;
