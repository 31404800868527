import React, { useEffect, useState } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import logo from '../../Assets/logo.png';

import HelpAsker from '../../components/HelpAsker';
import ButtonWantHelp from '../../components/IWantHelp';

import { useSaleCheckout } from '../../hooks/saleCheckout';
import { SalesStatusEnum } from '../../models/Sale';
import { Background, Container, GridMain } from './styles';

export const CheckoutRoute: React.FC<RouteProps> = ({
  component,
  path,
  exact,
}: RouteProps) => {
  const { saleCheckout } = useSaleCheckout();
  const history = useHistory();

  const [helpToggle, setHelpToggle] = useState('helper-off');

  const helperHandler = () => {
    if (helpToggle === 'helper-off') {
      setHelpToggle('helper-on');
    } else {
      setHelpToggle('helper-off');
    }
  };

  return (
    <Background>
      <img width="176" src={logo} id="logo" alt="logo" />
      <Container>
        <GridMain>
          {!saleCheckout ? (
            <h2 style={{ margin: '3rem 0', textAlign: 'center' }}>
              Carregando...
            </h2>
          ) : (
            <p id="text-intro">
              Você está sendo atendido por &nbsp;
              <span>{saleCheckout?.user.name}</span>
            </p>
          )}
          <Route exact={exact} path={path} component={component} />
        </GridMain>
        {helpToggle === 'helper-on' && (
          <HelpAsker className={helpToggle} setHelpToggle={setHelpToggle} />
        )}

        <ButtonWantHelp helperHandler={helperHandler} helpToggle={helpToggle} />
      </Container>
    </Background>
  );
};
