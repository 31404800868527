import React from 'react';

import logo from '../../Assets/logo.png';

import { NotFoundContainer } from './styles';

const NotFound: React.FC = () => (
  <NotFoundContainer>
    <img src={logo} width="176" alt="Logo Fael" className="animeTop" />
    <h2>Página não encontrada.</h2>
  </NotFoundContainer>
);

export default NotFound;
