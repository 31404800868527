import styled from 'styled-components';

export const TrainingContainer = styled.div`
  padding: 0 0 1rem;
  max-width: 120rem;
  margin: 25rem auto 0;
  background-color: #fff;
  border-radius: 2rem;
  color: var(--black-background);

  @media (max-width: 600px) {
    margin: 15rem auto -8rem;
  }

  .content-container {
    position: relative;
    padding-top: 1rem;
    box-sizing: border-box;
    @media (min-width: 768px) {
      padding-top: 12rem;
    }
    ::before {
      content: '';
      width: 100%;
      background-color: #ffff;
      position: absolute;
      z-index: 0;
      top: 0px;
    }
  }

  .videos-container {
    max-width: 1200px;

    margin: auto;
    position: relative;
    @media (min-width: 768px) {
      padding: 0rem 8rem 2rem;
      margin-bottom: 2rem;
    }
  }

  .main-videos-container {
    width: 100%;
    padding: 0px 2rem;
    @media (min-width: 768px) {
      padding: 0 0 3rem;
      margin-top: -25rem;
    }
    @media (max-width: 600px) {
      padding: 0px 2rem 2rem;
      margin-top: -10rem;
    }
  }

  .main-video-banner {
    display: block;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    outline: none;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: var(--black-background);
    cursor: pointer;
    background-color: var(--primary-color);
    border-radius: 2rem;
  }

  .main-video-frame {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 30rem;
    background-image: url();
    background-size: cover;
    background-position: center center;
    /* background-color: var(--primary-color: #296394); */
    border-radius: 2rem;
    overflow: hidden;
    margin: 0px auto;
    object-fit: cover;
  }

  .main-video-caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    font-weight: 700;
    color: var(--black-background);
    img {
      width: 18px;
      height: 16px;
    }
  }

  .main-video-caption-txt {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 700;
    img {
      width: 18px;
      height: 16px;
    }
    span {
      padding-left: 1rem;
    }
  }

  .scroll-videos-container {
  }

  .title-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @media (min-width: 768px) {
      padding: 0;
    }
    @media (max-width: 600px) {
      padding: 0 2rem;
    }
  }

  h3 {
    color: var(--black-background);
    font-size: 1.8rem;
    font-weight: bold;
    position: relative;
    line-height: 3rem;
    margin: 1.4rem 0px;
  }

  h3::after {
    background-color: var(--primary-color);
    content: '';
    height: 0.2rem;
    left: 0px;
    position: absolute;
    top: 3.5rem;
    width: 2.4rem;
  }

  .container-video {
    max-width: 100vw;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    @media (min-width: 768px) {
      padding: 0px;
    }
  }

  .video-section {
    width: 100%;
    min-width: 0px;
    min-height: 0px;
  }

  .slick-slider {
    margin-left: -8px;
    margin-right: -8px;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list,
  .slick-slider {
    position: relative;
    display: block;
  }
  .slick-slider .slick-arrow.slick-disabled {
    opacity: 0;
    transition: all 0.4s ease 0s;
  }
  .slick-slider .slick-arrow.slick-prev {
    left: -2rem;
  }
  .slick-slider .slick-arrow.slick-next {
    right: -2rem;
  }
  .slick-slider .slick-arrow {
    -webkit-box-align: center;
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    display: none;
    height: 3.6rem;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 1.6rem);
    width: 3.6rem;
    z-index: 10;
    @media (min-width: 768px) {
      .slick-slider .slick-arrow {
        display: flex;
      }
    }
    @media (max-width: 600px) {
      width: 8.6rem;
    }
  }
  .slick-prev {
    left: 25px;
  }
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--primary-color);
    border: none;
    outline: none;
    background: 0 0;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
    @media (max-width: 600px) {
      margin: 0 2rem;
    }
  }
  .slick-slider .slick-list .slick-track {
    margin-left: 0px;
  }
  .slick-prev:before,
  .slick-next:before {
    opacity: 1;
    background-color: transparent;
    color: var(--primary-color);
    @media (max-width: 600px) {
      font-size: 35px;
      line-height: 2;
    }
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    :after,
    :before {
      display: table;
      content: '';
    }
  }
  .slick-track:after {
    clear: both;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  .slick-slide > div {
    margin-left: 8px;
    margin-right: 8px;
    overflow: hidden;
  }

  .video-section-row {
    display: flex;
    width: 100%;
    margin: 2.5rem 0px;
    cursor: pointer;
    border-radius: 2rem;
    //background: #fff;
    overflow-x: auto;
    @media (min-width: 768px) {
      width: 22.5rem;
      margin: 2.5rem 0px;
    }
    @media (min-width: 1024px) {
      width: 100%;
    }
  }

  /* width */
  .video-section-row::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  .video-section-row::-webkit-scrollbar-track {
    background: rgb(214, 255, 237);
    border-radius: 10px;
  }

  /* Handle */
  .video-section-row::-webkit-scrollbar-thumb {
    background: #89facf;
    border-radius: 10px;
    border: 3px solid rgb(214, 255, 237);
  }

  /* Handle on hover */
  .video-section-row::-webkit-scrollbar-thumb:hover {
    background: #89facf;
  }

  .video-card {
    display: grid;
    width: 26rem;
    &:hover {
      cursor: pointer;
    }
  }

  .video-card + .video-card {
    margin-left: 2rem;
  }

  .link {
    align-self: flex-end;
  }

  .scroll-video {
    display: block;
    border-radius: 2rem 2rem 0 0;
    overflow: hidden;
    position: relative;
  }

  img.scroll-video-frame {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 15rem;
    background-image: url('https://i.vimeocdn.com/video/1041881642_640x360.jpg?r=pad');
    background-position: center;
    background-size: cover;
    background-color: transparent;
    object-fit: cover;
  }

  .scroll-thumb .scroll-image {
    height: 12rem;
  }

  .scroll-video-name {
    // background-color: rgb(255, 255, 255);
    color: var(--primary-color);
    font-size: 1.4rem;
    font-weight: 700;
    padding: 1rem 1.3rem;
    background-color: #fff;
    border-radius: 0 0 2rem 2rem;
  }
  .scroll-video-name > p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    color: var(--black-background);
  }

  .link {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    border-radius: 2rem;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    width: 26rem;
    height: 18.5rem;
    padding: 1rem;
    margin-left: 2.5rem;
    margin-bottom: auto;
    color: #fff;
    text-decoration: none;
  }

  .link img {
    border-radius: 50%;
    border: 2px solid #fff;
    padding: 1rem;
    margin-bottom: 2rem;
  }
`;
