/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable indent */
import React, { useState, useRef } from 'react';
import * as yup from 'yup';
import { CSSProperties } from 'styled-components';
import { useToast } from '../../hooks/toast';

import { phoneNumberMask } from '../../utils/masks';

import Input from '../Input';

import { Container } from './styles';
import { useNewHelpAsker } from '../../services/api';

interface IHelpAskerProps {
  className: string;
  setHelpToggle: React.Dispatch<React.SetStateAction<string>>;
}

interface IHelpFormData {
  name: string;
  email: string;
  phone_number: string;
  help_description: string;
}

const HelpAsker: React.FC<IHelpAskerProps> = ({
  className,
  setHelpToggle,
}: IHelpAskerProps) => {
  const { addToast } = useToast();
  const newHelpAsker = useNewHelpAsker();

  const [dummyData, setDummyData] = useState<IHelpFormData>({
    name: '',
    email: '',
    phone_number: '',
    help_description: '',
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
      phone_number: yup
        .string()
        .required('O telefone é obrigatório')
        .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, 'Telefone inválido'),
      email: yup
        .string()
        .required('Email é obrigatório')
        .email('Email inválido'),
      help_description: yup.string().required('Escreva a sua dúvida'),
    });

    try {
      await schema.validate(dummyData, {
        abortEarly: false,
      });
      const wasQuestionSaved = await newHelpAsker(dummyData);
      if (wasQuestionSaved) {
        setDummyData({
          email: '',
          help_description: '',
          name: '',
          phone_number: '',
        });

        addToast({
          type: 'success',
          title: '',
          description: 'Sua pergunta foi enviada',
        });
      } else {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'Sua pergunta não foi registrada, tente novamente',
        });
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const firstErrorMessage = error.errors[0];
        addToast({
          type: 'error',
          title: 'Erro nos dados informados',
          description: firstErrorMessage,
        });
      }
    }
  };
  return (
    <Container className={className}>
      <form onSubmit={handleSubmit} className="background">
        <Input
          labelStyle={{ color: 'black' }}
          name="name"
          type="text"
          className="short-input"
          label="Nome"
          value={dummyData.name}
          onChange={e => setDummyData({ ...dummyData, name: e.target.value })}
        />
        <Input
          labelStyle={{ color: 'black' }}
          name="email"
          type="email"
          className="short-input"
          label="Email"
          value={dummyData.email}
          onChange={e => setDummyData({ ...dummyData, email: e.target.value })}
        />
        <Input
          labelStyle={{ color: 'black' }}
          name="phone"
          type="text"
          className="short-input"
          label="Telefone"
          value={dummyData.phone_number}
          onChange={e =>
            setDummyData({
              ...dummyData,
              phone_number: phoneNumberMask(e.target.value),
            })
          }
        />
        <>
          <label>Como podemos lhe ajudar?</label>
          <textarea
            className="long-input"
            value={dummyData.help_description}
            onChange={e =>
              setDummyData({ ...dummyData, help_description: e.target.value })
            }
          />
        </>
        <button className="submit-btn" type="submit">
          Enviar
        </button>
      </form>
      <button
        type="button"
        className="button-back"
        onClick={() => setHelpToggle('helper-off')}
      >
        voltar
      </button>
    </Container>
  );
};

export default HelpAsker;
