import styled from 'styled-components';

export const Background = styled.div`
    background-color: var(--black-background);
    color: #fff;

    max-width: 100vw;
    min-height: 100vh;
    padding: 1.6rem 0;
    position: relative;
    #logo {
        display: block;
        margin: 0 auto;
    }
    .helper-off {
      width: 1px;
      height: 1px;
      opacity: 0;
      position: absolute;
    }
    .helper-on {
      position: fixed;
      right: 1rem;
      bottom: 2.5rem;
      @media (max-width: 1200px) {
        right: 10rem;
        bottom: 1.6rem;
      }
      @media (max-width: 900px) {
        right: 5rem;
      }
    @media (max-width: 600px) {
      right: 3rem;
    }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: calc(100vw - 45rem);
  justify-content: center;
  max-width: 120rem;
  margin: 2.6rem auto;
  @media (max-width: 1260px) {
    grid-template-columns: calc(100vw - 40rem);
  }
  @media (max-width: 1200px) {
    grid-template-columns: calc(100vw - 20rem);
    margin: 2.6rem auto 5.6rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: calc(100vw - 10rem);
  }
  @media (max-width: 600px) {
    grid-template-columns: calc(100vw - 6rem);
  }
`;

export const GridMain = styled.div`
  background-color: var(--black-background);
  border: 2px solid var(--primary-color);
  border-radius: 1rem;

  max-width: 120rem;
  max-height: calc(100vh - 1rem);
  width: 100%;
  margin: 0 auto;
  @media (max-width: 740px) {
    max-height: calc(100vh + 26rem);
  }
  #text-intro {
    font-weight: 800;
    font-size: 1.3rem;
    line-height: 1.3rem;
    text-align: center;

    margin-top: 1.6rem;
    span {
      color: var(--primary-color);
    }
    @media (max-width: 420px) {
      font-size: 1.1rem;
      line-height: 1.1rem;
    }
  }
`;
