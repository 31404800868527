/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import { CSSProperties } from 'styled-components';

import { InputStyles } from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type: string;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean | undefined;
  min?: string | number | undefined;
  max?: string | number | undefined;
  minLength?: number | undefined;
  maxLength?: number | undefined;
  labelStyle?: CSSProperties | undefined;
  className?: string | undefined;
}

const Input: React.FC<IInputProps> = ({
  label,
  type,
  name,
  placeholder = undefined,
  value = undefined,
  disabled,
  labelStyle,
  className,
  onChange = () => ({}),
  // error,
  onBlur = () => ({}),
  required = false,
  min = undefined,
  max = undefined,
  minLength = undefined,
  maxLength = undefined,
}: IInputProps) => (
  <InputStyles>
    <label style={labelStyle} htmlFor={name}>
      {label}
    </label>
    <input
      id={name}
      className={className}
      name={name}
      type={type}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      min={min}
      max={max}
      minLength={minLength}
      maxLength={maxLength}
    />
    {/* {error && <p className={error}>{error}</p>} */}
  </InputStyles>
);

export default Input;
