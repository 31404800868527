import styled from 'styled-components';

export const TermosContainer = styled.div`
  background-color: #eee;
  #back {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 14px;
    color: var(--black-background);

    display: flex;
    justify-content: center;
    font-weight: 800;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    text-decoration: none;
    margin: 0 auto 10px;
    width: 50%;
    padding: 16px;
    &:hover,
    &:focus {
      outline: none;
      border: 1px solid var(--black-background);
      box-shadow: 0 0 0 2px var(--black-background);
    }
  }
  .green-container {
    background-color: var(--primary-color);
    min-height: 3rem;
  }
  .black-container {
    background-color: var(--black-background);
    height: 14rem;
    padding: 3rem 0;
    @media (max-width: 420px) {
      height: 10rem;
      padding: 2rem 0 2.5rem;
    }
    img {
      display: block;
      margin: 0 auto;
      @media (max-width: 420px) {
        max-width: 12rem;
      }
    }
  }
  .container {
    margin: 2rem auto 0;
    max-width: 110rem;
    @media (max-width: 1100px) {
      padding: 0 1rem;
    }
  }
  h2 {
    color: var(--black-background);
    font-weight: 700;
    font-size: 2.4rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  h3 {
    color: var(--black-background);
    font-weight: 400;
    font-size: 1.6rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  p {
    color: var(--black-background);
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
  footer {
    background-color: var(--black-background);
    color: #fff;
    font-size: 1.2rem;
    text-align: center;

    width: 100%;
    padding: 1.6rem;
  }
`;
