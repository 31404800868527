import styled from 'styled-components';
import iconSearch from '../../../Assets/icons/iconSearch.svg';

export const ProfileGenericContainer = styled.div`
  padding: 0rem 8rem 2rem;
  max-width: 1200px;
  margin: 0px auto;
  @media (max-width: 991px) {
    padding: 0;
  }
  .account {
    background-color: #fff;
    border-radius: 1.4rem;
    color: var(--black-background);
    min-height: 3.2rem;

    padding: 5.2rem 3rem;
    @media (max-width: 991px) {
      border-radius: 0;
    }
    @media (max-width: 410px) {
      h2 {
        text-align: center;
      }
    }
    @media (max-width: 350px) {
      h2 {
        font-size: 2rem;
      }
    }
  }
  #logout {
    background-color: var(--black-background);
    border: 1px solid var(--primary-color);
    border-radius: 14px;
    color: var(--primary-color);
    font-weight: 800;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    text-decoration: none;

    width: 100%;
    padding: 16px;
    margin-top: 32px;
    &:hover,
    &:focus {
      color: var(--primary-color);
      outline: none;
      border: 1px solid var(--primary-color);
      box-shadow: 0 0 0 2px var(--primary-color);
    }
  }
  #back {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 14px;
    color: var(--black-background);

    display: flex;
    justify-content: center;
    font-weight: 800;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    text-decoration: none;

    min-width: 100%;
    padding: 16px;
    &:hover,
    &:focus {
      outline: none;
      border: 1px solid var(--black-background);
      box-shadow: 0 0 0 2px var(--black-background);
    }
  }
`;

export const UserProfileContainer = styled.div`
  .grid-profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    gap: 10px;
    margin-bottom: 16px;
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
    .profile {
      background: var(--black-background);
      border: 1px solid #296394;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 14px;
      color: #fff;

      display: flex;
      justify-content: space-around;
      align-items: flex-start;

      padding: 30px 20px;
      @media (max-width: 600px) {
        width: 100%;
      }
      @media (max-width: 380px) {
        flex-direction: column;
        align-items: center;
      }
      img {
        width: 95px;
      }
      .profile-user {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        margin-top: 8px;
        .profile-name {
          font-weight: 800;
          font-size: 18px;
          line-height: 21px;
        }
        .profile-name-city {
          font-size: 14px;
          line-height: 14px;

          margin: 8px 0;
        }
        .profile-level {
          background: var(--black-background);
          border: 1px solid #fff;
          border-radius: 20px;

          padding: 4px 32px;
        }
      }
    }
    .access-information {
      @media (max-width: 600px) {
        width: 100%;
      }
      h4 {
        color: var(--black-background);

        font-weight: 800;
        font-size: 14px;
        line-height: 22px;

        margin-left: 12px;
      }
      .background-information {
        background-color: var(--black-background);
        border: 1px solid #296394;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 14px;

        display: flex;

        padding: 16px 8px;
        a {
          background: none;
          border: none;
          color: #fff;

          font-weight: 800;
        }
      }
      .input-information {
        color: #fff;
        display: grid;
        grid-template-columns: 4fr 3fr;
        align-items: end;

        width: 100%;
        .grid-password {
          display: flex;
          flex-direction: column;
        }
        .grid-button {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        .grid-input {
          display: flex;
          flex-direction: column;
          vertical-align: center;
          div {
            display: flex;
            flex-direction: row;
            align-items: center;

            input {
              border-radius: 12px;
              border: none;

              padding: 4px;
              margin: 4px 0;
              outline: none;
            }

            img {
              width: 20px;
              height: 20px;
              margin-left: -30px;
              cursor: pointer;
            }
          }

          .incorrect {
            border-radius: 12px;
            border: solid 1px;
            border-color: red;
            padding: 4px;
            margin: 3px 0;
            outline: none;
          }
        }
        label {
          font-size: 14px;
          line-height: 14px;
        }
        span {
          color: #fff;
          font-style: italic;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
        }
        button {
          background-color: transparent;
          border: none;
          color: #fff;
          font-weight: 800;
          font-size: 14px;
          line-height: 15px;

          display: flex;
          align-items: center;

          cursor: pointer;
          outline: none;
          img {
            margin-left: 8px;
          }
          &:hover {
            color: var(--primary-color);
          }
          &:disabled {
            background-color: transparent;
            border: none;
            color: #fff;
            font-weight: 800;
            font-size: 14px;
            line-height: 15px;

            display: flex;
            align-items: center;

            cursor: not-allowed;
            outline: none;
            img {
              margin-left: 8px;
            }
            &:hover {
              color: red;
            }
          }
        }
      }
    }
  }
  .grid-manage-data {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
    grid-template-areas:
      '. . .'
      'bottom bottom bottom';
    margin-top: 16px;
    .bottom {
      grid-area: bottom;
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 420px) {
      display: flex;
      flex-wrap: wrap;
    }
    @media (max-width: 360px) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      flex: 1;
      text-align: center;
      img {
        margin: 0 auto;
      }
    }
    a {
      background-color: var(--black-background);
      border: 1px solid var(--primary-color);
      border-radius: 10px;
      text-decoration: none;

      display: flex;
      flex-direction: column;

      max-height: 100px;
      padding: 16px;
      outline: none;
      p {
        color: var(--black-background);
      }
      img {
        max-width: 40px;
      }
      @media (max-width: 600px) {
        justify-content: flex-end;
        img {
          max-width: 40px;
        }
      }
      span {
        color: #fff;
        font-size: 14px;
        font-weight: 800;
        line-height: 15px;

        margin-top: 12px;
        @media (max-width: 600px) {
          font-size: 12px;
        }
      }
      &:hover,
      &:focus {
        color: var(--primary-color);
        outline: none;
        border: 1px solid var(--primary-color);
        box-shadow: 0 0 0 2.5px var(--primary-color);
        p {
          color: var(--primary-color);
        }
      }
    }
    #button-help {
      display: block;
      padding: 12px;
      @media (max-width: 420px) {
        flex: 1;
      }
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 600px) {
          img {
            display: none;
          }
        }
        @media (max-width: 420px) {
          img {
            display: block;
          }
        }
        @media (max-width: 360px) {
          display: block;
          text-align: center;
          img {
            display: none;
          }
        }
      }
    }
  }
`;

export const PersonalData = styled.div`
  h3 {
    margin-bottom: 16px;
  }
  .grid-form-data {
    margin-bottom: 35px;
  }
  form {
    background-color: var(--black-background);
    border-radius: 11px;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    gap: 16px;
    justify-content: space-between;
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .photo-profile {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      #cam {
        position: relative;
        top: -30px;
      }
      input[type='file'] {
        opacity: 0;
        cursor: pointer;
        width: 24px;
        height: 24px;
        font-size: 0;
        position: absolute;
      }
      input {
        position: absolute;
        top: 0px;
        opacity: 0.5;
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
      }
    }
    .grid-input {
      width: 100%;
      color: #fff;
      @media (max-width: 600px) {
        order: 2;
      }
      h4 {
        display: block;

        font-size: 12px;
        font-weight: 800;
        line-height: 12px;

        margin-bottom: 6px;
      }

      input {
        background: #fff;
        border: solid 1px #eee;
        border-radius: 10px;
        color: var(--black-background);
        font-size: 12px;

        display: block;

        width: 100%;
        height: 26px;
        padding: 4px;
        margin-bottom: 12px;
      }
    }
  }
  #edit {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    font-weight: 800;

    max-height: 32px;
    cursor: pointer;
    outline: none;
    @media (max-width: 600px) {
      width: 100%;
      min-height: 40px;
      max-height: 40px;
      padding: 0.8rem;

      order: 1;
    }
  }

  button:disabled {
    cursor: not-allowed;
  }
`;

export const BankData = styled.div`
  h3 {
    margin-bottom: 16px;
  }
  .grid-form-data {
    margin-bottom: 35px;
  }
  form {
    background-color: var(--black-background);
    border-radius: 11px;
    padding: 20px;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 16px;
    justify-content: space-between;
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
    .grid-input {
      width: 100%;
      color: #fff;
      h4 {
        display: block;

        font-size: 12px;
        font-weight: 800;
        line-height: 12px;

        margin-bottom: 6px;
      }
      input {
        background: #fff;
        border: solid 1px #eee;
        border-radius: 10px;
        color: var(--black-background);
        font-size: 12px;

        display: block;

        width: 100%;
        height: 26px;
        padding: 4px;
        margin-bottom: 12px;
      }
      input:disabled {
        background: gray;
        border: solid 1px #eee;
        border-radius: 10px;
        color: var(--black-background);
        font-size: 12px;

        display: block;

        width: 100%;
        height: 26px;
        padding: 4px;
        margin-bottom: 12px;
      }

      select {
        background: #fff;
        border: solid 1px #eee;
        border-radius: 10px;
        color: var(--black-background);
        font-size: 12px;

        display: block;

        width: 100%;
        height: 26px;
        padding: 4px;
        margin-bottom: 12px;
      }
    }
  }
  #edit {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    font-weight: 800;

    max-height: 32px;
    cursor: pointer;
    outline: none;
  }
`;

export const HelpCenter = styled.div`
  position: relative;
  .search-container {
    position: absolute;
    right: 3rem;
    @media (max-width: 600px) {
      position: relative;
      width: 100vw;
      form {
        margin: 0 auto 2rem;
      }
    }
  }
  input {
    background-color: rgba(196, 196, 196, 0.75);
    border: none;
    border-radius: 2rem;
    color: #646464;

    font-size: 1.4rem;
    line-height: 2.2rem;

    max-width: 16.8rem;
    max-height: 2.8rem;
    padding: 0.8rem;
    &::placeholder {
      background: url(${iconSearch}) no-repeat;
      background-repeat: no-repeat;
      background-position: right;
      font-weight: 800;
      font-size: 1.2rem;
      line-height: 2.2rem;

      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1.5px #646464;
    }
    @media (max-width: 600px) {
      max-width: 18rem;
      max-height: 3.4rem;
    }
  }
  h3 {
    color: var(--black-background);
    font-size: 2.4rem;
    font-weight: normal;
    line-height: 2.4rem;
    @media (max-width: 600px) {
      font-size: 2.2rem;
      line-height: 2.2rem;
      text-align: center;
      order: 2;
    }
  }
  .sales-attendance {
    border-bottom: 1px solid #aaa;
    width: 100%;
    max-height: 36rem;
    margin: 2rem 0 3rem;
    padding: 0 1rem 1rem;
    overflow: auto;
    @media (max-width: 600px) {
      max-height: 26rem;
    }
    ::-webkit-scrollbar-track {
      background-color: #c4c4c4;
      border-radius: 2rem;
    }
    ::-webkit-scrollbar {
      width: 0.7rem;
    }
    ::-webkit-scrollbar-thumb {
      background: #646464;
      border-radius: 2rem;
    }
  }
  .search-container {
    display: flex;
    justify-content: space-between;
  }
`;

export const DocumentsContainer = styled.div`
  #download-button {
    margin: 1rem;
    display: block;
    padding: 12px;
    @media (max-width: 420px) {
      flex: 1;
    }
    cursor: pointer;

    div {
      img {
        max-width: 30px;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      div {
        margin-top: -35px;
        margin-left: -20px;
        flex-direction: column;
        p {
          color: var(--black-background);
        }

        span {
          color: black;
          font-size: 14px;
          font-weight: 800;
          line-height: 15px;

          margin-top: 12px;
          margin-left: -30px;
          @media (max-width: 600px) {
            font-size: 12px;
          }
        }
      }
    }
    &:hover,
    &:focus {
      color: var(--primary-color);
      outline: none;
      border: 1px solid var(--primary-color);
      box-shadow: 0 0 0 2.5px var(--primary-color);
      p {
        color: var(--primary-color);
      }
    }
  }

  a {
    background-color: white;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    text-decoration: none;

    display: flex;
    flex-direction: column;

    max-height: 100px;
    padding: 16px;
    outline: none;
    .arrow {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
    }

    @media (max-width: 600px) {
      justify-content: flex-end;
      img {
        max-width: 40px;
      }
    }
    span {
      color: var(--black-background);
      font-size: 14px;
      font-weight: 800;
      line-height: 15px;

      margin-top: 12px;
      @media (max-width: 600px) {
        font-size: 12px;
      }
    }
    &:hover,
    &:focus {
      color: var(--primary-color);
      outline: none;
      border: 1px solid var(--primary-color);
      box-shadow: 0 0 0 2.5px var(--primary-color);
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    grid-template-areas: '. . ';
    margin: 1rem;
    gap: 16px;

    margin-top: 16px;
    .bottom {
      grid-area: bottom;
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 420px) {
      display: flex;
      flex-wrap: wrap;
    }
    @media (max-width: 360px) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      flex: 1;
      text-align: center;
    }
  }
`;
