import React from 'react';

import Intro from '../../../components/Intro/index';
import TableRanking from './TableRanking';
import Tab from '../../../components/Tab/index';

import trophy from '../../../Assets/icons/trophy.svg';

import { RankingContainer, Results } from './styles';

const Ranking: React.FC = () => {
  const [isSelect, setIsSelect] = React.useState(true);

  return (
    <RankingContainer>
      <Intro image={trophy} name="Ranking" text="Venda mais e ganhe mais" />
      <Results>
        <h1>Resultado Geral</h1>
        <Tab
          tab1="Semana Atual"
          tab2="Semana Anterior"
          isSelect={isSelect}
          setIsSelect={setIsSelect}
          checkout={false}
        />
        <TableRanking weekOn={isSelect} />
      </Results>
    </RankingContainer>
  );
};
export default Ranking;
