import React from 'react';

import Intro from '../../../components/Intro/index';
import Tab from '../../../components/Tab/index';

import Commissions from './Commissions';
import Payments from './Payments';

import administrativo from '../../../Assets/pages/administrativo.svg';

import { AdminContainer, Container } from './styles';

const Admin: React.FC = () => {
  const [isSelect, setIsSelect] = React.useState(true);

  return (
    <AdminContainer>
      <Intro
        image={administrativo}
        name="Financeiro"
        text="Acompanhe suas comissões"
      />
      <section className="adm">
        <Tab
          tab1="Comissões"
          tab2="Pagamentos"
          isSelect={isSelect}
          setIsSelect={setIsSelect}
          className="tab"
          checkout={false}
        />
        {isSelect ? (
          <p>
            Está página mostra suas comissões futuras. Para acessar seus
            próximos pagamentos clique em
            <b> Pagamento</b> no botão acima
          </p>
        ) : (
          <p>
            Está página mostra seus pagamentos. Para acessar suas comissões
            futuras clique em
            <b> Comissões</b> no botão acima
          </p>
        )}
        <Container>
          {isSelect ? (
            <Commissions isSelect={isSelect} />
          ) : (
            <Payments isSelect={!isSelect} />
          )}
        </Container>
      </section>
    </AdminContainer>
  );
};

export default Admin;
