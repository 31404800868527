import React, { useState } from 'react';

import talk from '../../Assets/icons/iconTalkHelp.svg';
import whatsapp from '../../Assets/icons/whatsapp.svg';

import { ButtonWantHelpContainer } from './styles';

interface IButtonWantHelpProps {
  helperHandler: () => void;
  helpToggle: string;
}

const ButtonWantHelp: React.FC<IButtonWantHelpProps> = ({
  helperHandler,
  helpToggle,
}) => {
  const [isButtonCallWhatsApp, setIsButtonCallWhatsApp] = useState(false);
  return (
    <ButtonWantHelpContainer isButtonCallWhatsApp={isButtonCallWhatsApp}>
      {helpToggle === 'helper-off' && (
        <button
          type="button"
          className="style-button want-help"
          onClick={() => setIsButtonCallWhatsApp(!isButtonCallWhatsApp)}
        >
          <img src={talk} alt="talk" />
          Quero Ajuda
        </button>
      )}
      {isButtonCallWhatsApp && (
        <div>
          <a
            href="https://wa.me/554136676657"
            className="style-button call-whatsapp"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img style={{ width: '4rem' }} alt="Whatsapp" src={whatsapp} />
            Fale com um consultor
          </a>
          <p>
            ou nos{' '}
            <button
              type="button"
              className="button-send"
              onClick={() => {
                setIsButtonCallWhatsApp(false);
                helperHandler();
              }}
            >
              envie sua dúvida
            </button>
          </p>
        </div>
      )}
    </ButtonWantHelpContainer>
  );
};

export default ButtonWantHelp;
