import React, { useState } from 'react';

import Intro from '../../../components/Intro';

import duvidas from '../../../Assets/icons/duvidas.svg';

import { useNewTechQuestion } from '../../../services/api';

import { TechQuestionsContainer, TechnicalQuestions } from './styles';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

const TechQuestions: React.FC = () => {
  const newTechQuestion = useNewTechQuestion();
  const { addToast } = useToast();
  const [question, setQuestion] = useState('');
  const { token, user } = useAuth();
  const handleNewQuestion = async () => {
    let wasQuestionSaved = { data: false };
    try {
      wasQuestionSaved = await newTechQuestion({ question, token });
      if (wasQuestionSaved) {
        setQuestion('');
        addToast({
          type: 'success',
          title: '',
          description: 'Sua pergunta foi enviada',
        });
      } else {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'Sua pergunta não foi registrada, tente novamente',
        });
      }
    } catch (error) {}
  };
  return (
    <TechQuestionsContainer>
      <Intro
        image={duvidas}
        name="Preciso de Ajuda"
        text="Dúvidas técnicas de vendas"
      />
      <TechnicalQuestions>
        <div className="background-container">
          <p>
            Envie aqui sua dúvida sobre técnicas de vendas e nosso time de
            atendimento entrará em contato para auxiliá-lo
          </p>
          <textarea
            value={question}
            onChange={e => setQuestion(e.target.value)}
          />
          <div className="button-container">
            <button
              type="button"
              className={question.length ? 'on' : 'off'}
              disabled={!question.length}
              onClick={handleNewQuestion}
            >
              Enviar
            </button>
          </div>
        </div>
      </TechnicalQuestions>
    </TechQuestionsContainer>
  );
};

export default TechQuestions;
