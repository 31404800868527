import styled from 'styled-components';
import { colors } from '../../styles/globalStyles';

export const NotFoundContainer = styled.div`
  background-color: #141b23;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 100vw;
  min-height: 100vh;

  h2 {
    font-weight: 800;
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    color: ${colors.primary};
    margin-top: 28px;
  }
`;
