import styled from 'styled-components';

export const InputStyles = styled.div`
  margin-bottom: 0.8rem;
  label {
    display: block;

    color: #fff;
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 1.2rem;

    margin-bottom: 0.6rem;
  }
  input {
    background: #fff;
    border: solid 1px #eee;
    border-radius: 1rem;
    font-size: 1.2rem;

    display: block;

    width: 100%;
    height: 2.6rem;
    padding: 0.4rem;
    margin-bottom: 1.2rem;
    transition: 0.2s;
    &::placeholder {
      font-size: 1.4rem;
    }
  }
  input:hover,
  input:focus {
    outline: none;
    border: 1px solid var(--primary-color);
    background-color: #fff;
    box-shadow: 0 0 0 1px var(--primary-color);
  }
`;
