import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import home from '../../Assets/icons/Home.svg';
import trophy from '../../Assets/icons/trophy.svg';
import dinheiro from '../../Assets/icons/dinheiro.svg';
import capelo from '../../Assets/icons/capelo.svg';
import userIcon from '../../Assets/icons/user.svg';
import vendas from '../../Assets/icons/vendas.svg';
import cumprimento from '../../Assets/icons/cumprimento.svg';
import duvidas from '../../Assets/icons/duvidas.svg';
import contrato from '../../Assets/icons/contract.svg';
import ranking from '../../Assets/pages/ranking.svg';

import Avatar from '../Avatar/index';

import { useAuth } from '../../hooks/auth';

import { Container, SidebarStyle } from './styles';
import getFirstName from '../../hooks/getFirstName';

const Sidebar: React.FC = () => {
  const { user } = useAuth();

  return (
    <Container>
      <SidebarStyle>
        <div className="user-profile">
          <Avatar
            avatar_url={user?.avatar_url}
            widthAndHeightPicture="15"
            marginTop="3"
          />
          <h3>
            Olá,
            {` ${getFirstName(user?.name) || 'João Desconhecido'}`}
          </h3>
          <p>Vamos realizar seus sonhos!</p>
          <span>{user?.profile_class || 'Sem classe'}</span>
        </div>
        <div className="container-links">
          <ul>
            <li>
              <NavLink to="/home" activeClassName="active">
                <img src={home} alt="Home" />
                <span>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/ranking" activeClassName="active">
                <img src={trophy} alt="Ranking" />
                <span>Ranking</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin" activeClassName="active">
                <img src={dinheiro} alt="Financeiro" />
                <span>Financeiro</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/treinamento" activeClassName="active">
                <img src={capelo} alt="Treinamento" />
                <span>Treinamento</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/team" activeClassName="active">
                <img src={vendas} alt="Equipe de vendas" />
                <span>Equipe de vendas</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/minhas-vendas" activeClassName="active">
                <img src={cumprimento} alt="Minhas vendas" />
                <span style={{ marginLeft: '-6px' }}>Minhas Vendas</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/profile" activeClassName="active">
                <img src={userIcon} alt="Conta do usuário" />
                <span>Minha Conta</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/level" activeClassName="active">
                <img src={ranking} alt="Meu nível" />
                <span>Meu nível</span>
              </NavLink>
            </li>
          </ul>
          <ul className="duvidas">
            <li>
              <NavLink to="/duvidas-tecnicas" activeClassName="active">
                <img src={duvidas} alt="Dúvidas Técnicas" />
                <span>Preciso de ajuda!</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/contrato" activeClassName="active">
                <img src={contrato} alt="Termo de adesão" />
                <span>Termo de adesão</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </SidebarStyle>
    </Container>
  );
};

export default Sidebar;
