import React, { useRef } from 'react';

import { AccordionContainer } from './styles';

interface IAccordionProps {
  title: string;
  content: string;
}

const Accordion: React.FC<IAccordionProps> = ({ title, content }) => {
  const [isActive, setIsActive] = React.useState('');
  const [height, setHeight] = React.useState('0px');

  const contentRef = useRef<HTMLDivElement>(null);
  function handleAccordion() {
    setIsActive(isActive === '' ? 'active' : '');
    setHeight(
      isActive === 'active' ? '0px' : `${contentRef.current?.scrollHeight}px`,
    );
  }
  return (
    <AccordionContainer>
      <button
        type="button"
        onClick={handleAccordion}
        className={`accordion ${isActive}`}
      >
        <p>{title}</p>
      </button>
      <div
        ref={contentRef}
        className="accordion-content"
        style={{ maxHeight: `${height}` }}
      >
        <p className="accordion-text">{content}</p>
      </div>
    </AccordionContainer>
  );
};

export default Accordion;
