import styled from 'styled-components';

interface IButtonWantHelpContainerProps {
  isButtonCallWhatsApp: boolean;
}

export const ButtonWantHelpContainer = styled.div<IButtonWantHelpContainerProps>`
  position: fixed;
  right: 2.5rem;
  bottom: 4rem;
  @media (max-width: 1200px) {
    position: absolute;
    right: 10rem;
    bottom: 1.6rem;
  }
  @media (max-width: 900px) {
    right: 5rem;
  }
  @media (max-width: 600px) {
    right: 3rem;
  }
  .style-button {
    background-color: var(--black-background);
    border: 1px solid var(--primary-color);
    border-radius: 1rem;
    color: #fff;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .want-help {
    display: ${({ isButtonCallWhatsApp }) =>
      isButtonCallWhatsApp ? 'none' : 'flex'};
    min-height: 4.2rem;
    max-height: 4.2rem;

    padding: 0.8rem 2rem 1rem;

    outline: none;
    img {
      margin-right: 8px;
    }
  }
  .call-whatsapp {
    font-family: Object Sans;
    line-height: 22px;
    text-decoration: none;
    text-align: center;

    max-width: 16.4rem;
    max-height: 10rem;
    padding: 2rem 1rem;

    outline: none;
  }
  a {
    font-family: Object Sans;
  }

  p {
    font-size: 12px;
    text-align: center;

    margin-top: 8px;
  }
  .button-send {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    outline: none;
  }
`;
