/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { Link } from 'react-router-dom';

import Intro from '../../../components/Intro';

import contract from '../../../Assets/icons/contract.svg';
import arrow from '../../../Assets/icons/arrow.svg';
import download from '../../../Assets/icons/download.svg';

import { ProfileGenericContainer, DocumentsContainer } from './styles';

const LegalDocuments: React.FC = () => (
  <ProfileGenericContainer>
    <Intro image={contract} name="Documentos Legais" text="" />
    <DocumentsContainer>
      <div className="grid">
        <Link to="/termos/privacy" target="_blank" id="button-help">
          <div>
            <span>Política de Privacidade</span>
            <p>Esse documento trata da política de privacidade</p>
            <div className="arrow">
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        </Link>
        <Link to="/termos/cookies" target="_blank" id="button-help">
          <div>
            <span>Política de Cookies</span>
            <p>Esse documento trata da política de cookies</p>
            <div className="arrow">
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        </Link>
        <Link to="/termos/conditions" target="_blank" id="button-help">
          <div>
            <span>Condições de Adesão ao Programa</span>
            <p>Esse documento trata das condições de adesão ao programa</p>
            <div className="arrow">
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        </Link>
        <Link to="/contrato" target="_blank" id="button-help">
          <div>
            <span>Termos de Uso</span>
            <p>Termos de Uso da aplicação e suas funções</p>
            <div className="arrow">
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        </Link>
      </div>
      <a
        onClick={() => {
          if (window !== undefined) {
            window.open(
              'https://wup-system.s3.us-east-2.amazonaws.com/Plano+de+Carreira+2021+V.+05Fev.pdf',
            );
          }
        }}
        id="download-button"
      >
        <div>
          <div>
            <span>Plano de carreira e business</span>
            <p>Clique para baixar o plano de carreira em PDF</p>
          </div>

          <img src={download} alt="download" />
        </div>
      </a>
      <Link to="/profile" id="back">
        Voltar
      </Link>
    </DocumentsContainer>
  </ProfileGenericContainer>
);

export default LegalDocuments;
