import { shade } from 'polished';
import styled from 'styled-components';

import sessionBackground from '../../Assets/sessionBackground.jpg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Licenses = styled.div`
  /* ///////////////// Logo ///////////////// */
  .intro {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin: 0rem auto 2.5rem;
    img {
      margin: 1.2rem auto;
    }
  }

  /* ///////////////// CARDS ///////////////// */
  .licenses-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  .license {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    border-radius: 2rem;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    p {
      align-self: center;
      margin-top: 1rem;
      color: white;
      font-size: 1.2rem;
      font-weight: 800;
      text-decoration: underline;
      margin-bottom: -1.5rem;
    }

    @media (min-width: 992px) {
      width: 350px;
    }

    @media (min-width: 320px) {
      width: 300px;
    }
  }

  .vip {
    background: #12262f;
    border-top: 15px solid #66ce2c;
    border-bottom: 5px solid #66ce2c;
    padding: 1.6rem 0;
    border-radius: 8px;
    min-height: 650px;
    height: 100%;
    margin-right: 10px;
    margin-left: 10px;

    cursor: pointer;

    @media (min-width: 1024px) {
      padding: 0;
    }

    @media (max-width: 420px) {
      padding: 1.6rem 0;
      border-radius: 8px;
    }
  }

  .sales,
  .manager,
  .partner {
    background: #12262f;
    box-sizing: border-box;
    border: 2px solid #296394;
    border-radius: 15px;
    height: 80rem;
    margin-top: 40px;
    min-width: 7rem;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 1598px) {
      box-sizing: border-box;
      border-radius: 15px;
      margin-top: 40px;
      margin-right: 10px;
      margin-left: 10px;
    }

    @media (max-width: 1280px) {
      box-sizing: border-box;
      border-radius: 15px;
      margin-top: 40px;
      margin-left: 10px;
    }

    @media (max-width: 420px) {
      padding: 1.6rem 0;
      border-radius: 8px;
      height: 82rem;
    }
  }

  .embreve {
    display: flex;
    background-color: #296394;
    color: white;
    justify-content: center;
    flex-flow: row wrap;
    position: relative;
    top: -3rem;
    width: 80%;
    border: 1px solid #296394;
    border-radius: 0.8rem;
    font-size: 1.2em;
    font-weight: bold;
    padding: 8px 30px;
    margin: 0 auto;
    z-index: 1;

    @media (min-width: 425px) {
      top: -2rem;
    }
  }

  .license-name {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 10rem;
    padding: 60px 30px;

    li {
      color: white;
      padding: 1rem;
    }

    @media (min-width: 1024px) {
      padding: 60px 30px 45px;
    }

    @media (min-width: 1440px) {
      padding: 60px 30px;
    }
  }

  .vip {
    .license-name {
      height: 160px;
    }
  }

  .sales .license-name,
  .manager .license-name,
  .partner .license-name {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    height: 14rem;
    padding: 0 30px 0;
    margin-top: -15px;
    margin-bottom: 30px;

    @media (min-width: 1280px) {
      padding: 20px 30px 0;
      margin-top: 0;
      margin-bottom: 30px;
    }

    @media (min-width: 1440px) {
      padding: 20px 30px 0;
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  .tooltip {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    overflow: hidden;
    p {
      font-size: 1rem;
    }
    @media (min-width: 320px) {
      position: absolute;
      top: 6rem;
      left: 27rem;
    }
    @media (min-width: 375px) {
      position: absolute;
      top: 6rem;
      left: 30rem;
    }
    @media (min-width: 425px) {
      position: absolute;
      top: 6rem;
      left: 32rem;
    }
    @media (min-width: 520px) {
      position: absolute;
      top: 6rem;
      left: 36rem;
    }
    @media (min-width: 620px) {
      position: absolute;
      top: 6rem;
      left: 42rem;
    }
    @media (min-width: 720px) {
      position: absolute;
      top: 6rem;
      left: 46rem;
    }
    @media (min-width: 768px) {
      position: absolute;
      top: 6rem;
      left: 49rem;
    }
    @media (min-width: 830px) {
      position: absolute;
      top: 6rem;
      left: 52rem;
    }
    @media (min-width: 925px) {
      position: absolute;
      top: 6rem;
      left: 56rem;
    }
    @media (min-width: 991px) {
      position: absolute;
      top: 6rem;
      left: 61rem;
    }
    @media (min-width: 1024px) {
      position: absolute;
      top: 20rem;
      left: 96rem;
    }
    @media (min-width: 1280px) {
      position: absolute;
      top: 20rem;
      left: 120rem;
    }
    @media (min-width: 1440px) {
      position: absolute;
      top: 23rem;
      left: 138rem;
    }
    @media (min-width: 2560px) {
      position: absolute;
      top: 18rem;
      left: 138rem;
    }
  }

  .license-name ul li {
    padding-bottom: 21px;
    text-align: center;
    list-style-type: none;
    color: white;
  }

  @media (max-width: 1024px) {
    .sales .license-name ul li,
    .manager .license-name ul li,
    .partner .license-name ul li {
      padding-bottom: 0px;
      text-align: center;
      list-style-type: none;
      padding-top: 21px;
    }
  }

  .vip .license-name ul li span {
    font-size: 2.5rem;
  }

  /* .vip .license-name ul li h2 {
  color: #66CE2C;
  font-size: 4.25rem;
  font-weight: 900;
} */

  @media (max-width: 1024px) {
    .vip .license-name ul li h2 {
      font-size: 3.25rem;
    }
  }

  .sales .license-name ul li span,
  .manager .license-name ul li span,
  .partner .license-name ul li span {
    color: white;
    font-size: 2rem;
  }

  .sales .license-name ul li h2 {
    color: #296394;
    font-size: 2.25rem;
    font-weight: 900;
  }

  .manager .license-name ul li h2 {
    color: var(--black-background);
    font-size: 2.25rem;
    font-weight: 900;
  }

  .partner .license-name ul li h2 {
    color: var(--black-background);
    font-size: 2.25rem;
    font-weight: 900;
  }

  .vip .price {
    position: relative;
    background: #66ce2c;
    height: 70px;
    padding: 22px 0;
    text-align: center;
  }

  .sales .price {
    color: rgba(255, 255, 255);
    position: relative;
    background: #29639499;
    height: 70px;
    padding: 22px 0;
    text-align: center;
  }

  .manager .price {
    color: white;
    position: relative;
    background: #29639499;
    height: 70px;
    padding: 22px 0;
    text-align: center;
  }

  .partner .price {
    color: white;
    position: relative;
    background: #29639499;
    height: 70px;
    padding: 22px 0;
    text-align: center;
  }

  h3 {
    color: #fff;
    font-size: 3rem;
    font-weight: 900;
    font-style: normal;
    line-height: 28px;
    letter-spacing: -2px;
  }

  .bonus,
  .course {
    color: var(--black-background);
    font-size: 1.3rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding: 30px 15px;
    ul {
      display: grid;
      grid-template-columns: 0.1fr 3fr;
      grid-auto-rows: 3.5rem;
      align-items: center;
      li {
        color: white;
      }
    }

    @media (min-width: 1024px) {
      padding: 20px 15px;
    }

    @media (min-width: 1440px) {
      padding: 30px 15px;
    }
  }

  .bonus ul li,
  .course ul li {
    padding-left: 15px;
    padding-right: 15px;
  }

  .bonus small {
    font-size: 0.8rem;
  }

  .border {
    width: 100%;
    height: 0.75rem;
    background: #dbff0099;
  }

  .sales .border {
    width: 100%;
    height: 0.75rem;
    background: #29639499;
  }

  .manager .border {
    width: 100%;
    height: 0.75rem;
    background: #29639499;
  }

  .partner .border {
    width: 100%;
    height: 0.75rem;
    background: #29639499;
  }

  h4 {
    color: #66ce2c;
    font-size: 1.6rem;
    font-weight: 900;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
    padding: 40px 50px 25px;

    @media (min-width: 1024px) {
      padding: 10px 20px 10px;
    }

    @media (min-width: 1440px) {
      padding: 40px 20px;
    }
  }

  button {
    background-color: #66ce2c;
    border: none;
    border-radius: 8px;
    color: var(--black-background);
    font-size: 1.6rem;
    font-weight: 700;
    display: block;
    text-align: center;
    padding: 22px 42px;
    margin: 17px auto 32px;
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    button {
      padding: 12px 16px;
    }
  }

  /* ///////////////// CAROUSEL ///////////////// */

  button.BrainhubCarousel__dot {
    background-color: transparent;
  }

  .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
    background: #fff;
  }

  .BrainhubCarousel__dots .BrainhubCarousel__dot {
    padding: 15px;
  }

  .carousel-dots {
    display: flex;
    justify-content: center;
    margin: 20px 0 0;
  }

  /* ///////////////// CTA ///////////////// */

  .biggerBtn {
    background-color: #08d385;
    border: none;
    border-radius: 8px;
    color: var(--black-background);
    font-size: 1.6rem;
    font-weight: 700;
    display: block;
    text-align: center;
    padding: 22px 42px;
    margin: 32px auto;
    width: 90vw;
    cursor: pointer;
    @media (max-width: 420px) {
      display: none;
    }
  }

  .line {
    background: #fff;
    max-width: 100vw;
    height: 1px;
    margin: 25px auto;
  }
`;

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  width: 100%;
  align-items: center;
  max-width: 700px;
  background: #141b23;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;
    @media (max-height: 800px) {
      margin: 30px 0;
    }
  }
  h1 {
    margin-bottom: 24px;
    color: #fbfbfb;
    font-family: Object Sans;
  }

  button {
    background: #296394;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #fbfbfb;
    width: 100%;
    font-weight: 500;
    margin-top: 16px;

    &:hover {
      background: #296394;
      cursor: pointer;
    }
  }
  a {
    color: #fbfbfb;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;
    font-size: large;

    &:hover {
      color: ${shade(0.2, '#fbfbfb')};
      cursor: pointer;
    }
  }
  > a {
    color: #fbfbfb;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }
    &:hover {
      color: #ff9000;
      cursor: pointer;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${sessionBackground}) no-repeat right;
  background-size: contain;
  background-color: #fbfbfb;
  @media (max-width: 1110px) {
    background: #141b23 no-repeat right;
  }
`;
