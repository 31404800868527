import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import Sidebar from '../../components/Sidebar';
import MenuResponsive from '../../components/MenuResponsive';

import { useIsBackgroundFixed } from '../../hooks/isBackgroundFixed';

import { Background, Container, GridMain, Main } from './styles';

export const SalesmanRoute: React.FC<RouteProps> = ({
  component,
  path,
  exact,
}: RouteProps) => {
  const { isBackgroundFixed } = useIsBackgroundFixed();

  return (
    <Background isBackgroundFixed={isBackgroundFixed}>
      <MenuResponsive />
      <Container>
        <Sidebar />
        <GridMain>
          <Main>
            <Route exact={exact} path={path} component={component} />
          </Main>
        </GridMain>
      </Container>
    </Background>
  );
};
