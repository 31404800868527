import React, { useContext, useState } from 'react';
import { useTransition, animated } from 'react-spring';
import styled from 'styled-components';
import { rgba } from 'polished';
import { colors } from '../styles/globalStyles';

const FullscreenModal = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${rgba(colors.secondary, 0.7)};
  z-index: 100;

  display: flex;
  place-content: center;

  @keyframes scaling {
    0% {
      transform: scale(0.9);
      color: ${colors.white};
    }
    50% {
      transform: scale(1);
      color: ${colors.primary};
    }
    100% {
      transform: scale(0.9);
      color: ${colors.white};
    }
  }

  p {
    margin: auto;
    color: ${colors.white};
    font-weight: 800;
    font-size: 3.5rem;
    animation: scaling 4s infinite cubic-bezier(0.65, 0, 0.35, 1);
    max-width: 40rem;
    min-width: 60%;
    text-align: center;
  }
`;

interface FullscreenLoadingData {
  showFullscreenMessage: (message: string) => void;
  eraseFullscreenMessage: () => void;
}

const FullscreenLoadingContext = React.createContext<FullscreenLoadingData>(
  {} as FullscreenLoadingData,
);

const FullscreenLoadingProvider: React.FC = ({ children }) => {
  const [message, setMessage] = useState('');

  const transitions = useTransition(message.length > 0, null, {
    from: {
      backgroundColor: rgba(colors.secondary, 0),
      transform: 'translate(-50%, -50%) scale(0)',
    },
    enter: {
      backgroundColor: rgba(colors.secondary, 0.7),
      transform: 'translate(-50%, -50%) scale(1)',
    },
    leave: {
      backgroundColor: rgba(colors.secondary, 0),
      transform: 'translate(-50%, -50%) scale(0)',
    },
  });

  const showFullscreenMessage = (newMessage: string) => {
    setMessage(newMessage);
  };

  const eraseFullscreenMessage = () => {
    setMessage('');
  };

  // eslint-disable-next-line arrow-body-style
  const markup = transitions.map(({ item, props }) => {
    return item ? (
      <FullscreenModal style={props}>
        <p>{message}</p>
      </FullscreenModal>
    ) : null;
  });

  return (
    <FullscreenLoadingContext.Provider
      value={{ showFullscreenMessage, eraseFullscreenMessage }}
    >
      {markup}
      {children}
    </FullscreenLoadingContext.Provider>
  );
};

function useFullscreenLoading(): FullscreenLoadingData {
  const context = useContext(FullscreenLoadingContext);

  if (!context) {
    throw new Error(
      'useFullscreenLoading must be used within a FullscreenLoadingProvider',
    );
  }

  return context;
}

export { FullscreenLoadingProvider, useFullscreenLoading };
