import React, { useState, useRef } from 'react';
import { useOnClickOutside } from './hooks';
import { useAuth } from '../../hooks/auth';

import Burger from './Burger/Burger';
import Menu from './Menu/Menu';

import { MenuContainer } from './styles';
import Avatar from '../Avatar';
import getFirstName from '../../hooks/getFirstName';

const MenuResponsive: React.FC = () => {
  const { user } = useAuth();

  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const openMenu = React.useCallback(
    option => {
      document.body.className = option ? 'menu-open' : '';
      setOpen(option);
    },
    [setOpen],
  );

  useOnClickOutside(menuRef, () => {
    document.body.className = '';
    setOpen(false);
  });

  return (
    <MenuContainer ref={menuRef}>
      <div className="avatar-container">
        <Avatar
          avatar_url={user?.avatar_url || ''}
          widthAndHeightPicture="3.8"
          marginTop="0"
        />
        <div className="info-container">
          <p>Olá, {getFirstName(user?.name)}</p>
          <span>Pronto para vender?</span>
        </div>
      </div>
      <div className="class-menu-container">
        <span className="profile-class">{user?.profile_class}</span>
        <Burger open={open} setOpen={openMenu} />
        <Menu open={open} setOpen={openMenu} />
      </div>
    </MenuContainer>
  );
};

export default MenuResponsive;
