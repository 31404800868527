import styled from 'styled-components';
import { rgba } from 'polished';
import { colors } from '../../styles/globalStyles';

export interface SkeletonLoaderProps {
  height?: string;
  width?: string;
  marginBottom?: number;
}

export default styled.span<SkeletonLoaderProps>`
  display: block;
  height: ${({ height = '100%' }) => height};
  width: ${({ width = '100%' }) => width};
  position: relative;
  overflow: hidden;
  background-color: ${colors.secondary};
  margin-bottom: ${({ marginBottom = 1 }) => marginBottom}rem;
  border-radius: 5px;
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      ${rgba(colors.primary, 0)} 0,
      ${rgba(colors.primary, 0.2)} 20%,
      ${rgba(colors.primary, 0.5)} 60%,
      ${rgba(colors.primary, 0)}
    );
    animation: shimmer 2s infinite;
    content: '';
  }
`;
