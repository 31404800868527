/* eslint-disable indent */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { formatISO } from 'date-fns';

import { IStudent } from '../../../models/Student';
import Input from '../../../components/Input';
import { useSaleCheckout } from '../../../hooks/saleCheckout';
import { useToast } from '../../../hooks/toast';
import { usePutStudent } from '../../../services/api';

import {
  phoneNumberMask,
  documentNumberMask,
  postalCodeMask,
} from '../../../utils/masks';

import { Form } from './styles';

interface ISignUpData {
  name: string;
  email: string;
  rg: string;
  cpf: string;
  birthday: string;
  nationality: string;
  sex: 'Masculino' | 'Feminino';
  parentDad: string;
  parentMom: string;
  address: string;
  cep: string;
  phone: string;
}

const data: ISignUpData = {
  email: '',
  name: '',
  rg: '',
  cpf: '',
  birthday: '',
  nationality: '',
  sex: 'Masculino',
  parentDad: '',
  parentMom: '',
  address: '',
  cep: '',
  phone: '',
};

const SignUp: React.FC = () => {
  const { saleCheckout, setSaleCheckout } = useSaleCheckout();
  const { addToast } = useToast();

  const putStudent = usePutStudent();

  const history = useHistory();
  const { state } = useLocation<{ graduation: boolean }>();

  const [dummyData, setDummyData] = useState<ISignUpData>({
    ...data,
    name: saleCheckout?.student.name || 'sem nome',
    email: saleCheckout?.student.email || '',
    phone: saleCheckout?.student.phone_number || '',
    address: saleCheckout?.student.address || '',
    cpf: saleCheckout?.student.document_number || '',
    nationality: saleCheckout?.student.country_of_birth || '',
    cep: saleCheckout?.student.postal_code || '',
    birthday: saleCheckout?.student.birth_date || '',
  });

  const updateDataPropertyFromInput =
    (cleaner: (s: string) => string = a => a) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value } = e.target;
      const propName = e.target.name as keyof ISignUpData;
      setDummyData(currentDummyData => ({
        ...currentDummyData,
        [propName]: cleaner(value),
      }));
    };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
      phone: yup
        .string()
        .required('O telefone é obrigatório')
        .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, 'Telefone inválido'),
      email: yup
        .string()
        .required('Email é obrigatório')
        .email('Email inválido'),
      rg: yup
        .string()
        .required('O RG é obrigatório')
        .matches(/^\d{7,}$/, 'RG deve ter pelo meno 7 dígitos'),
      cpf: yup
        .string()
        .required('O CPF é obrigatório')
        .matches(
          /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/,
          'O CPF é inválido, siga o formato XXX.XXX.XXX-XX',
        ),
      birthday: yup
        .string()
        // .matches(/^(0[1-9]|[12][0-9]|3[01])[- /.]/)
        .required('Data de nascimento é obrigatória'),
      nationality: yup.string().required('Sua cidade natal é obrigatória'),
      parentDad: yup.string().required('Nome do pai é obrigatório'),
      address: yup.string().required('Endereço é obrigatório'),
      sex: yup
        .string()
        .required('Sexo é obrigatório')
        .oneOf(
          ['Masculino', 'Feminino'],
          'Sexo deve ser "Masculino" ou "Feminino"',
        ),
      parentMom: yup.string().required('Nome da mãe é obrigatório'),
      cep: yup
        .string()
        .required('CEP é obrigatório')
        .matches(/^\d{5}[-]{0,1}\d{3}$/, 'O CEP é inválido'),
    });

    try {
      await schema.validate(dummyData, {
        abortEarly: false,
      });

      const [birthYear, birthMonth, birthDay] = dummyData.birthday
        .split('-')
        .map(n => Number(n));

      if (saleCheckout?.student) {
        const student: IStudent = {
          ...saleCheckout?.student,
          name: dummyData.name,
          email: dummyData.email,
          document_number: dummyData.cpf,
          regional_document_number: dummyData.rg,
          birth_date: formatISO(new Date(birthYear, birthMonth - 1, birthDay)),
          country_of_birth: dummyData.nationality,
          gender: dummyData.sex,
          father_name: dummyData.parentDad,
          mother_name: dummyData.parentMom,
          address: dummyData.address,
          postal_code: dummyData.cep,
          phone_number: dummyData.phone,
        };

        // const res = await putStudent(student);
        await putStudent(student);

        // console.log('handleSubmit -> res', res);

        setSaleCheckout(
          sc =>
            sc && {
              ...sc,
              student,
            },
        );

        history.push('/checkout/gerar-link');
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const firstErrorMessage = error.errors[0];
        addToast({
          type: 'error',
          title: 'Erro nos dados informados',
          description: firstErrorMessage,
        });
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <Input
          required
          label="Nome completo do aluno"
          type="text"
          name="name"
          value={dummyData.name}
          onChange={updateDataPropertyFromInput()}
        />
        <Input
          required
          label="RG"
          type="text"
          name="rg"
          placeholder="RG"
          value={dummyData.rg}
          onChange={updateDataPropertyFromInput(rg => rg.replace(/\D/g, ''))}
        />
        <Input
          required
          label="Data de Nascimento"
          type="date"
          name="birthday"
          value={dummyData.birthday}
          onChange={updateDataPropertyFromInput()}
        />
        <Input
          required
          label="Naturalidade"
          type="text"
          name="nationality"
          value={dummyData.nationality}
          onChange={updateDataPropertyFromInput()}
        />
        <Input
          required
          label="Nome do pai"
          type="text"
          name="parentDad"
          value={dummyData.parentDad}
          onChange={updateDataPropertyFromInput()}
        />
        <Input
          required
          label="Logradouro e número"
          type="text"
          name="address"
          value={dummyData.address}
          onChange={updateDataPropertyFromInput()}
        />
        <Input
          required
          label="Curso escolhido"
          type="text"
          name="selected-course"
          className="course-disabled"
          disabled
          value={saleCheckout?.name_of_course || 'Curso escolhido indisponível'}
        />
      </div>
      <div>
        <Input
          required
          label="E-mail"
          type="email"
          name="email"
          placeholder="email@email.com"
          value={dummyData.email}
          onChange={updateDataPropertyFromInput()}
        />
        <Input
          required
          label="CPF"
          type="text"
          name="cpf"
          value={dummyData.cpf}
          onChange={updateDataPropertyFromInput(d =>
            documentNumberMask(d, { allowCompany: true }),
          )}
        />
        <Input
          required
          label="Telefone"
          type="text"
          name="phone"
          value={dummyData.phone}
          onChange={updateDataPropertyFromInput(p => phoneNumberMask(p))}
        />
        <label>Sexo</label>
        <select name="sex" onChange={updateDataPropertyFromInput()}>
          <option value="Feminino">Feminino</option>
          <option value="Masculino">Masculino</option>
        </select>
        <Input
          required
          label="Nome da Mãe"
          type="text"
          name="parentMom"
          value={dummyData.parentMom}
          onChange={updateDataPropertyFromInput()}
        />
        <Input
          required
          label="CEP"
          type="text"
          name="cep"
          value={dummyData.cep}
          onChange={updateDataPropertyFromInput(p => postalCodeMask(p))}
        />
        <div id="button-register">
          <button type="submit">Continuar cadastro</button>
        </div>
      </div>
    </Form>
  );
};

export default SignUp;
