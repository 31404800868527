import styled from 'styled-components';
import search from '../../../Assets/icons/search.svg';

export const MySalesContainer = styled.div`
  padding: 0rem 8rem 2rem;
  max-width: 120rem;
  margin: 0 auto;
  @media (max-width: 991px) {
    padding: 0;
  }
`;

export const SalesInfo = styled.section`
  background: #fff;
  border-radius: 2rem;

  padding: 5.2rem;
  @media (max-width: 991px) {
    border-radius: 0;
  }
  @media (max-width: 540px) {
    padding: 3.2rem;
  }
  @media (max-width: 420px) {
    padding: 1.6rem;
  }
  .container-button {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 3.2rem;
    button {
      background-color: var(--black-background);
      border: 2px solid var(--primary-color);
      border-radius: 1rem;
      color: #fff;

      font-weight: 800;
      font-size: 1.3rem;
      line-height: 1.3rem;
      text-transform: uppercase;

      min-width: 11rem;
      max-width: 13rem;
      max-height: 3.6rem;
      padding: 1rem 2rem;
      margin: 0.5rem 0.2rem;
      cursor: pointer;
      &::placeholder {
        background: url(${search}) no-repeat;
        background-repeat: no-repeat;
        background-position: right;
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 2.2rem;

        display: flex;
        justify-content: space-between;
      }
      &:hover,
      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px var(--primary-color);
      }
    }
    .active {
      background-color: var(--primary-color);
      color: var(--black-background);
    }
    span {
      background-color: var(--black-background);
      border: 2px solid var(--primary-color);
      border-radius: 1rem;
      color: #fff;

      font-weight: 800;
      font-size: 1.3rem;
      line-height: 1.3rem;
      text-align: center;
      text-transform: uppercase;

      min-width: 16rem;
      max-height: 3.6rem;
      padding: 1.1rem 2rem 0.6rem;

      margin: 0.5rem 0.2rem;
    }
  }
  .container-input {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 2rem;
    .input {
      background-color: var(--black-background);
      border: 2px solid var(--primary-color);
      border-radius: 1rem;
      color: #fff;

      font-size: 1.3rem;
      line-height: 1.3rem;

      min-width: 11rem;
      min-width: 13rem;
      max-height: 3.6rem;
      padding: 0.8rem 0.4rem;
      margin: 0 2px;
      &::placeholder {
        background: url(${search}) no-repeat;
        background-repeat: no-repeat;
        background-position: right;
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 2.2rem;

        display: flex;
        justify-content: space-between;
      }
      &:hover,
      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px var(--primary-color);
      }
      @media (max-width: 600px) {
        max-width: 15rem;
        max-height: 4rem;
      }
      @media (max-width: 420px) {
        max-width: 12.5rem;
      }
      @media (max-width: 380px) {
        max-width: 14rem;
      }
    }
  }
`;

export const Table = styled.table`
  border-spacing: 0;
  color: var(--black-background);
  text-align: center;
  display: block;

  max-width: 70rem;
  max-height: 40rem;
  padding-bottom: 0.8rem;
  margin: 2.8rem auto 0;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar-track {
    background-color: #c4c4c4;
    border-radius: 2rem;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    @media (max-width: 800px) {
      width: 4px;
      height: 4px;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: #646464;
    border-radius: 2rem;
  }
  thead tr,
  tbody tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;

    width: 69.4rem;
    padding: 1rem 0;
    @media (max-width: 560px) {
      width: 60rem;
    }
    @media (max-width: 460px) {
      width: 48rem;
    }
  }
  tbody tr {
    &:hover {
      background-color: #eee;
      border-radius: 1rem;
      cursor: pointer;
    }
  }
  th {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.3rem;
    min-width: 12rem;
  }
  td {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.4rem;
    min-width: 12rem;
  }
  .left {
    margin-left: 1.6rem;
    text-align: left;
  }
  .center {
    text-align: center;
  }
`;
