/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { formatISO } from 'date-fns/esm';
import Intro from '../../../components/Intro';
import Input from '../../../components/Input';

import userIntro from '../../../Assets/icons/user-intro.svg';
import cam from '../../../Assets/icons/cam.svg';

import fotoPerfil from '../../../Assets/foto-perfil.svg';

import { ProfileGenericContainer, PersonalData } from './styles';
import { useToast } from '../../../hooks/toast';
import {
  GetProfile as IGetProfile,
  useEditProfile,
  useGetProfile,
  useSendAvatar,
} from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { ILocalStorageUser } from '../../../models/User';

import {
  phoneNumberMask,
  documentNumberMask,
  postalCodeMask,
} from '../../../utils/masks';
import Avatar from '../../../components/Avatar';

const UserData: React.FC = () => {
  const getProfile = useGetProfile();
  const editProfile = useEditProfile();
  const sendAvatar = useSendAvatar();
  const { updateLocalStorageUser } = useAuth();

  const history = useHistory();
  const { addToast } = useToast();

  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState<IGetProfile['Response'] | undefined>(
    undefined,
  );
  const [previousData, setPreviousData] = useState<
    IGetProfile['Response'] | undefined
  >(undefined);

  useEffect(() => {
    (async () => {
      try {
        const res = await getProfile();
        setData({
          ...res,
          birth_date: (res.birth_date && res.birth_date.split('T')[0]) || '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: `${error.message} `,
        });
      }
    })();
  }, []);

  const handleProfileEdition = async () => {
    try {
      const handleValidation = async () => {
        const schema = yup.object().shape({
          name: yup.string().required('Nome é obrigatório'),
          document_number: yup
            .string()
            .required('CPF/CNPJ é um campo obrigatório')
            .matches(
              /^(\d{3}\.\d{3}\.\d{3}-\d{2})|(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/,
              'CPF/CNPJ deve seguir a seguinte regra "XXX.XXX.XXX-XX"/"XX.XXX.XXX/XXXX-XX"',
            ),
          birth_date: yup.string().nullable(),
          phone_number: yup
            .string()
            .matches(
              /^\(\d{2}\) \d{4,5}-\d{4}$/,
              'Telefone deve seguir a seguinte regra "(XX) XXXXX-XXXX',
            ),
          postal_code: yup
            .string()
            .nullable()
            .matches(
              /^\d{5}-\d{3}$/,
              'CEP deve seguir a seguinte regra "XXXXXX-XXX"',
            ),
          street: yup.string().nullable(),
          complement: yup.string().nullable(),
          neighborhood: yup.string().nullable(),
          city: yup.string().nullable(),
          state: yup
            .string()
            .nullable()
            .optional()
            .oneOf(
              [
                '',
                null,
                'AC',
                'AL',
                'AP',
                'AM',
                'BA',
                'CE',
                'DF',
                'ES',
                'GO',
                'MA',
                'MT',
                'MS',
                'MG',
                'PA',
                'PB',
                'PR',
                'PE',
                'PI',
                'RJ',
                'RN',
                'RS',
                'RO',
                'RR',
                'SC',
                'SP',
                'SE',
                'TO',
              ],
              'Estado inválido! Deve seguir formato curto, por exemplo: PR',
            ),
        });

        await schema.validate(data, { abortEarly: false });
      };
      await handleValidation();

      if (data) {
        await editProfile({
          name: data.name,
          document_number: data.document_number,
          birth_date: data.birth_date,
          phone_number: data.phone_number,
          postal_code: data.postal_code,
          street: data.street,
          complement: data.complement,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
        });
      }

      addToast({
        type: 'success',
        title: '',
        description: 'Seus dados foram atualizados',
      });

      setIsEdit(false);
      setData(data);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: `${error.errors[0]}`,
        });
      }
    }
  };

  const handleAvatarUpload = async (event: FileList) => {
    const imgFile = event[0];

    const isValidImageSelected =
      Number(imgFile.size) <= 3000000 && imgFile.type.startsWith('image');

    if (!isValidImageSelected) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Selecione uma imagem válida (máx. 3MB)',
      });
      return;
    }

    const formData = new FormData();
    formData.append('avatar', event[0]);

    try {
      const res = await sendAvatar(formData);
      if (res) {
        setData(res);
        updateLocalStorageUser(res as ILocalStorageUser);
      }
      addToast({
        type: 'success',
        title: '',
        description: 'Seu avatar foi atualizado com sucesso',
      });
    } catch (error) {
      // addToast({
      //   type: 'error',
      //   title: 'Ocorreu um erro',
      //   description: `${error}`,
      // });
      history.goBack();
    }
  };
  return (
    <ProfileGenericContainer>
      <PersonalData>
        <Intro
          image={userIntro}
          name="Dados Pessoais"
          text="Gerencie seus dados pessoais"
        />

        {data && (
          <section className="account">
            <div className="grid-form-data">
              <form>
                <div className="photo-profile">
                  <Avatar
                    avatar_url={data.avatar_url}
                    widthAndHeightPicture="14"
                    marginTop="0"
                  />
                  <div id="cam">
                    {' '}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={e => {
                        if (e.target.files !== null) {
                          handleAvatarUpload(e.target.files);
                        }
                      }}
                    />
                    <img itemType="file" src={cam} alt="cam" />
                  </div>
                </div>
                <div className="grid-input">
                  <>
                    <Input
                      label="Nome completo"
                      type="text"
                      name="name"
                      disabled={!isEdit}
                      placeholder="Jonathan Zanardi"
                      value={data.name}
                      onChange={e => setData({ ...data, name: e.target.value })}
                    />
                    <Input
                      label="CPF/CNPJ"
                      type="string"
                      name="cpf"
                      placeholder="000.000.000-00"
                      disabled
                      value={data.document_number}
                      onChange={e =>
                        setData({
                          ...data,
                          document_number: documentNumberMask(e.target.value),
                        })
                      }
                    />
                    <Input
                      label="Data de Nascimento"
                      type="date"
                      name="date-of-birth"
                      placeholder="000.000.000-00"
                      max={formatISO(new Date(), { representation: 'date' })}
                      disabled={!isEdit}
                      value={data.birth_date}
                      onChange={e => {
                        const dateString = e.target.value;
                        setData({ ...data, birth_date: dateString });
                      }}
                    />
                    <Input
                      label="Telefone"
                      type="string"
                      name="phone_number-number"
                      disabled={!isEdit}
                      value={data.phone_number}
                      onChange={e =>
                        setData({
                          ...data,
                          phone_number: phoneNumberMask(e.target.value),
                        })
                      }
                    />
                    <Input
                      label="CEP"
                      type="string"
                      name="postal_code"
                      disabled={!isEdit}
                      value={data.postal_code}
                      onChange={e =>
                        setData({
                          ...data,
                          postal_code: postalCodeMask(e.target.value),
                        })
                      }
                    />
                  </>
                </div>
                <div className="grid-input">
                  <>
                    <Input
                      label="Endereço"
                      type="text"
                      name="street"
                      disabled={!isEdit}
                      value={data.street}
                      onChange={e =>
                        setData({ ...data, street: e.target.value })
                      }
                    />
                    <Input
                      label="Complemento"
                      type="text"
                      name="address-complement"
                      disabled={!isEdit}
                      value={data.complement}
                      onChange={e =>
                        setData({ ...data, complement: e.target.value })
                      }
                    />
                    <Input
                      label="Bairro"
                      type="text"
                      name="district"
                      disabled={!isEdit}
                      value={data.neighborhood}
                      onChange={e =>
                        setData({ ...data, neighborhood: e.target.value })
                      }
                    />
                    <Input
                      label="Cidade"
                      type="text"
                      name="city"
                      disabled={!isEdit}
                      value={data.city}
                      onChange={e => setData({ ...data, city: e.target.value })}
                    />
                    <Input
                      label="Estado"
                      type="text"
                      name="state"
                      disabled={!isEdit}
                      value={data.state}
                      onChange={e =>
                        setData({ ...data, state: e.target.value })
                      }
                    />
                  </>
                </div>
                {!isEdit ? (
                  <button
                    onClick={() => {
                      setPreviousData(data);
                      setIsEdit(true);
                    }}
                    type="button"
                    id="edit"
                  >
                    Editar
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setIsEdit(false);
                      setData(previousData);
                    }}
                    type="button"
                    id="edit"
                  >
                    Cancelar
                  </button>
                )}
              </form>
            </div>
            {isEdit ? (
              <button type="submit" id="back" onClick={handleProfileEdition}>
                Salvar
              </button>
            ) : (
              <Link to="/profile" id="back">
                Voltar
              </Link>
            )}
          </section>
        )}
      </PersonalData>
    </ProfileGenericContainer>
  );
};

export default UserData;
