import React from 'react';

import { StyledBurger } from './styles';

import { useIsBackgroundFixed } from '../../../hooks/isBackgroundFixed';

interface IBurgerProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Burger: React.FC<IBurgerProps> = ({ open, setOpen }) => {
  const { isBackgroundFixed, setIsBackgroundFixed } = useIsBackgroundFixed();

  function handleClickMenu() {
    setOpen(!open);
    setIsBackgroundFixed(!isBackgroundFixed);
  }

  return (
    <StyledBurger
      open={open}
      onClick={handleClickMenu}
      aria-label="Menu"
      title="Menu"
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Burger;
