/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { useLoadingBar } from '../../../hooks/loadingBar';
import {
  GetVideos,
  ITrainingVideoBody,
  useGetHomeVideo,
  useGetVideos,
} from '../../../services/api';
import SkeletonLoader from '../../../components/SkeletonLoader';
import Play from '../../../Assets/icons/play.svg';
import Arrow from '../../../Assets/icons/arrow-black.svg';
import { TrainingContainer } from './styles';

interface IEntityGroup {
  [key: string]: ITrainingVideoBody[];
}

const groupedByCategory = (info: ITrainingVideoBody[]) =>
  info.reduce(
    (categoriesObject: IEntityGroup, item) => ({
      ...categoriesObject,
      [item.category]: [...(categoriesObject[item.category] || []), item],
    }),
    {},
  );

const Training: React.FC = () => {
  const history = useHistory();

  const getVideos = useGetVideos();
  const getHomeVideo = useGetHomeVideo();
  const { token } = useAuth();
  const { addToast } = useToast();
  const { isBarLoading } = useLoadingBar();

  const [trainingVideos, setTrainingVideos] = useState<GetVideos['Response']>({
    data: [
      {
        description: '',
        id: '',
        link_url: '',
        picture: '',
        title: '',
        category: '',
      },
    ],
    cursor: {
      afterCursor: '',
      beforeCursor: '',
    },
  });

  const [homeVideo, setHomeVideo] = useState<{
    link_url: string;
    picture: string;
    title: string;
    category: string;
  }>({
    link_url: '',
    picture: '',
    category: '',
    title: '',
  });

  useEffect(() => {
    (async () => {
      try {
        const { link_url, picture, category, title } = await getHomeVideo();
        setHomeVideo({
          link_url,
          picture,
          category,
          title,
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Cannot Get Videos',
        });
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await getVideos({ token });
        setTrainingVideos(res);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Cannot Get Videos',
        });
      }
    })();
  }, []);

  return (
    <TrainingContainer>
      <div className="content-container">
        <div className="videos-container">
          {isBarLoading ? (
            <SkeletonLoader
              style={{
                backgroundColor: '#fff',
                borderRadius: '2rem',
                width: '64rem',
                height: '30rem',
                margin: '0 auto',
              }}
            />
          ) : (
            <div className="main-videos-container">
              <div
                role="button"
                className="main-video-banner"
                onClick={() => {
                  history.push(`/treinamento/${homeVideo.category}`, {
                    link_url: homeVideo.link_url,
                    title: homeVideo.title,
                  });
                }}
              >
                <img
                  alt="Thumbnail Home"
                  src={homeVideo.picture}
                  className="main-video-frame"
                  onError={e => {
                    const imgElement = e.target as HTMLImageElement;
                    imgElement.src =
                      'https://www.sinprosp.org.br/images/404.png';
                  }}
                />
                <div className="main-video-caption">
                  <div className="main-video-caption-txt">
                    <img src={Play} alt="Play" />
                    <span>Assista Agora</span>
                  </div>
                  <img src={Arrow} alt="Seta para direita" />
                </div>
              </div>
            </div>
          )}
          <div className="scroll-videos-container">
            {trainingVideos.data.length ? (
              Object.keys(groupedByCategory(trainingVideos.data)).map(
                (category: string) => (
                  <>
                    <div className="title-section">
                      <h3>{category}</h3>
                    </div>
                    <div className="container-video">
                      <div className="video-section">
                        <div className="video-section-row">
                          <ul style={{ display: 'flex' }}>
                            <>
                              {groupedByCategory(trainingVideos.data)
                                [category].slice(0, 4)
                                .map(info => (
                                  <>
                                    {isBarLoading ? (
                                      <SkeletonLoader
                                        style={{
                                          // backgroundColor: '#fff',
                                          borderRadius: '2rem',
                                          minWidth: '24rem',
                                          maxWidth: '100%',
                                          minHeight: '14rem',
                                          maxHeight: '18rem',
                                          margin: '0 auto',
                                        }}
                                      />
                                    ) : (
                                      <>
                                        <div
                                          role="button"
                                          className="video-card"
                                          onClick={() => {
                                            history.push(
                                              `/treinamento/${info.category}`,
                                              {
                                                link_url: info.link_url,
                                                title: info.title,
                                              },
                                            );
                                          }}
                                        >
                                          <li className="scroll-video scroll-thumb scroll-image">
                                            <img
                                              alt={`Thumbnail do Curso ${info.title} - ${info.id}`}
                                              src={info.picture}
                                              className="scroll-video-frame"
                                              onError={e => {
                                                const imgElement =
                                                  e.target as HTMLImageElement;
                                                imgElement.src =
                                                  'https://www.sinprosp.org.br/images/404.png';
                                              }}
                                            />
                                          </li>
                                          <div className="scroll-video-name">
                                            <p>{info.title}</p>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                            <Link
                              className="link"
                              to={`/treinamento/${category}`}
                            >
                              <img src={Arrow} alt="Seta para direita" />
                              Ver Todos
                            </Link>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                ),
              )
            ) : (
              <div className="container-video">
                {' '}
                <p className="not-data">
                  Ainda não temos nenhum vídeo, volte mais tarde
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </TrainingContainer>
  );
};

export default Training;
