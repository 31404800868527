import React, { HTMLAttributes } from 'react';

import fotoPerfil from '../../Assets/foto-perfil.svg';

import { ProfilePicture } from './styles';

interface IAvatarProps extends HTMLAttributes<HTMLImageElement> {
  avatar_url: string | undefined;
  widthAndHeightPicture: string;
  className?: string | undefined;
  marginTop?: string;
}

const Avatar: React.FC<IAvatarProps> = ({
  avatar_url,
  widthAndHeightPicture,
  className,
  marginTop = '0',
}) => (
  <>
    <ProfilePicture
      picture={avatar_url || fotoPerfil}
      widthAndHeightPicture={widthAndHeightPicture}
      className={className}
      marginTop={marginTop}
    />
  </>
);

export default Avatar;
