import styled from 'styled-components';

import iconSearch from '../../../Assets/icons/iconSearch.svg';

interface IModalProps {
  isShow: boolean;
}

export const TeamContainer = styled.div`
  padding: 0rem 8rem 2rem;
  max-width: 120rem;
  margin: 0px auto;
  @media (max-width: 991px) {
    padding: 0;
  }
`;

export const SalesTeam = styled.section`
  background: #fff;
  border-radius: 2rem;

  height: 60rem;
  @media (max-width: 1130px) {
    padding: 0 1rem;
  }
  @media (max-width: 991px) {
    border-radius: 0;
    padding: 0;
  }
  @media (max-width: 700px) {
    padding: 0 1rem;
  }
  h1 {
    color: var(--black-background);
    font-weight: 800;
    font-size: 23px;
    line-height: 24px;
    text-align: center;

    padding-top: 52px;
    margin-bottom: 26px;
  }
  .search-and-copy-clipboard {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    max-width: 70rem;
    margin: 0 auto;
    @media (max-width: 540px) {
      flex-direction: column;
      align-items: center;
    }
  }
  form {
    @media (max-width: 540px) {
      margin-bottom: 1.6rem;
    }
    input {
      background-color: rgba(196, 196, 196, 0.75);
      border: none;
      border-radius: 2rem;
      color: #646464;
      font-size: 1.4rem;
      line-height: 2.2rem;

      max-width: 20rem;
      max-height: 2.8rem;
      padding: 0.8rem;
      &::placeholder {
        background: url(${iconSearch}) no-repeat;
        background-repeat: no-repeat;
        background-position: right;
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 2.2rem;

        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
      &:hover,
      &:focus {
        outline: none;
        box-shadow: 0 0 0 1.5px #646464;
      }
      @media (max-width: 600px) {
        max-width: 18rem;
        max-height: 3.4rem;
      }
    }
  }
  .copy-clipboard {
    @media (max-width: 540px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    span {
      color: var(--black-background);
      font-size: 1.1rem;
      font-weight: 500;
    }
    div {
      display: flex;
      justify-content: flex-end;
    }
    input {
      border-radius: 1rem;
      border: 1px solid var(--primary-color);
      background-color: var(--black-background);
      color: #fff;
      font-size: 1rem;
      font-weight: 700;

      width: 32rem;
      height: 2.8rem;
      padding: 0 8px;
      outline: none;
      @media (max-width: 600px) {
        height: 3rem;
        padding: 0.8rem 0.8rem 0.6rem;
      }
    }
    button {
      background: var(--primary-color);
      border: 1.5px solid var(--primary-color);
      border-radius: 1rem;

      padding: 0.2rem 1.6rem;
      margin-left: -2rem;
      cursor: pointer;
      outline: none;
    }
  }
`;

export const Table = styled.table`
  border-spacing: 0;
  color: var(--black-background);
  text-align: center;
  display: block;

  max-width: 70rem;
  max-height: 40rem;
  padding-bottom: 0.8rem;
  margin: 2.8rem auto 0;
  overflow: auto;
  ::-webkit-scrollbar-track {
    background-color: #c4c4c4;
    border-radius: 2rem;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    @media (max-width: 800px) {
      width: 4px;
      height: 4px;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: #646464;
    border-radius: 2rem;
  }
  thead tr,
  tbody tr {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr 1fr 2fr;
    align-items: center;

    width: 69.4rem;
    padding: 1rem 0;
    @media (max-width: 560px) {
      width: 60rem;
    }
    @media (max-width: 460px) {
      width: 48rem;
    }
  }
  tbody tr {
    &:hover {
      background-color: #eee;
      border-radius: 1rem;
      cursor: pointer;
    }
  }
  th {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  td {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
  .left {
    margin-left: 1.6rem;
    text-align: left;
  }
  .center {
    text-align: center;
  }
  .avatar {
    display: block;
    margin: 0 auto;
  }
`;

export const Modal = styled.div<IModalProps>`
  background: var(--black-background);
  border: 1px solid var(--primary-color);
  border-radius: 1rem;
  box-sizing: border-box;
  color: var(--black-background);
  display: ${props => (props.isShow ? 'block' : 'none')};

  padding: 1.6rem 3.2rem;

  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -353px;
  margin-top: -167px;
  @media (max-width: 700px) {
    width: 60rem;
    margin-left: -30rem;
  }
  @media (max-width: 600px) {
    max-width: 90vw;
    min-height: 60rem;
    max-height: 90vh;
    margin-left: -45vw;
    margin-top: calc(-45vh + 2rem);
    z-index: 100;
  }
  .modal-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 4rem;

    height: 30rem;
    position: relative;

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .grid-photo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        background: var(--primary-color);
        border-radius: 10px;
        font-weight: 800;
        font-size: 18px;
        line-height: 19px;
        padding: 10px 0;
        text-align: center;

        width: 172px;
        margin-top: 12px;
      }
    }
    .personal-data {
      display: flex;
      flex-direction: column;
      @media (max-width: 600px) {
        margin-top: 3.2rem;
      }
      p {
        background: #fff;
        border-radius: 10px;
        font-weight: 800;
        font-size: 18px;
        line-height: 19px;

        padding: 12px;
        margin-bottom: 16px;
      }
      label {
        color: #fff;
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;
        @media (max-width: 370px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
  #close {
    background: var(--black-background);
    border: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
    font-weight: 800;
    font-size: 18px;
    line-height: 19px;

    width: 172px;
    height: 51px;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
    transition: 200ms;
    @media (max-width: 600px) {
      width: 100%;
      min-height: 4.3rem;
      position: static;
    }
    &:hover,
    &:focus {
      color: var(--primary-color);
      outline: none;
      border: 1px solid var(--primary-color);
      box-shadow: 0 0 0 1px var(--primary-color);
    }
  }
`;
