import styled from 'styled-components';

export const TooltipIcon = styled.div`
  max-width: 1.2rem;
  padding: 0.2rem;
  margin-left: 0.4rem;
  position: relative;
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  display: none;
  max-width: 25rem;
`;

interface ITooltipCardProps {
  isMultinivelScreen: boolean;
}

export const TooltipCard = styled.div<ITooltipCardProps>`
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: justify;

  & ${TooltipIcon}:hover + ${TooltipBox} {
    background-color: #fff;
    border: 1px solid var(--black-background);
    border-radius: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    color: var(--black-background);
    display: block;
    text-align-last: start;

    position: absolute;
    padding: 0.8rem 1.1rem;
    z-index: 10;
    ${({ isMultinivelScreen }) =>
      isMultinivelScreen && {
        top: 0,
        left: '-220px',
      }}
    @media (max-width: 800px) {
      margin-left: -5rem;
    }
  }
`;
