export const banks = [
  { code: '246', name: 'Banco ABC Brasil S.A.' },
  { code: '075', name: 'Banco ABN AMRO S.A.' },
  { code: '121', name: 'Banco Agibank S.A.' },
  { code: '025', name: 'Banco Alfa S.A.' },
  { code: '641', name: 'Banco Alvorada S.A.' },
  { code: '065', name: 'Banco Andbank (Brasil) S.A.' },
  { code: '096', name: 'Banco B3 S.A.' },
  { code: '024', name: 'Banco BANDEPE S.A.' },
  { code: '318', name: 'Banco BMG S.A.' },
  { code: '752', name: 'Banco BNP Paribas Brasil S.A.' },
  { code: '107', name: 'Banco BOCOM BBM S.A.' },
  { code: '063', name: 'Banco Bradescard S.A.' },
  { code: '036', name: 'Banco Bradesco BBI S.A.' },
  { code: '204', name: 'Banco Bradesco Cartões S.A.' },
  { code: '394', name: 'Banco Bradesco Financiamentos S.A.' },
  { code: '237', name: 'Banco Bradesco S.A.' },
  { code: '218', name: 'Banco BS2 S.A.' },
  { code: '208', name: 'Banco BTG Pactual S.A.' },
  { code: '473', name: 'Banco Caixa Geral - Brasil S.A.' },
  { code: '040', name: 'Banco Cargill S.A.' },
  { code: '739', name: 'Banco Cetelem S.A.' },
  { code: '233', name: 'Banco Cifra S.A.' },
  { code: '745', name: 'Banco Citibank S.A.' },
  { code: '756', name: 'Banco Cooperativo do Brasil S.A. - BANCOOB' },
  { code: '748', name: 'Banco Cooperativo Sicredi S.A.' },
  { code: '222', name: 'Banco Credit Agricole Brasil S.A.' },
  { code: '505', name: 'Banco Credit Suisse (Brasil) S.A.' },
  { code: '003', name: 'Banco da Amazônia S.A.' },
  { code: '083', name: 'Banco da China Brasil S.A.' },
  { code: '707', name: 'Banco Daycoval S.A.' },
  { code: '654', name: 'Banco Digimais S.A.' },
  { code: '001', name: 'Banco do Brasil S.A.' },
  { code: '047', name: 'Banco do Estado de Sergipe S.A.' },
  { code: '037', name: 'Banco do Estado do Pará S.A.' },
  { code: '041', name: 'Banco do Estado do Rio Grande do Sul S.A.' },
  { code: '004', name: 'Banco do Nordeste do Brasil S.A.' },
  { code: '224', name: 'Banco Fibra S.A.' },
  { code: '626', name: 'Banco Ficsa S.A.' },
  { code: '094', name: 'Banco Finaxis S.A.' },
  { code: '612', name: 'Banco Guanabara S.A.' },
  { code: '012', name: 'Banco Inbursa S.A.' },
  { code: '604', name: 'Banco Industrial do Brasil S.A.' },
  { code: '653', name: 'Banco Indusval S.A.' },
  { code: '077', name: 'Banco Inter S.A.' },
  { code: '249', name: 'Banco Investcred Unibanco S.A.' },
  { code: '184', name: 'Banco Itaú BBA S.A.' },
  { code: '029', name: 'Banco Itaú Consignado S.A.' },
  { code: '479', name: 'Banco ItauBank S.A' },
  { code: '376', name: 'Banco J. P. Morgan S.A.' },
  { code: '074', name: 'Banco J. Safra S.A.' },
  { code: '217', name: 'Banco John Deere S.A.' },
  { code: '600', name: 'Banco Luso Brasileiro S.A.' },
  { code: '243', name: 'Banco Máxima S.A.' },
  { code: '389', name: 'Banco Mercantil do Brasil S.A.' },
  { code: '370', name: 'Banco Mizuho do Brasil S.A.' },
  { code: '746', name: 'Banco Modal S.A.' },
  { code: '456', name: 'Banco MUFG Brasil S.A.' },
  { code: '169', name: 'Banco Olé Bonsucesso Consignado S.A.' },
  { code: '212', name: 'Banco Original S.A.' },
  { code: '623', name: 'Banco PAN S.A.' },
  { code: '611', name: 'Banco Paulista S.A.' },
  { code: '643', name: 'Banco Pine S.A.' },
  { code: '747', name: 'Banco Rabobank International Brasil S.A.' },
  { code: '633', name: 'Banco Rendimento S.A.' },
  { code: '120', name: 'Banco Rodobens S.A.' },
  { code: '422', name: 'Banco Safra S.A.' },
  { code: '033', name: 'Banco Santander (Brasil) S.A.' },
  { code: '743', name: 'Banco Semear S.A.' },
  { code: '630', name: 'Banco Smartbank S.A.' },
  { code: '366', name: 'Banco Société Générale Brasil S.A.' },
  { code: '464', name: 'Banco Sumitomo Mitsui Brasileiro S.A.' },
  { code: '082', name: 'Banco Topázio S.A.' },
  { code: '634', name: 'Banco Triângulo S.A.' },
  { code: '655', name: 'Banco Votorantim S.A.' },
  { code: '610', name: 'Banco VR S.A.' },
  { code: '119', name: 'Banco Western Union do Brasil S.A.' },
  { code: '102', name: 'Banco XP S.A.' },
  { code: '021', name: 'BANESTES S.A. Banco do Estado do Espírito Santo' },
  { code: '755', name: 'Bank of America Merrill Lynch Banco Múltiplo S.A.' },
  { code: '250', name: 'BCV - Banco de Crédito e Varejo S.A.' },
  { code: '144', name: 'BEXS Banco de Câmbio S.A.' },
  { code: '017', name: 'BNY Mellon Banco S.A.' },
  { code: '070', name: 'BRB - Banco de Brasília S.A.' },
  { code: '104', name: 'Caixa Econômica Federal' },
  { code: '320', name: 'China Construction Bank (Brasil) Banco Múltiplo S.A.' },
  { code: '477', name: 'Citibank N.A.' },
  { code: '487', name: 'Deutsche Bank S.A. - Banco Alemão' },
  { code: '064', name: 'Goldman Sachs do Brasil Banco Múltiplo S.A.' },
  { code: '062', name: 'Hipercard Banco Múltiplo S.A.' },
  { code: '269', name: 'HSBC Brasil S.A. - Banco de Investimento' },
  { code: '492', name: 'ING Bank N.V.' },
  { code: '652', name: 'Itaú Unibanco Holding S.A.' },
  { code: '341', name: 'Itaú Unibanco S.A.' },
  { code: '488', name: 'JPMorgan Chase Bank, National Association' },
  { code: '399', name: 'Kirton Bank S.A. - Banco Múltiplo' },
  { code: '128', name: 'MS Bank S.A. Banco de Câmbio' },
  { code: '260', name: 'Nu Pagamentos S.A.' },
  { code: '254', name: 'Paraná Banco S.A.' },
  { code: '125', name: 'Plural S.A. - Banco Múltiplo' },
  { code: '751', name: 'Scotiabank Brasil S.A. Banco Múltiplo' },
  { code: '095', name: 'Travelex Banco de Câmbio S.A.' },
  { code: '129', name: 'UBS Brasil Banco de Investimento S.A.' },
];
