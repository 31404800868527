/* eslint-disable indent */
import styled from 'styled-components';

interface IBackgroundProps {
  isBackgroundFixed: boolean;
}

export const Background = styled.div<IBackgroundProps>`
  background-color: var(--black-background);
  color: #fff;

  max-width: 100vw;
  min-height: 100%;
  ${({ isBackgroundFixed }) =>
    isBackgroundFixed && {
      minWidth: '100vw',
      position: 'fixed',
      top: '0',
      right: '0',
    }};
  @media (max-width: 991px) {
    min-width: 100vw;
    max-width: 100vw;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 25rem minmax(calc(100vw - 26rem), auto);
  margin: 0 auto;
  overflow: hidden;

  @media (max-width: 991px) {
    display: flex;
    padding: 7.2rem 0 5.2rem 0;
  }
  @media (max-width: 600px) {
    padding: 5rem 0 2rem 0;
  }
`;

export const GridMain = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  padding-bottom: 6rem;
  position: relative;
  z-index: 1;
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`;

export const Main = styled.main`
  min-height: calc(100vh + 5rem);
  @media (max-width: 991px) {
    min-width: 100%;
    padding: 0;
  }
  @media (max-width: 800px) {
    min-height: 100vh;
  }
`;
