import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import logo from '../../../Assets/whiteLogo.png';
import { useToast } from '../../../hooks/toast';
import { useForgotPassword } from '../../../services/api';

import { RecoveryContainer } from './styles';

const PasswordRecovery: React.FC = () => {
  const forgotPassword = useForgotPassword();
  const history = useHistory();
  const { addToast } = useToast();
  const [email, setEmail] = useState('');
  const handleSubmit = async () => {
    try {
      await forgotPassword({ email });

      addToast({
        type: 'success',
        title: '',
        description: 'Você recebeu um email com o link de recuperação de senha',
      });
      history.push('/');
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro',
        description: `${error.message}`,
      });
    }
  };

  return (
    <RecoveryContainer>
      <img height="200rem" src={logo} alt="Logo Fael" className="animeTop" />
      <form className="animeTop">
        <h1 id="login" className="animeTop">
          Enviaremos um email para a recuperação da sua senha
        </h1>
        <input
          type="email"
          placeholder="Digite seu e-mail"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <button type="button" onClick={handleSubmit}>
          Recuperar Senha
        </button>
      </form>
      <Link to="/" id="login" className="animeTop">
        Voltar ao Login
      </Link>
    </RecoveryContainer>
  );
};

export default PasswordRecovery;
