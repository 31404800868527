/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { table } from 'console';
import Intro from '../../../components/Intro';
import cumprimento from '../../../Assets/icons/cumprimento.svg';

import { SalesInfo, Table, MySalesContainer } from './styles';
import { getCurrencyMask } from '../../../utils/currencyFormatter';
import PaymentStatusEnum from '../../../enum/PaymentStatusEnum';
import useDebouncedText from '../../../hooks/debouncedText';
import useDataPaginator from '../../../hooks/dataPaginator';
import { useLoadingBar } from '../../../hooks/loadingBar';
import { ISale } from '../../../models/Sale';
import { useGetMySales } from '../../../services/api';
import DatePicker from '../../../components/DatePicker';
import SkeletonLoader from '../../../components/SkeletonLoader';

const MySales: React.FC = () => {
  const getMySales = useGetMySales();
  const { isBarLoading } = useLoadingBar();

  const [saleSearch, setSaleSearch] = useDebouncedText('');
  const [isActive, setActive] = useState<boolean>(true);

  const [dateRange, setDateRange] = useState<Date[]>([
    new Date(new Date().setDate(new Date().getDate() - 30)),
    new Date(new Date().setDate(new Date().getDate() + 1)),
  ]);
  const [data, setData, dataListLoading, checkDataListBottom] =
    useDataPaginator<ISale, [Date[], string, boolean]>({
      filters: [dateRange, saleSearch, isActive],
      storeNewData: async (filters, cursor) => {
        const res = await getMySales({
          cursor: cursor as string,
          filter_course_name: filters[1],
          paid: filters[2],
          after_date: filters[0][0].toISOString(),
          before_date: filters[0][1].toISOString(),
        });
        console.log('---<<>>', res);
        return {
          nextCursor: res.cursor.afterCursor,
          shouldResetData: !res.cursor.beforeCursor,
          nextData: res.data,
        };
      },
    });
  const totalValue = data.reduce((prev, cur) => prev + cur.value_of_course, 0);
  function handleSelect() {
    setActive(!isActive);
  }
  return (
    <MySalesContainer>
      <Intro
        image={cumprimento}
        name="Minhas Vendas"
        text="Acompanhe o resultado de suas vendas"
      />
      <SalesInfo>
        <div className="container-button">
          <button
            type="button"
            className={!isActive ? 'active' : ''}
            onClick={handleSelect}
          >
            Não Pagas
          </button>
          <button
            type="button"
            className={isActive ? 'active' : ''}
            onClick={handleSelect}
          >
            Pagas
          </button>
          <span>{getCurrencyMask(totalValue)}</span>
        </div>
        <div className="container-input">
          <input
            className="input"
            type="text"
            placeholder="Curso"
            value={saleSearch}
            onChange={e => setSaleSearch(e.target.value)}
          />
          <DatePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
            heightLarge={false}
          />
        </div>
        <Table onScroll={checkDataListBottom}>
          <thead>
            <tr>
              <th>Aluno</th>
              <th>Curso</th>
              <th>Valor</th>
              <th>Status</th>
              <th>Data Pedido</th>
            </tr>
          </thead>
          <tbody>
            <>
              {data.length ? (
                data.map(info => {
                  const status = (i: string) => {
                    if (i === PaymentStatusEnum.pending) {
                      return 'Pendente';
                    }
                    if (i === 'Scheduling') {
                      return 'Agendamento';
                    }
                    if (i === PaymentStatusEnum.paid) {
                      return 'Pago';
                    }
                    if (i === PaymentStatusEnum.waiting) {
                      return 'Esperando';
                    }
                    return 'Erro';
                  };
                  return (
                    <>
                      <tr>
                        <td className="left">{info.student.name}</td>
                        <td className="center">{info.name_of_course}</td>
                        <td className="center">
                          {getCurrencyMask(info.promotional_value_of_course)}
                        </td>
                        <td className="center">{status(info.status)}</td>
                        <td className="center">
                          {format(parseISO(info.created_at), 'dd/MM/yyyy')}
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <span>Nenhuma venda encontrada</span>
              )}
              {dataListLoading && (
                <>
                  <SkeletonLoader
                    className="rank"
                    style={{
                      minWidth: '50rem',
                      height: '5rem',
                      backgroundColor: 'white',
                    }}
                  />
                </>
              )}
            </>
          </tbody>
        </Table>
      </SalesInfo>
    </MySalesContainer>
  );
};

export default MySales;
