/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosResponse, AxiosError } from 'axios';
import { IFaqBody } from '../../models/Faq';
import { IUser, ILocalStorageUser } from '../../models/User';
import { ICourseBody } from '../../models/Course';

import { IStudent } from '../../models/Student';
import { ISale } from '../../models/Sale';
import useApiCall from '../../hooks/apiCall';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

interface AuthRequest {
  token: string;
}

interface Login {
  Request: {
    email: string;
    password: string;
  };
  Response: {
    user: IUser;
    token: string;
  };
}

export interface PaginatedResponse<T> {
  [x: string]: unknown;
  data: T[];
  cursor: {
    afterCursor: string | null;
    beforeCursor: string | null;
  };
}

const getAuthHeader = (token: string | undefined) => ({
  headers: {
    Authorization: `Bearer ${token || ''}`,
  },
});
export const useLogin = () =>
  useApiCall<Login['Request'], Login['Response']>({
    request: async ({ email, password }) => {
      const res: AxiosResponse<Login['Response']> = await api.post(
        '/sessions',
        {
          email,
          password,
        },
      );
      if (res.data.user.profile_role !== 'Seller') {
        throw new Error();
      }
      return res.data;
    },
    errorMessage: 'Não foi possível realizar a requisição',
    getDynamicErrorMessage: (error: AxiosError) => {
      if (error.isAxiosError) {
        const errorMessage: string = error.response?.data?.error;
        if (errorMessage === 'Incorrect email/password combination.') {
          const toastMessage = 'Email/Senha inválidos.';
          return toastMessage;
        }
      }

      return '';
    },
  });

interface NewTechQuestion {
  Request: AuthRequest & {
    question: string;
  };

  Response: {
    data: boolean;
  };
}

export const useNewTechQuestion = () =>
  useApiCall<NewTechQuestion['Request'], NewTechQuestion['Response']>({
    request: async ({ question, token }) => {
      const res: AxiosResponse<NewTechQuestion['Response']> = await api.post(
        '/support/technical-question',
        {
          question,
        },
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description:
        'Não foi possível registrar sua pergunta, por favor tente mais tarde',
    },
  });

interface Help {
  Request: {
    name: string;
    email: string;
    phone_number: string;
    help_description: string;
  };

  Response: {
    data: boolean;
  };
}

export const useNewHelpAsker = () =>
  useApiCall<Help['Request'], Help['Response']>({
    request: async ({ email, help_description, name, phone_number }) => {
      const res: AxiosResponse<Help['Response']> = await api.post(
        '/support/help',
        {
          email,
          help_description,
          name,
          phone_number,
        },
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description:
        'Não foi possível registrar sua pergunta, por favor tente mais tarde',
    },
  });

interface ChangePassword {
  Request: AuthRequest & {
    old_password: string;
    password: string;
    password_confirmation: string;
  };

  Response: {
    ok: boolean;
  };
}

export const useChangePassword = () =>
  useApiCall<ChangePassword['Request'], ChangePassword['Response']>({
    request: async ({
      old_password,
      password,
      password_confirmation,
      token,
    }) => {
      const res: AxiosResponse<ChangePassword['Response']> = await api.post(
        '/password/change',
        {
          old_password,
          password,
          password_confirmation,
        },
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível alterar sua senha, tente mais tarde',
    },
  });

interface EditProfileData {
  Request: {
    name?: string;
    document_number?: string;
    birth_date?: string;
    phone_number?: string;
    postal_code?: string;
    street?: string;
    complement?: string;
    neighborhood?: string;
    city?: string;
    state?: string;
    adherence_agreement?: boolean;
  };
  Response: void;
}

export const useEditProfile = () =>
  useApiCall<
    EditProfileData['Request'],
    AxiosResponse<EditProfileData['Response']>
  >({
    request: async (
      {
        name,
        document_number,
        birth_date,
        phone_number,
        postal_code,
        street,
        complement,
        neighborhood,
        city,
        state,
        adherence_agreement,
      },
      token,
    ) => {
      const res: AxiosResponse<EditProfileData['Response']> = await api.put(
        '/profile',
        {
          name,
          document_number,
          birth_date,
          phone_number,
          postal_code,
          street,
          complement,
          neighborhood,
          city,
          state,
          adherence_agreement,
        },
        getAuthHeader(token),
      );
      return res;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível alterar seus dados, tente mais tarde',
    },
  });

interface SignUp {
  Request: {
    name: string;
    email: string;
    password: string;
    password_confirmation: string;
    phone_number: string;
    document_number: string;
    profile_role: string;
    referrer_id: string;
  };
  Response: {
    user: ILocalStorageUser;
    token: string;
  };
}

export const useSignUp = () =>
  useApiCall<SignUp['Request'], SignUp['Response']>({
    request: async ({
      name,
      email,
      password,
      password_confirmation,
      phone_number,
      document_number,
      profile_role,
      referrer_id,
    }) => {
      const res: AxiosResponse<SignUp['Response']> = await api.post(
        '/referrals',
        {
          name,
          email,
          password,
          password_confirmation,
          phone_number,
          document_number,
          profile_role,
          referrer_id,
        },
      );
      return res.data;
    },
    errorMessage: 'Não foi possível realizar a requisição',
    getDynamicErrorMessage: (error: AxiosError) => {
      if (error.isAxiosError) {
        const errorMessage: string = error.response?.data?.error;
        if (
          errorMessage ===
          'Erro ao cadastrar vendedor: Este e-mail já está cadastrado. '
        ) {
          const toastMessage = 'Esse email já foi cadastrado';
          return toastMessage;
        }
        if (
          errorMessage ===
          'Erro ao cadastrar vendedor: Esse documento já cadastrado. '
        ) {
          const toastMessage = 'Esse documento já foi cadastrado';
          return toastMessage;
        }
      }

      return 'Não foi possível realizar a requisição';
    },
  });

interface ResetPassword {
  Request: {
    password: string;
    password_confirmation: string;
    token: string;
  };

  Response: {
    ok: boolean;
  };
}

export const useResetPassword = () =>
  useApiCall<ResetPassword['Request'], ResetPassword['Response']>({
    request: async ({ password, password_confirmation, token }) => {
      const res: AxiosResponse<ResetPassword['Response']> = await api.post(
        '/password/reset',
        {
          password,
          password_confirmation,
          token,
        },
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível alterar sua senha, tente mais tarde',
    },
  });

interface ForgotPassword {
  Request: {
    email: string;
  };

  Response: {
    ok: boolean;
  };
}

export const useForgotPassword = () =>
  useApiCall<ForgotPassword['Request'], ForgotPassword['Response']>({
    request: async ({ email }) => {
      const res: AxiosResponse<ForgotPassword['Response']> = await api.post(
        '/password/forgot',
        {
          email,
        },
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description:
        'Não foi possível enviar o email de recuperação, tente mais tarde',
    },
  });

export interface GetHomeVideo {
  Request: void;

  Response: {
    id: string;
    category: string;
    title: string;
    description: string;
    link_url: string;
    set_home_page_date: string;
    is_available: boolean;
    is_active: boolean;
    picture: string;
    created_at: string;
    updated_at: string;
  };
}

export const useGetHomeVideo = () =>
  useApiCall<GetHomeVideo['Request'], GetHomeVideo['Response']>({
    request: async (_, token) => {
      const res: AxiosResponse<GetHomeVideo['Response']> = await api.get(
        '/video/home',
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível recuperar o vídeo da home',
    },
  });

export interface ITrainingVideo {
  id: string;
  title: string;
  description: string;
  link_url: string;
  is_available: boolean;
  is_active: boolean;
  category: string;
  picture: string;
  created_at: string;
  updated_at: string;
}

export type ITrainingVideoBody = Omit<
  ITrainingVideo,
  'is_available' | 'is_active' | 'created_at' | 'updated_at'
>;

export interface GetVideos {
  Request: AuthRequest & {
    filter_name?: string;
    afterCursor?: string;
    beforeCursor?: string;
  };

  Response: PaginatedResponse<ITrainingVideoBody>;
}

export const useGetVideos = () =>
  useApiCall<GetVideos['Request'], GetVideos['Response']>({
    request: async ({ afterCursor, beforeCursor, filter_name, token }) => {
      if (
        afterCursor === undefined &&
        beforeCursor === undefined &&
        filter_name === undefined
      ) {
        const res: AxiosResponse<GetVideos['Response']> = await api.get(
          '/video?get_all=true',
          getAuthHeader(token),
        );
        return res.data;
      }
      const res: AxiosResponse<GetVideos['Response']> = await api.get(
        `/video?filter_name=${filter_name}&afterCursor=${afterCursor}&beforeCursor=${beforeCursor}`,
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível recuperar os dados de venda',
    },
  });
export interface GetCategorizedVideos {
  Request: AuthRequest & {
    filter_name?: string;
    filter_category?: string;
    cursor: string | null;
  };

  Response: PaginatedResponse<ITrainingVideoBody>;
}

export const useGetCategorizedVideos = () =>
  useApiCall<GetCategorizedVideos['Request'], GetCategorizedVideos['Response']>(
    {
      request: async (
        { cursor, filter_name = '', filter_category = '' },
        token,
      ) => {
        const res: AxiosResponse<GetCategorizedVideos['Response']> =
          await api.get(
            ''.concat(
              '/video?',
              filter_name.length > 0 ? `filter_name=${filter_name}` : '',
              filter_category.length > 0
                ? `filter_category=${filter_category}`
                : '',
              cursor ? `&after_cursor=${cursor}` : '',
            ),
            getAuthHeader(token),
          );
        return res.data;
      },
      errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível recuperar os dados de venda',
      },
    },
  );

export const useGetCourses = () =>
  useApiCall<AuthRequest, ICourseBody[]>({
    request: async ({ token }) => {
      const res: AxiosResponse<ICourseBody[]> = await api.get(
        '/courses/get-all',
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível recuperar a lista de cursos',
    },
  });

export interface LinkGenerator {
  Request: {
    seller_id: string;
    date_of_expiry: string;
    student: {
      student_name: string;
    };
    course_id: string;
  };

  Response: {
    sale_link: string;
  };
}

export const useLinkGenerator = () =>
  useApiCall<LinkGenerator['Request'], LinkGenerator['Response']>({
    request: async (
      { date_of_expiry, seller_id, student, course_id },
      token,
    ) => {
      const res: AxiosResponse<LinkGenerator['Response']> = await api.post(
        '/sales/generate-sales-link',
        {
          date_of_expiry,
          seller_id,
          student,
          course_id,
        },
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível gerar o link de venda',
    },
  });

interface EditBankData {
  Request: IUser;
  Response: void;
}

export const useEditBankData = () =>
  useApiCall<EditBankData['Request'], AxiosResponse<EditBankData['Response']>>({
    request: async (
      {
        name,
        document_number,
        bank,
        bank_account_type,
        bank_agency,
        bank_account,
      },
      token,
    ) => {
      const res: AxiosResponse<EditBankData['Response']> = await api.put(
        '/profile/bank',
        {
          name,
          document_number,
          bank,
          bank_account_type,
          bank_agency,
          bank_account,
        },
        getAuthHeader(token),
      );
      return res;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível alterar seus dados, tente mais tarde',
    },
  });

export interface GetProfile {
  Request?: void;
  Response: IUser;
}

export const useGetProfile = () =>
  useApiCall<GetProfile['Request'], GetProfile['Response']>({
    request: async (_, token) => {
      const res: AxiosResponse = await api.get(
        '/profile',
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível recuperar os dados do usuário',
    },
  });

export interface GetSalesInfo {
  Request: {
    after_date?: string;
    before_date?: string;
  };

  Response: {
    sales: number;
    referrals: number;
  };
}

export const useGetSalesInfo = () =>
  useApiCall<GetSalesInfo['Request'], GetSalesInfo['Response']>({
    request: async ({ after_date, before_date }, token) => {
      const res: AxiosResponse<GetSalesInfo['Response']> = await api.get(
        `/sales/seller-info${after_date ? `?after_date=${after_date}` : ''}${
          before_date ? `&before_date=${before_date}` : ''
        }`,
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível recuperar os dados de venda',
    },
  });

export interface GetSalesRank {
  Request: void;

  Response: {
    current_week: Array<{
      rank: string;
      id: string;
      name: string;
      avatar?: string;
      city?: string;
      profile_class?: string;
      sales: string;
    }>;
    last_week: Array<{
      rank: string;
      id: string;
      name: string;
      avatar?: string;
      city?: string;
      profile_class?: string;
      sales: string;
    }>;
  };
}

export const useGetSalesRank = () =>
  useApiCall<GetSalesRank['Request'], GetSalesRank['Response']>({
    request: async (_, token) => {
      const res: AxiosResponse<GetSalesRank['Response']> = await api.get(
        '/sales/rank',
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível recuperar os dados do ranking',
    },
  });

interface GetFAQ {
  Request: {
    filter_name?: string;
  };
  Response: PaginatedResponse<IFaqBody>;
}

export const useGetFAQ = () =>
  useApiCall<GetFAQ['Request'], GetFAQ['Response']>({
    request: async ({ filter_name }, token) => {
      const { data }: AxiosResponse<GetFAQ['Response']> = await api.get(
        ''.concat('/FAQ?', filter_name ? `&filter_name=${filter_name}` : ''),
        getAuthHeader(token),
      );
      return data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível encontrar nenhuma pergunta',
    },
  });

interface GetSale {
  Request: {
    saleId: ISale['id'];
  };
  Response: ISale;
}

export const useGetCheckoutSale = () =>
  useApiCall<GetSale['Request'], GetSale['Response']>({
    request: async ({ saleId }, token) => {
      const { data }: AxiosResponse<GetSale['Response']> = await api.get(
        `/checkout/info/${saleId}`,
        getAuthHeader(token),
      );
      return data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível receber os dados da venda',
    },
  });

interface PutCheckout {
  Request: {
    sale_id: string;
    course: {
      course_id: string;
    };
    student: IStudent;
    payment: {
      card_mask?: string;
      payment_type: 'Crédito' | 'Boleto';
      credit_card?: {
        installments: number;
        payment_token: string;
        billing_address: {
          street: string;
          number: number;
          neighborhood: string;
          zipcode: string;
          city: string;
          state: string;
        };
      };
      customer: {
        name: string;
        email: string;
        cpf: string;
        birth?: string;
        phone_number: string;
      };
    };
  };
  Response: {
    code: number;
    data: {
      barcode: string;
      link: string;
      pdf: {
        charge: string;
      };
      expire_at: string;
      charge_id: number;
      status: string;
      total: number;
      payment: string;
    };
  };
}

export const usePutCreditCardCheckout = () =>
  useApiCall<PutCheckout['Request'], PutCheckout['Response']>({
    request: async payment => {
      const { data }: AxiosResponse<PutCheckout['Response']> = await api.put(
        '/checkout',
        payment,
      );

      return data;
    },
    errorMessage: 'Erro na conclusão do pagamento, tente outra vez',
    getDynamicErrorMessage: (error: AxiosError) => {
      if (error.isAxiosError) {
        const errorMessage: string = error.response?.data?.message;
        if (
          errorMessage &&
          errorMessage.startsWith('Erro ao efetuar pagamento:')
        ) {
          const toastMessage = errorMessage.split(':')[1].toUpperCase();
          return toastMessage;
        }
      }

      return '';
    },
  });

export interface Referred {
  id: string;
  name: string;
  email: string;
  phone_number: string;
  document_number: string;
  birth_date?: string;
  bank_completed_name?: string;
  bank_document_number?: string;
  bank?: string;
  bank_account_type?: string;
  bank_account?: string;
  bank_agency?: string;
  postal_code?: string;
  state?: string;
  city?: string;
  neighborhood?: string;
  street?: string;
  complement?: string;
  profile_role: string;
  profile_class?: string;
  status: string;
  avatar_url?: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

export interface IReferred {
  referred_id: string;
  avatar_url?: string;
  name: string;
  profile_class: string;
  referred_sales: string;
  referred_earnings: string;
  referrer_earnings_by_referred: number;
  email: string;
  phone_number: string;
}
export interface GetReferred {
  Request: {
    skip?: number;
    filter_name?: string;
  };
  Response: IReferred;
}

export const useGetReferred = () =>
  useApiCall<GetReferred['Request'], GetReferred['Response'][]>({
    request: async ({ skip = 0, filter_name }, token) => {
      const { data }: AxiosResponse<GetReferred['Response'][]> = await api.get(
        `/referrals/team?${skip > 0 ? `skip=${skip}&` : ''}${
          filter_name ? `filter_name=${filter_name}&` : ''
        }`,
        getAuthHeader(token),
      );
      return data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível receber os dados da sua equipe de vendas',
    },
  });

interface UpdateCheckoutStudent {
  Request: IStudent;
  Response: void;
}

export const usePutStudent = () =>
  useApiCall<
    UpdateCheckoutStudent['Request'],
    UpdateCheckoutStudent['Response']
  >({
    request: async student => {
      const student_name = student.name;

      const newStudent: Partial<IStudent> = { ...student };
      delete newStudent.is_active;
      delete newStudent.is_available;
      delete newStudent.login;
      delete newStudent.created_at;
      delete newStudent.updated_at;
      delete newStudent.name;

      const { data }: AxiosResponse<UpdateCheckoutStudent['Response']> =
        await api.put('/student', {
          ...newStudent,
          student_name,
        });

      return data;
    },
    errorMessage: 'Erro ao salvar os dados, tente outra vez',
  });

interface SendAvatar {
  Request: FormData;

  Response: GetProfile['Response'];
}

export const useSendAvatar = () =>
  useApiCall<SendAvatar['Request'], SendAvatar['Response']>({
    request: async (avatar, token) => {
      const res: AxiosResponse<SendAvatar['Response']> = await api.patch(
        '/users/avatar',
        avatar,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type':
              'multipart/form-data; boundary=----WebKitFormBoundarykwHBzgMlGtFQIIni',
          },
        },

        // getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro no upload',
      description: 'Não foi possível enviar seu arquivo',
    },
  });

export interface GetMySales {
  Request: {
    filter_course_name: string;
    paid: boolean | null;
    after_date: string;
    before_date: string;
    cursor: string | null;
  };
  Response: PaginatedResponse<ISale>;
}

export const useGetMySales = () =>
  useApiCall<GetMySales['Request'], GetMySales['Response']>({
    request: async (
      { filter_course_name, paid, after_date, before_date, cursor },
      token,
    ) => {
      const res: AxiosResponse<GetMySales['Response']> = await api.get(
        ''.concat(
          '/sales/me?',
          `paid=${paid}`,
          filter_course_name.length > 0
            ? `filter_course_name=${filter_course_name}`
            : '',
          `&after_date=${after_date}`,
          `&before_date=${before_date}`,
          cursor ? `&after_cursor=${cursor}` : '',
        ),
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível receber os dados de suas vendas',
    },
  });

interface UpdateCheckoutStudent {
  Request: IStudent;
  Response: void;
}

export interface GetReferrer {
  Request: {
    seller_hash: string;
  };
  Response: {
    name: string;
    avatar_url?: string;
    referrer_id: string;
  };
}

export const useGetReferrer = () =>
  useApiCall<GetReferrer['Request'], GetReferrer['Response']>({
    request: async ({ seller_hash }, token) => {
      const res: AxiosResponse<GetReferrer['Response']> = await api.get(
        `/referrals/referrer?hash=${seller_hash}`,
        getAuthHeader(token),
      );
      return res.data;
    },
    disableAlert: true,
  });
export interface ICommissions {
  origin: string;
  status_date: string;
  value: number;
  sale: {
    student: {
      name?: string;
    };
    user: {
      name?: string;
    };
  };
}
export interface GetCommissions {
  Request: {
    filter_name?: string;
    filter_commission_type?: string;
    after_date: string;
    before_date: string;
    cursor: string | null;
  };
  Response: PaginatedResponse<ICommissions>;
}

export const useGetCommissions = () =>
  useApiCall<GetCommissions['Request'], GetCommissions['Response']>({
    request: async (
      {
        filter_name = '',
        filter_commission_type = '',
        after_date,
        before_date,
        cursor,
      },
      token,
    ) => {
      const res: AxiosResponse<GetCommissions['Response']> = await api.get(
        ''.concat(
          '/financial/me/commissions?',
          filter_name.length > 0 ? `filter_name=${filter_name}` : '',
          filter_commission_type.length > 0
            ? `filter_commission_type=${filter_commission_type}`
            : '',
          `&after_date=${after_date}`,
          `&before_date=${before_date}`,
          cursor ? `&after_cursor=${cursor}` : '',
        ),
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível receber os dados de suas comissões',
    },
  });

export interface GetPaymentHistory {
  Request: void;
  Response: {
    mon: string;
    payment_date: string;
    sum: number;
  };
}

export const useGetPaymentHistory = () =>
  useApiCall<GetPaymentHistory['Request'], GetPaymentHistory['Response'][]>({
    request: async (_, token) => {
      const res: AxiosResponse<GetPaymentHistory['Response'][]> = await api.get(
        '/financial/me/payment-history',
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description:
        'Não foi possível receber os dados do seu histórico de pagamentos',
    },
  });

export interface GetBalance {
  Request: void;
  Response: {
    to_receiving: number;
    current_month_balance: number;
    closing_date: string;
  };
}

export const useGetBalance = () =>
  useApiCall<GetBalance['Request'], GetBalance['Response']>({
    request: async (_, token) => {
      const res: AxiosResponse<GetBalance['Response']> = await api.get(
        '/financial/me/balance',
        getAuthHeader(token),
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível receber os dados do seu saldo',
    },
  });
interface StudentAgreement {
  Request: {
    sale_id: string;
    adherence_agreement: boolean;
  };
  Response: void;
}

export const useStudentAgreement = () =>
  useApiCall<
    StudentAgreement['Request'],
    AxiosResponse<StudentAgreement['Response']>
  >({
    request: async ({ sale_id, adherence_agreement }, token) => {
      const res: AxiosResponse<StudentAgreement['Response']> = await api.put(
        '/sales/adherence-agreement',
        {
          sale_id,
          adherence_agreement,
        },
        getAuthHeader(token),
      );
      return res;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description:
        'Não foi possível enviar o aceite de termos, tente mais tarde',
    },
  });
