import styled from 'styled-components';
import { shade } from 'polished';

import sessionBackground from '../../Assets/sessionBackground.jpg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  width: 100%;
  align-items: center;
  max-width: 700px;
  background: #141b23;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;
  }
  h1 {
    margin-bottom: 24px;
    color: #ffff;
    font-family: Object Sans;
  }

  button {
    background: #296394;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #312e38;
    width: 100%;
    font-weight: 500;
    margin-top: 16px;

    &:hover {
      background: ${shade(0.2, '#296394')};
      cursor: pointer;
    }
  }
  a {
    color: #ffff;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    font-size: large;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#ffff')};
      cursor: pointer;
    }
  }
  > a {
    color: #296394;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;

    svg {
      margin-right: 16px;
    }
    &:hover {
      color: ${shade(0.2, '#296394')};
      cursor: pointer;
    }
  }
`;
export const Background = styled.div`
  flex: 1;
  background: url(${sessionBackground}) no-repeat right;
  background-color: #fbfbfb;
  background-size: contain;
  @media (max-width: 1110px) {
    background: #141b23 no-repeat right;
  }
`;
