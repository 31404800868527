import styled from 'styled-components';

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  padding: 46px 64px 24px 64px;
  @media (max-width: 530px) {
    display: flex;
    flex-direction: column;

    padding: 32px;
  }
  @media (max-width: 530px) {
    padding: 32px 24px;
  }
  label {
    display: block;

    color: #fff;
    font-size: 12px;
    font-weight: 800;
    line-height: 12px;

    margin-bottom: 6px;
  }
  .course-disabled {
    background-color: var(--black-background);
    color: white;
  }
  select {
    background: #fff;
    border: solid 1px #eee;
    border-radius: 10px;
    font-size: 12px;

    display: block;

    width: 100%;
    height: 26px;
    padding: 4px;
    margin-bottom: 12px;
    transition: 0.2s;
    &::placeholder {
      font-size: 14px;
    }
    &:hover,
    &:focus {
      background-color: #fff;
      border: 1px solid var(--primary-color);
      box-shadow: 0 0 0 1px var(--primary-color);
      outline: none;
    }
  }
  #button-register {
    display: flex;
    justify-content: flex-end;
    button {
      background: var(--primary-color);
      border: 1px solid var(--primary-color);
      border-radius: 10px;
      color: var(--black-background);

      font-weight: 800;
      font-size: 16px;
      line-height: 17px;

      width: 200px;
      padding: 4px;
      margin-top: 16px;
      cursor: pointer;
    }
  }
`;
