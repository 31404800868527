/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import Input from '../../../components/Input';

import boleto from '../../../Assets/icons/code.svg';

import { Form } from './styles';
import { useToast } from '../../../hooks/toast';

import { getCurrencyMask } from '../../../utils/currencyFormatter';
import { phoneNumberMask, documentNumberMask } from '../../../utils/masks';
import { useFullscreenLoading } from '../../../hooks/fullscreenLoading';
import { useSaleCheckout } from '../../../hooks/saleCheckout';
import { useLoadingBar } from '../../../hooks/loadingBar';
import { usePutCreditCardCheckout } from '../../../services/api';
import { IStudent } from '../../../models/Student';

interface IBilletData {
  name: string;
  phone: string;
  email: string;
  email2: string;
  cpf: string;
}

const data = {
  name: '',
  phone: '',
  email: '',
  email2: '',
  cpf: '',
};

const Billet: React.FC = () => {
  const history = useHistory();

  const { showFullscreenMessage, eraseFullscreenMessage } =
    useFullscreenLoading();
  const { saleCheckout, setSaleCheckout } = useSaleCheckout();
  const { startBarLoading, completeBarLoading } = useLoadingBar();
  const { addToast } = useToast();
  const putCreditCardCheckout = usePutCreditCardCheckout();

  const [checkbox, setCheckbox] = useState(true);
  const [dummyData, setDummyData] = useState<IBilletData>({
    ...data,
    name: saleCheckout?.student.name || '',
  });
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
  const chargeLinkEl = useRef<HTMLAnchorElement>(null);
  const [chargeDocumentLink, setChargeDocumentLink] = useState<string>('');

  useEffect(() => {
    if (chargeDocumentLink.length) {
      console.log(
        '🚀 ~ file: billet.tsx ~ line 60 ~ useEffect ~ chargeDocumentLink',
        chargeDocumentLink,
      );
      chargeLinkEl.current?.click();
    }
  }, [chargeDocumentLink]);

  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();

  //   const schema = yup.object().shape({
  //     name: yup
  //       .string()
  //       .required('O nome é obrigatório')
  //       .min(5, 'Nome deve ter no mínimo 5 caracteres'),
  //     phone: yup
  //       .string()
  //       .required('Insira o número do seu telefone')
  //       .matches(
  //         /^\(\d{2}\) \d{4,5}-\d{4}$/,
  //         'Telefone deve seguir a seguinte regra "(XX) XXXXX-XXXX',
  //       ),
  //     email: yup
  //       .string()
  //       .required('Insira o seu email')
  //       .email('Email inválido'),
  //     email2: yup
  //       .string()
  //       .required('Insira o email de confirmação')
  //       .oneOf([yup.ref('email')], 'Os emails não são iguais'),
  //     cpf: yup
  //       .string()
  //       .typeError('CPF/CNPJ inválido')
  //       .required('CPF/CNPJ é obrigatório')
  //       .matches(
  //         /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
  //         'CPF/CNPJ deve seguir a seguinte regra "XXX.XXX.XXX-XX"/"XX.XXX.XXX/XXXX-XX"',
  //       ),
  //   });

  //   try {
  //     await schema.validate(dummyData, {
  //       abortEarly: false,
  //     });

  //     startBarLoading();
  //     setIsPaymentLoading(true);
  //     showFullscreenMessage('Processando pagamento...');

  //     try {
  //       const payment = {
  //         payment_type: 'Boleto' as 'Crédito' | 'Boleto',
  //         customer: {
  //           name: dummyData.name,
  //           email: dummyData.email,
  //           cpf: dummyData.cpf
  //             .replace(/\./g, '')
  //             .replace('-', ''),
  //           phone_number: dummyData.phone
  //             .replace(' ', '')
  //             .replace('-', '')
  //             .replace('(', '')
  //             .replace(')', ''),
  //         },
  //       };

  //       const apiRequest = {
  //         // from context
  //         sale_id: saleCheckout?.id as string,
  //         course: {
  //           course_id: saleCheckout?.course.id as string,
  //         },
  //         student: {
  //           id: saleCheckout?.student.id,
  //           name: dummyData.name,
  //           phone_number: dummyData.phone,
  //           email: dummyData.email,
  //           email_confirmation: dummyData.email2,
  //           document_number: dummyData.cpf,
  //         } as IStudent,
  //         //
  //         payment,
  //       };

  //       const res = await putCreditCardCheckout(apiRequest);

  //       setChargeDocumentLink(res.data.pdf.charge);

  //       /* const link = document.createElement('a');
  //       const [isoDate] = (new Date()).toISOString().split('T');
  //       link.download = isoDate;
  //       link.href = res.data.pdf.charge;
  //       link.addEventListener('click', () => {
  //         window.open(res.data.pdf.charge);
  //       });
  //       link.click(); */

  //       addToast({
  //         type: 'success',
  //         title: 'Sucesso!',
  //         description: 'Pagamento realizado',
  //       });

  //       setSaleCheckout((sc) => sc && ({
  //         ...sc,
  //         student: {
  //           ...sc.student,
  //           name: dummyData.name,
  //           email: dummyData.email,
  //           document_number: dummyData.cpf,
  //           phone_number: dummyData.phone,
  //         },
  //       }));

  //       history.push('/checkout/cadastro');
  //     } catch (error) {
  //       console.log('Erro Pag Back', { error });
  //     } finally {
  //       completeBarLoading();
  //       setIsPaymentLoading(false);
  //       eraseFullscreenMessage();
  //     }
  //   } catch (error) {
  //     // console.log('Deu ruim');
  //     if (error instanceof yup.ValidationError) {
  //       // console.log('Deu ruim com toast');
  //       const firstErrorMessage = error.errors[0];
  //       addToast({
  //         type: 'error',
  //         title: 'Erro nos dados informados',
  //         description: firstErrorMessage,
  //       });
  //     }
  //   }
  // };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
      phone: yup
        .string()
        .required('Insira o número do seu telefone')
        .matches(
          /^\(\d{2}\) \d{4,5}-\d{4}$/,
          'Telefone deve seguir a seguinte regra "(XX) XXXXX-XXXX',
        ),
      email: yup
        .string()
        .required('Insira o seu email')
        .email('Email inválido'),
      email2: yup
        .string()
        .required('Insira o email de confirmação')
        .oneOf([yup.ref('email')], 'Os emails não são iguais'),
      cpf: yup
        .string()
        .typeError('CPF/CNPJ inválido')
        .required('CPF/CNPJ é obrigatório')
        .matches(
          /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
          'CPF/CNPJ deve seguir a seguinte regra "XXX.XXX.XXX-XX"/"XX.XXX.XXX/XXXX-XX"',
        ),
    });
    try {
      await schema.validate(dummyData, {
        abortEarly: false,
      });
      history.push('/checkout/termos', {
        cpf: dummyData.cpf,
        email: dummyData.email,
        email2: dummyData.email2,
        name: dummyData.name,
        phone: dummyData.phone,
        saleId: saleCheckout?.id,
        courseId: saleCheckout?.course_id,
      });
    } catch (error) {
      // console.log('Deu ruim');
      if (error instanceof yup.ValidationError) {
        // console.log('Deu ruim com toast');
        const firstErrorMessage = error.errors[0];
        addToast({
          type: 'error',
          title: 'Erro nos dados informados',
          description: firstErrorMessage,
        });
      }
    }
  };

  return (
    <>
      <a
        ref={chargeLinkEl}
        href={chargeDocumentLink}
        target="_blank"
        rel="noreferrer"
        hidden
      >
        &nbsp;
      </a>
      <Form onSubmit={handleSubmit}>
        <div>
          <Input
            label="Nome completo"
            type="text"
            name="name"
            placeholder="Nome e Sobrenome"
            value={dummyData.name}
            onChange={e => setDummyData({ ...dummyData, name: e.target.value })}
          />
          <Input
            label="Telefone"
            type="text"
            name="phone"
            placeholder="(99) 99999-9999"
            value={dummyData.phone}
            onChange={e =>
              setDummyData({
                ...dummyData,
                phone: phoneNumberMask(e.target.value),
              })
            }
          />
          <Input
            label="E-mail"
            type="email"
            name="email"
            placeholder="email@email.com"
            value={dummyData.email}
            onChange={e =>
              setDummyData({ ...dummyData, email: e.target.value })
            }
          />
          <Input
            label="Confirme seu e-mail"
            type="email"
            name="confirm-email"
            placeholder="email@email.com"
            value={dummyData.email2}
            onChange={e =>
              setDummyData({ ...dummyData, email2: e.target.value })
            }
          />
          <div className="cpf">
            <Input
              label="CPF"
              type="string"
              name="cpf"
              placeholder="Somente números"
              value={dummyData.cpf}
              onChange={e =>
                setDummyData({
                  ...dummyData,
                  cpf: documentNumberMask(e.target.value, {
                    allowCompany: false,
                  }),
                })
              }
            />
          </div>
        </div>
        <div className="info-boleto">
          <p>Boleto (somente à vista)</p>
          <p>
            Pagamentos com Boleto Bancário levam até 3 dias úteis para serem
            compensados e então terem os produtos liberados
          </p>
          <p>
            Atente-se ao vencimento do boleto. Você pode pagar o boleto em
            qualquer banco ou casa lotérica até o dia do vencimento
          </p>
        </div>
        <div className="card">
          <img src={boleto} alt="boleto" />
          <div className="checked-container">
            <div className="checkbox">
              <input
                type="checkbox"
                name="termos"
                id="terms-checked"
                onChange={e => {
                  if (e.target.checked) {
                    setCheckbox(false);
                  } else {
                    setCheckbox(true);
                  }
                }}
              />
              <label htmlFor="terms-checked">
                Declaro ser elegível a cursar graduação/pós conforme diretrizes
                da LDB/MEC (Nº 9.394,Cap IV - Art.nº44/I/III)
              </label>
            </div>
            <button type="submit" disabled={isPaymentLoading || checkbox}>
              Gerar Boleto
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default Billet;
