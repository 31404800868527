import styled from 'styled-components';

export const Background = styled.div`
  background-color: var(--black-background);
  color: #fff;

  max-width: 100vw;
  min-height: 100vh;
  padding: 40px 0;
  #logo {
    display: block;
    margin: 0 auto;
  }
  #help {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: var(--black-background);
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    position: fixed;
    right: 10px;
    bottom: 40px;
    padding: 4px 20px;
    img {
      margin-right: 4px;
    }
  }
`;

export const Container = styled.main`
  #confirm {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    color: var(--black-background);

    font-weight: 800;
    font-size: 16px;
    line-height: 17px;

    width: 200px;
    padding: 4px;
    cursor: pointer;
    @media (max-width: 420px) {
      width: 140px;
    }
    @media (max-width: 380px) {
      max-width: 120px;
    }
    @media (max-width: 368px) {
      max-width: 110px;
    }
    @media (max-width: 350px) {
      max-width: 90px;
    }
    &:disabled {
      color: white;
      border-color: grey;
      background-color: grey;
    }
  }

  .container-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 44px 22px;
    @media (max-width: 901px) {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 420px) {
      display: flex;
    }
    div {
      @media (max-width: 901px) {
        margin-bottom: 20px;
      }
    }
    input {
      width: 16px;
      height: 16px;
    }
    input[type='checkbox']:checked {
      background-color: #fff;
      color: var(--primary-color);
      width: 16px;
      height: 16px;
    }
    label {
      font-weight: 800;
      font-size: 21px;
      line-height: 22px;

      letter-spacing: 0.05em;
      margin-left: 8px;
      span {
        color: var(--primary-color);
      }
      @media (max-width: 420px) {
        font-size: 16px;
        line-height: 16px;
      }
      @media (max-width: 380px) {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
`;
export const ModalContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: visible;
  background-color: var(--black-background);
  justify-content: space-between;
  height: 20rem;
`;

export const Term = styled.div`
  margin: 22px 44px 44px 44px;
  @media (max-width: 601px) {
    margin: 16px;
  }
  display: flex;
  flex-direction: column;
  height: 450px;

  #scroll-to-bottom {
    z-index: 99;
    margin: 0 auto;
    margin-top: -8%;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    color: var(--black-background);

    width: 50px;
    padding: 4px;
    cursor: pointer;
    @media (max-width: 420px) {
      width: 140px;
    }
    @media (max-width: 380px) {
      max-width: 120px;
    }
    @media (max-width: 368px) {
      max-width: 110px;
    }
    @media (max-width: 350px) {
      max-width: 90px;
    }
  }
  div {
    border: 5px solid #ffffff;
    border-radius: 20px;
    border-radius: 20px;
    height: 400px;
    padding: 16px 22px;
    margin: 22px 0;
    overflow-y: auto;
    ::-webkit-scrollbar-track {
      background-color: #c4c4c4;
      border-radius: 20px;
    }
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      padding: 10px 0;
    }
    ::-webkit-scrollbar-thumb {
      background: #646464;
      border-radius: 20px;
    }
  }
  p {
    font-size: 21px;
    line-height: 24px;
    margin-bottom: 21px;
    font-weight: 800;
    letter-spacing: 0.05em;
    @media (max-width: 601px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (max-width: 380px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
