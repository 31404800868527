import React from 'react';
import { NavLink } from 'react-router-dom';

import home from '../../../Assets/icons/Home.svg';
import dinheiro from '../../../Assets/icons/dinheiro.svg';
import capelo from '../../../Assets/icons/capelo.svg';
import vendas from '../../../Assets/icons/vendas.svg';
import duvidas from '../../../Assets/icons/duvidas.svg';
import cumprimento from '../../../Assets/icons/cumprimento.svg';
import trophy from '../../../Assets/icons/trofeu.svg';
import userIcon from '../../../Assets/icons/user.svg';
import ranking from '../../../Assets/pages/ranking.svg';
import contrato from '../../../Assets/icons/contract.svg';

import { Nav } from './styles';

interface IMenuProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Menu: React.FC<IMenuProps> = ({ open, setOpen }) => (
  <Nav open={open}>
    <NavLink
      to="/home"
      activeClassName="active"
      className="nav-link"
      onClick={() => setOpen(!open)}
    >
      <img src={home} alt="Home" />
      <span>Home</span>
    </NavLink>
    <NavLink
      to="/ranking"
      activeClassName="active"
      className="nav-link"
      onClick={() => setOpen(!open)}
    >
      <img src={trophy} alt="Ranking" />
      <span>Ranking</span>
    </NavLink>
    <NavLink
      to="/admin"
      activeClassName="active"
      className="nav-link"
      onClick={() => setOpen(!open)}
    >
      <img src={dinheiro} alt="Administrativo" />
      <span>Financeiro</span>
    </NavLink>
    <NavLink
      to="/treinamento"
      activeClassName="active"
      className="nav-link"
      onClick={() => setOpen(!open)}
    >
      <img src={capelo} alt="Treinamento" />
      <span>Treinamento</span>
    </NavLink>
    <NavLink
      to="/team"
      activeClassName="active"
      className="nav-link"
      onClick={() => setOpen(!open)}
    >
      <img src={vendas} alt="Time de Vendas" />
      <span>Equipe de Vendas</span>
    </NavLink>
    <NavLink
      to="/minhas-vendas"
      activeClassName="active"
      className="nav-link"
      onClick={() => setOpen(!open)}
    >
      <img src={cumprimento} alt="Minhas vendas" style={{ maxWidth: '30px' }} />
      <span style={{ marginLeft: '2px' }}>Minhas Vendas</span>
    </NavLink>
    <NavLink
      to="/profile"
      activeClassName="active"
      className="nav-link"
      onClick={() => setOpen(!open)}
    >
      <img src={userIcon} alt="Conta do usuário" />
      <span>Minha Conta</span>
    </NavLink>
    <NavLink
      to="/level"
      activeClassName="active"
      className="nav-link"
      onClick={() => setOpen(!open)}
    >
      <img src={ranking} alt="Conta do usuário" />
      <span>Meu nível</span>
    </NavLink>

    <div className="duvidas">
      <NavLink
        to="/duvidas-tecnicas"
        activeClassName="active"
        className="nav-link"
        onClick={() => setOpen(!open)}
      >
        <img src={duvidas} alt="Dúvidas Técnicas" />
        <span>Preciso de ajuda!</span>
      </NavLink>
      <NavLink
        to="/contrato"
        activeClassName="active"
        className="nav-link"
        onClick={() => setOpen(!open)}
      >
        <img src={contrato} alt="Termo de adesão" />
        <span>Termo de adesão</span>
      </NavLink>
    </div>
  </Nav>
);

export default Menu;
