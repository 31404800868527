/* eslint-disable no-shadow */
export enum ProfileRoleEnum {
  seller = 'Seller',
  admin = 'Admin',
}

export enum ProfileClassEnum {
  trainee = 'Trainee',
  junior = 'Junior',
  pleno = 'Pleno',
  master = 'Master',
  supervisor = 'M. Supervisor',
}

export enum ProfileStatusEnum {
  pending = 'Pending',
  approved = 'Approved',
  reject = 'Reject',
}

export interface IUser {
  id: string;
  name: string;
  email: string;
  password: string;
  phone_number: string;
  document_number: string;
  birth_date: string;
  bank_completed_name?: string;
  bank_document_number?: string;
  bank?: string;
  bank_account_type?: string;
  bank_account?: string;
  bank_agency?: string;
  postal_code?: string;
  state?: string;
  city?: string;
  neighborhood?: string;
  street?: string;
  complement?: string;
  profile_role: ProfileRoleEnum;
  profile_class: ProfileClassEnum;
  status: string;
  seller_hash: string;
  seller_link: string;
  adherence_agreement: boolean;
  avatar_url?: string;
  created_at: string;
  updated_at: string;
  referred_by: string;
}

export type ILocalStorageUser = Pick<
  IUser,
  | 'email'
  | 'name'
  | 'profile_class'
  | 'profile_role'
  | 'id'
  | 'avatar_url'
  | 'seller_link'
>;
