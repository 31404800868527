/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { formatISO } from 'date-fns';
import SkeletonLoader from '../../../components/SkeletonLoader';
import { useLoadingBar } from '../../../hooks/loadingBar';
import { useToast } from '../../../hooks/toast';

import Intro from '../../../components/Intro';
import Avatar from '../../../components/Avatar';

import vendas from '../../../Assets/icons/vendas.svg';
import fotoModal from '../../../Assets/foto-modal.png';
import copy from '../../../Assets/icons/copy.svg';
import copyGreen from '../../../Assets/icons/copy-green.svg';

import { TeamContainer, SalesTeam, Table, Modal } from './styles';
import {
  GetReferred as IGetReferred,
  IReferred,
  useGetReferred,
} from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import useDataPaginator from '../../../hooks/dataPaginatorSkip';
import { getCurrencyMask } from '../../../utils/currencyFormatter';
import useDebouncedText from '../../../hooks/debouncedText';

const Team: React.FC = () => {
  const getReferred = useGetReferred();
  const { addToast } = useToast();

  const { user } = useAuth();

  const { isBarLoading } = useLoadingBar();

  const [sellerData, setSellerData] = useState<IReferred | undefined>();
  const [isShow, setIsShow] = useState(false);
  const [filterName, setNameFilter] = useDebouncedText('');
  const [isClick, setIsClick] = useState(true);

  const [data, , dataListLoading, checkDataListBottom] = useDataPaginator<
    IReferred,
    [string]
  >({
    filters: [filterName],
    storeNewData: async (filters, skip) => {
      const res = await getReferred({
        skip,
        filter_name: filters[0],
      });
      console.log('---<<>>', res);
      return {
        nextSkipCounter: skip + res.length,
        hasReachedEnd: res.length === 0,
        nextData: res,
      };
    },
  });

  function handleShow(value: IGetReferred['Response']) {
    if (value) {
      setSellerData({
        referred_id: value.referred_id,
        avatar_url: value.avatar_url,
        name: value.name,
        profile_class: value.profile_class,
        referred_sales: value.referred_sales,
        referred_earnings: value.referred_earnings,
        referrer_earnings_by_referred: value.referrer_earnings_by_referred,
        phone_number: value.phone_number,
        email: value.email,
      });
      setIsShow(true);
    }
  }

  const handleCopyLink = () => {
    if (user.seller_link) {
      setIsClick(false);
      addToast({
        type: 'success',
        title: '',
        description: 'Link copiado!',
      });
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        navigator.share({
          title: document.title,
          text: 'WUP Educacional',
          url: user.seller_link,
        });
      }
    }
    if (!user.seller_link) {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro',
        description: 'Não há link disponível',
      });
    }
  };

  return (
    <TeamContainer>
      <Intro
        image={vendas}
        name="Equipe de Vendas"
        text="Acompanhe o resultado da sua equipe de vendas"
      />

      <SalesTeam>
        <h1>Resultado Geral</h1>
        <div className="search-and-copy-clipboard">
          <form>
            <input
              placeholder=" Nome "
              onChange={e => setNameFilter(e.target.value)}
            />
            <input type="submit" hidden />
          </form>
          <div className="copy-clipboard">
            <span>
              Copie o link e envie para cadastrar um vendedor no seu time
            </span>
            <div>
              <input readOnly type="text" value={user.seller_link} />
              <CopyToClipboard text={user.seller_link}>
                <button
                  type="button"
                  onClick={handleCopyLink}
                  style={{
                    background: isClick
                      ? 'var(--primary-color)'
                      : 'var(--black-background)',
                  }}
                >
                  {isClick ? (
                    <img src={copy} alt="copy" />
                  ) : (
                    <img src={copyGreen} alt="copyGreen" />
                  )}
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>

        <Table onScroll={checkDataListBottom}>
          <thead>
            <tr>
              <th className="center">#</th>
              <th className="center">Nome</th>
              <th className="center">Nível</th>
              <th className="center">Vendas</th>
              <th className="center">Comissões</th>
            </tr>
          </thead>
          <tbody>
            <>
              {data ? (
                data.map(info => (
                  <>
                    <tr onClick={() => handleShow(info)}>
                      <td className="avatar">
                        <Avatar
                          avatar_url={info.avatar_url || ''}
                          widthAndHeightPicture="3"
                          marginTop="0"
                        />
                      </td>
                      <td className="left">{info.name}</td>
                      <td className={`center level ${info.profile_class}`}>
                        {info.profile_class}
                      </td>
                      <td className="center">{info.referred_sales}</td>
                      <td className="center">
                        {getCurrencyMask(info.referrer_earnings_by_referred)}
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <span>
                  {' '}
                  Não encontramos nenhum vendedor, comece a cadastrar
                </span>
              )}

              {dataListLoading && (
                <>
                  <SkeletonLoader
                    className="rank"
                    style={{
                      minWidth: '50rem',
                      height: '5rem',
                      backgroundColor: 'white',
                    }}
                  />
                </>
              )}
            </>
          </tbody>
        </Table>
      </SalesTeam>

      <Modal isShow={isShow} className="animeTop">
        {sellerData ? (
          <div className="modal-container">
            <div className="grid-photo">
              <Avatar
                avatar_url={sellerData.avatar_url}
                widthAndHeightPicture="20"
                marginTop="0"
              />
              <h3>{sellerData.profile_class}</h3>
            </div>
            <div className="personal-data">
              <div>
                <label>Nome</label>
                <p>{sellerData.name}</p>
              </div>
              <div>
                <label>Email</label>
                <p>{sellerData.email}</p>
              </div>
              <div>
                <label>Telefone</label>
                <p>{sellerData.phone_number}</p>
              </div>
            </div>
            <button type="button" id="close" onClick={() => setIsShow(false)}>
              Voltar
            </button>
          </div>
        ) : (
          <span>Erro</span>
        )}
      </Modal>
    </TeamContainer>
  );
};

export default Team;
