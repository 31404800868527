import React, { useEffect, useState } from 'react';
import { formatISO, format } from 'date-fns';
import SkeletonLoader from '../../../components/SkeletonLoader';

import DatePicker from '../../../components/DatePicker';
import Tooltip from '../../../components/Tooltip';

import { ICommissions, useGetCommissions } from '../../../services/api';
import { getCurrencyMask } from '../../../utils/currencyFormatter';

import { CommissionsContainer, Table } from './styles';
import useDataPaginator from '../../../hooks/dataPaginator';
import useDebouncedText from '../../../hooks/debouncedText';
import { useLoadingBar } from '../../../hooks/loadingBar';
import CommissionEnum from '../../../enum/CommissionEnum';

interface ICommissionProps {
  isSelect: boolean;
}

const Commissions: React.FC<ICommissionProps> = ({ isSelect }) => {
  const getCommissions = useGetCommissions();
  const { isBarLoading } = useLoadingBar();

  const [filter_name, setFilterName] = useDebouncedText('');
  const [filter_commission_type, setFilterType] = useState('');
  const [dateRange, setDateRange] = useState<Date[]>([
    new Date(new Date().setDate(new Date().getDate() - 30)),
    new Date(new Date().setDate(new Date().getDate() + 1)),
  ]);
  const [data, setData, dataListLoading, checkDataListBottom] =
    useDataPaginator<ICommissions, [Date[], string, string]>({
      filters: [dateRange, filter_commission_type, filter_name],
      storeNewData: async (filters, cursor) => {
        console.log(cursor);
        const res = await getCommissions({
          cursor: cursor as string,
          filter_commission_type: filters[1],
          filter_name: filters[2],
          after_date: formatISO(filters[0][0], { representation: 'date' }),
          before_date: formatISO(filters[0][1], { representation: 'date' }),
        });
        console.log('---<<>>', res);
        return {
          nextCursor: res.cursor.afterCursor,
          shouldResetData: !res.cursor.beforeCursor,
          nextData: res.data,
        };
      },
    });

  return (
    <CommissionsContainer>
      <div className="container-buttons">
        <input
          onChange={e => setFilterName(e.target.value)}
          placeholder=" Nome "
          className="name-search"
        />
        <select
          defaultValue=""
          name="type-filter"
          onChange={e => setFilterType(e.target.value)}
        >
          <option value="">Todos</option>
          <option value="Direct Selling">Venda Direta</option>
          <option value="Indirect Selling">Venda Indireta</option>
          <option value="Direct Recurrence">Recorrência Direta</option>
          <option value="Indirect Recurrence">Recorrência Indireta</option>
          <option value="Debut">Debute</option>
        </select>
        <DatePicker
          dateRange={dateRange}
          setDateRange={setDateRange}
          heightLarge
        />
      </div>
      <Table onScroll={checkDataListBottom} isSelect={isSelect}>
        <thead>
          <tr>
            <th className="tooltip">
              Nome
              <Tooltip isMultinivelScreen={false}>
                <p>Venda Direta = Aluno</p>
                <p>Venda Indireta = Vendedor</p>

                <p>Rec. Direta = Aluno</p>

                <p>Rec Indireta = Vendedor</p>
                <p>Debute = Primeira Venda</p>
              </Tooltip>
            </th>
            <th>Comissão</th>
            <th>Pagamento</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {isBarLoading ? (
            <>
              <tr style={{ display: 'flex', flexDirection: 'column' }}>
                <SkeletonLoader
                  style={{
                    minWidth: '50rem',
                    height: '5rem',
                    backgroundColor: 'white',
                  }}
                />
                <SkeletonLoader
                  style={{
                    minWidth: '50rem',
                    height: '5rem',
                    backgroundColor: 'white',
                  }}
                />
                <SkeletonLoader
                  style={{
                    minWidth: '50rem',
                    height: '5rem',
                    backgroundColor: 'white',
                  }}
                />
              </tr>
            </>
          ) : (
            <>
              {data.length ? (
                data.map(info => {
                  const origin = (i: string) => {
                    if (i === CommissionEnum.DirectRecurrence) {
                      return 'Recorrência Direta';
                    }
                    if (i === CommissionEnum.IndirectRecurrence) {
                      return 'Recorrência Indireta';
                    }
                    if (i === CommissionEnum.DirectSelling) {
                      return 'Venda Direta';
                    }
                    if (i === CommissionEnum.IndirectSelling) {
                      return 'Venda Indireta';
                      // eslint-disable-next-line max-len
                    }
                    if (
                      i === CommissionEnum.DirectDebut ||
                      i === CommissionEnum.IndirectDebut
                    ) {
                      return 'Debute';
                    }
                    return 'Erro';
                  };
                  return (
                    <tr>
                      <td>
                        {info.sale.student?.name || info.sale.user?.name || ''}
                      </td>
                      <td>{origin(info.origin)}</td>
                      <td>
                        {format(new Date(info.status_date), 'dd/MM/yyyy')}
                      </td>
                      <td>{getCurrencyMask(info.value)}</td>
                    </tr>
                  );
                })
              ) : (
                <span>
                  Não encontramos nenhuma comissão, por favor volte mais tarde
                </span>
              )}
            </>
          )}
        </tbody>
      </Table>
      <div className="total-value">
        {data && (
          <span>
            {getCurrencyMask(data.reduce((prev, cur) => prev + cur.value, 0))}
          </span>
        )}
      </div>
    </CommissionsContainer>
  );
};

export default Commissions;
