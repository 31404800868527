import styled from 'styled-components';

export const RecoveryContainer = styled.div`
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 100vw;
  min-height: 100vh;
  img {
    margin-bottom: 54px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    input {
      border: none;
      background: rgba(196, 196, 196, 0.2);
      border-radius: 15px;
      color: black;
      font-size: 14px;
      line-height: 14px;
      text-align: center;

      width: 330px;
      height: 54px;
      padding: 0 8px;
      margin-bottom: 8px;
      transition: 200ms;
      box-shadow: 0 0 0 2px black;

      &::placeholder {
        color: black;
        font-size: 15px;
        line-height: 15px;
        text-align: center;
      }
    }
    input:hover,
    input:focus {
      outline: none;
      box-shadow: 0 0 0 2px #296394;
    }
    button {
      border: none;
      background: var(--primary-color);
      border-radius: 37px;
      font-size: 14px;

      width: 271px;
      height: 54px;
      margin-top: 2px;
      :hover {
        cursor: pointer;
      }
    }
    @media (max-width: 350px) {
      input {
        width: 290px;
        height: 40px;
      }
      button {
        height: 40px;
      }
    }
  }
  #login {
    color: black;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    padding: 2rem;
  }
`;
