import React, { useState } from 'react';

import { TabContainer } from './styles';

interface ITabProps {
  tab1: string;
  tab2: string;
  isSelect: boolean;
  setIsSelect: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  checkout: boolean;
}

const Tab: React.FC<ITabProps> = ({
  tab1,
  tab2,
  isSelect,
  setIsSelect,
  className = undefined,
  checkout,
}: ITabProps) => {
  const [buttonA, setButtonA] = useState<'' | 'active'>('active');
  const [buttonB, setButtonB] = useState<'' | 'active'>('');

  function handleSelectOn() {
    setIsSelect(true);
    setButtonA('active');
    setButtonB('');
  }
  function handleSelectOff() {
    setIsSelect(false);
    setButtonA('');
    setButtonB('active');
  }

  return (
    <TabContainer className={className} checkout={checkout}>
      <button type="button" onClick={handleSelectOn} className={buttonA}>
        {tab1}
      </button>
      <button type="button" onClick={handleSelectOff} className={buttonB}>
        {tab2}
      </button>
    </TabContainer>
  );
};

export default Tab;
