import styled from 'styled-components';

export const LevelContainer = styled.div`
  padding: 0rem 8rem 2rem;
  max-width: 120rem;
  margin: 0px auto;
  @media (max-width: 991px) {
    padding: 0;
  }
`;

export const CarouselContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  max-width: 50rem;
  margin: 0 auto;
  @media (max-width: 330px) {
    width: 100vw;
    margin: 0;
  }
  div {
    display: flex;
    align-items: center;
    width: 30rem;
    height: 100%;
    @media (max-width: 600px) {
      width: 24rem;
    }
    @media (max-width: 500px) {
      width: 20rem;
    }
    @media (max-width: 400px) {
      width: 18rem;
    }
    @media (max-width: 370px) {
      width: 16rem;
    }
    button {
      background-color: #fff;
      border: none;

      padding: 0.8rem;
      margin-bottom: 12px;
    }
  }
  .class-name {
    background-color: #646464;
    border-radius: 2rem;
    color: #fff;

    padding: 0.6rem 1rem 0.3rem;
    @media (max-width: 370px) {
      font-size: 1.2rem !important;
      width: 7rem;
    }
  }
  #userClass {
    background-color: var(--primary-color);
    color: black;
  }
  span {
    background-color: #646464;
    color: #fff;
    border-radius: 2rem;
    font-size: 2.4rem;
    padding: 1rem 3.2rem 0.6rem;
    @media (max-width: 600px) {
      font-size: 2.2rem;
    }
    @media (max-width: 420px) {
      font-size: 2rem;
      padding: 0.8rem 2.8rem 0.3rem;
    }
    @media (max-width: 370px) {
      font-size: 1.8rem;
      padding: 0.6rem 2.4rem 0.1rem;
    }
  }
`;

export const ClassData = styled.div`
  background-color: #fff;
  border-radius: 2rem;
  color: var(--black-background);

  padding: 5.2rem;
  @media (max-width: 991px) {
    border-radius: 0;
  }
  @media (max-width: 420px) {
    padding: 3.2rem;
  }
  @media (max-width: 330px) {
    padding: 1.6rem 0.4rem;
  }
  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-weight: 800;
      font-size: 3rem;
      margin: 0.8rem 0 3.6rem;
    }
  }
  .class {
    text-align: center;
    .first-box {
      margin-top: -1rem;
    }
    p {
      font-size: 1.6rem;
      margin: 3rem 0;
      b {
        font-weight: 800;
        font-size: 3rem;
      }
    }
  }
  .info {
    font-size: 1.6rem;
    max-width: 100%;
    margin: 0 0;

    & > p {
      line-height: 2.1rem;
      padding: 1rem;
      text-align: center;
      border: 2px solid #296394;
      border-radius: 5px;
    }
    b {
      font-weight: 800;
      font-size: 3rem;
    }
    h3 {
      margin: 1.6rem 0;
    }
    ul {
      display: flex;

      flex-wrap: wrap;
      li {
        flex-grow: 1;
        margin: 1rem 0;
        align-items: center;
        text-align: center;

        h1 {
          color: var(--primary-color);
          font-weight: bold;
        }
        h2 {
          font-weight: bold;
        }
      }
    }
  }
  .path-container {
    display: flex;
    position: relative;

    width: 90%;
    height: 2rem;
    margin: 0 auto;

    background: #ffffff;
    border: 1px solid #141b23;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-size: 1rem;
    font-weight: bold;

    div + div {
      margin-left: -2%;
    }

    p {
      position: absolute;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .path {
    /* height: 1.3rem; */

    /* background: #296394; */

    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #141b23;
    border-top: transparent;
    border-bottom: transparent;
    border-radius: 10px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 460px) {
      span {
        display: none;
      }
    }

    font-weight: normal;
  }

  .premio {
    margin-top: 1rem;
    padding: 1rem;
    border: 2px solid #296394;
    border-radius: 5px;
  }
  button {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 1rem;
    color: var(--black-background);
    font-size: 1.6rem;

    display: block;

    margin: 2rem auto 0;
    padding: 0.6rem 2rem 0.3rem;
    cursor: pointer;
    outline: none;
  }
`;
