import styled from 'styled-components';
import iconSearch from '../../../Assets/icons/iconSearch.svg';

export const AdminContainer = styled.div`
  padding: 0rem 8rem 2rem;
  max-width: 120rem;
  margin: 5.4rem auto 4rem;
  @media (max-width: 991px) {
    padding: 0;
  }
  .adm {
    background-color: #fff;
    border-radius: 2rem;
    color: var(--black-background);

    padding: 5.4rem;
    @media (max-width: 991px) {
      border-radius: 0;
    }
    @media (max-width: 600px) {
      padding: 3.2rem;
    }
    .tab {
      margin-bottom: 1.7rem;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-align: center;

      max-width: 36rem;
      margin: 0 auto;
    }
  }
  .tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Container = styled.div`
  max-width: 70rem;
  margin: 0 auto;
`;

export const CommissionsContainer = styled.div`
  width: 100%;
  .container-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    max-width: 46rem;
    margin: 0.8rem auto;
    @media (max-width: 420px) {
      justify-content: center;
    }
    .name-search {
      background-color: rgba(196, 196, 196, 0.75);
      border: none;
      border-radius: 1rem;
      color: #646464;
      font-size: 1.4rem;
      line-height: 2.2rem;

      max-width: 14rem;
      max-height: 2.6rem;
      padding: 0.8rem;
      margin: 0.4rem;
      outline: none;
      &::placeholder {
        background: url(${iconSearch}) no-repeat;
        background-repeat: no-repeat;
        background-position: right;
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 2.2rem;

        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
      &:hover,
      &:focus {
        outline: none;
        box-shadow: 0 0 0 1.5px #646464;
      }
      @media (max-width: 600px) {
        max-height: 3.4rem;
      }
    }
    select {
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);
      border-radius: 1rem;
      color: var(--black-background);
      font-size: 1.1rem;

      display: flex;

      min-width: 140px !important;
      max-width: 140px !important;
      min-height: 26px !important;
      max-height: 26px !important;

      padding: 0.2rem;
      margin: 0.4rem;
      outline: none;
      @media (max-width: 600px) {
        min-height: 3.4rem !important;
        max-height: 3.4rem !important;
      }
    }
  }
  .total-value {
    border-top: 1px solid #000;

    padding-top: 16px;
    margin: 0.6rem 0;
    position: relative;
    span {
      font-weight: 700;
      font-size: 1.6rem;

      display: block;

      position: absolute;
      right: 0;
    }
  }
`;

export const PaymentsContainer = styled.div`
  padding: 2.7rem 0 5.2rem 0;
  width: 100%;

  .intro-data {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex: 1;

    max-width: 70rem;
    @media (max-width: 600px) {
      justify-content: space-between;
    }
    @media (max-width: 440px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: space-between;
      height: 14rem;
    }
    div span {
      color: var(--black-background);
      font-weight: 800;
      font-size: 1.4rem;
      line-height: 1.4rem;
      @media (max-width: 480px) {
        font-size: 1.3rem;
        line-height: 1.3rem;
      }
      @media (max-width: 440px) {
        font-size: 1.4rem;
        line-height: 1.4rem;
      }
    }
    div h3 {
      color: #000000;
      font-weight: 800;
      font-size: 2rem;
      line-height: 2rem;

      margin-top: 0.2rem;
      @media (max-width: 480px) {
        font-size: 1.7rem;
        line-height: 1.7rem;
      }
      @media (max-width: 440px) {
        font-size: 1.4rem;
        line-height: 1.4rem;
      }
    }
    .info-payments-tooltip {
      max-width: 100%;
      display: flex;
    }
  }
  h2 {
    text-align: right;
    margin-top: 0.8rem;
  }
`;

interface IButtonProps {
  isSelect: boolean;
}

export const Table = styled.table<IButtonProps>`
  border-spacing: 0;
  color: var(--black-background);

  display: block;

  text-align: center;

  span {
    font-size: 2rem;
    line-height: 8rem;
  }

  max-width: 70rem;
  max-height: 37rem;
  padding-bottom: 0.8rem;
  margin: 2.8rem auto 0;
  overflow: auto;

  ::-webkit-scrollbar-track {
    background-color: #c4c4c4;
    border-radius: 2rem;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    @media (max-width: 800px) {
      width: 4px;
      height: 4px;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: #646464;
    border-radius: 2rem;
  }
  thead tr,
  tbody tr {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-columns: ${({ isSelect }) =>
      isSelect ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};

    align-items: center;
    text-align: center;
    width: 70rem;
    padding: 1rem 0;
    @media (max-width: 560px) {
      width: 60rem;
    }
  }
  tbody tr {
    &:hover {
      background-color: #eee;
      border-radius: 1rem;
    }
  }
  th {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  td {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
  td:first-child {
    margin-left: 1.6rem;
  }
  .left {
    text-align: left;
  }
  .center {
    text-align: center;
  }
`;
