/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatISO } from 'date-fns';

import Intro from '../../../components/Intro';
import SkeletonLoader from '../../../components/SkeletonLoader';
import { useLoadingBar } from '../../../hooks/loadingBar';

import user from '../../../Assets/icons/user-intro.svg';
import buttonUser from '../../../Assets/icons/button-personal.svg';
import buttonBank from '../../../Assets/icons/button-bank.svg';
import buttonHelp from '../../../Assets/icons/button-help.svg';
import edit from '../../../Assets/icons/edit.svg';
import send from '../../../Assets/icons/send.svg';
import arrow from '../../../Assets/icons/arrow.svg';
import hidePassword from '../../../Assets/icons/hide_password.svg';
import showPassword from '../../../Assets/icons/show_password.svg';
import contract from '../../../Assets/icons/contract.svg';
import { useAuth } from '../../../hooks/auth';

import { ProfileGenericContainer, UserProfileContainer } from './styles';
import {
  GetProfile as IGetProfile,
  useChangePassword,
  useGetProfile,
} from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import Avatar from '../../../components/Avatar';
import getFirstName from '../../../hooks/getFirstName';
import {
  ProfileClassEnum,
  ProfileRoleEnum,
  ProfileStatusEnum,
} from '../../../models/User';

const UserProfile: React.FC = () => {
  const changePassword = useChangePassword();
  const getProfile = useGetProfile();

  const { signOut, token } = useAuth();
  const { addToast } = useToast();
  const { isBarLoading } = useLoadingBar();

  const [old_password, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState('');
  const [inputType, setInputType] = useState({
    old_password: 'password',
    password: 'password',
    password_confirmation: 'password',
  });
  const [data, setData] = useState<IGetProfile['Response']>({
    id: '',
    name: '',
    email: '',
    password: '',
    phone_number: '',
    document_number: '',
    birth_date: '',
    bank_completed_name: '',
    bank_document_number: '',
    bank: '',
    bank_account_type: '',
    bank_account: '',
    bank_agency: '',
    postal_code: '',
    state: '',
    city: '',
    neighborhood: '',
    street: '',
    complement: '',
    profile_role: ProfileRoleEnum.seller,
    profile_class: ProfileClassEnum.junior,
    status: ProfileStatusEnum.approved,
    seller_hash: '',
    seller_link: '',
    adherence_agreement: true,
    avatar_url: '',
    created_at: '',
    updated_at: '',
    referred_by: '',
  });

  const [isPassword, setIsPassword] = useState(false);
  const [isChange, setIsChangePassword] = useState(false);

  const handlePasswordChange = async () => {
    try {
      if (old_password.length > 7) {
        if (password.length > 7) {
          if (password_confirmation !== password) {
            throw new Error('Senhas não coincidem');
          }
        } else {
          throw new Error('Nova senha dever ter mais de 7 caracteres');
        }
      } else {
        throw new Error('Senha atual deve ter mais de 7 caracteres');
      }

      await changePassword({
        old_password,
        password,
        password_confirmation,
        token,
      });
      addToast({
        type: 'success',
        title: '',
        description: 'Sua senha foi alterada',
      });
      setIsChangePassword(true);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro',
        description: `${error.message}`,
      });
    }
  };
  const fetchData = async () => {
    getProfile()
      .then(res => {
        setData(res);
        if (res === undefined) {
          throw new Error('Não foi possível recuperar os dados do usuário');
        }
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: `${error.message} `,
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ProfileGenericContainer>
      <UserProfileContainer>
        <Intro
          image={user}
          name="Minha Conta"
          text="Administração do Negócio"
        />
        {isBarLoading ? (
          <SkeletonLoader
            style={{
              backgroundColor: '#fff',
              borderRadius: '2rem',
              minHeight: '60rem',
            }}
          />
        ) : (
          <section className="account">
            <div className="grid-profile">
              <div className="profile">
                <Avatar
                  avatar_url={data?.avatar_url}
                  widthAndHeightPicture="10"
                  marginTop="0"
                />

                <div className="profile-user">
                  <h3 className="profile-name">{getFirstName(data.name)}</h3>
                  {data.state ? (
                    <p className="profile-name-city">
                      {`${data.state.toLocaleUpperCase()} - ${data.city}`}
                    </p>
                  ) : (
                    <p className="profile-name-city">UF - Cidade</p>
                  )}
                  <p className="profile-level">
                    {data.profile_class ? data.profile_class : 'Sem Classe'}
                  </p>
                </div>
              </div>
              <div className="access-information">
                <h4>Informações de acesso</h4>
                <div className="background-information">
                  {isChange ? (
                    <h4 style={{ color: ' var(--primary-color)' }}>
                      Senha alterada com sucesso.
                    </h4>
                  ) : (
                    <>
                      {isPassword ? (
                        <div className="input-information">
                          <div className="grid-password">
                            <div className="grid-input">
                              <label>Senha Atual</label>
                              <div>
                                <input
                                  type={inputType.old_password}
                                  placeholder="******"
                                  className={
                                    old_password.length > 7 ? '' : 'incorrect'
                                  }
                                  onFocus={e => {
                                    e.type = 'text';
                                  }}
                                  value={old_password}
                                  onChange={e => setOldPassword(e.target.value)}
                                />
                                {inputType.old_password === 'password' ? (
                                  <img
                                    src={hidePassword}
                                    alt=""
                                    onClick={() =>
                                      setInputType({
                                        ...inputType,
                                        old_password: 'text',
                                      })
                                    }
                                  />
                                ) : (
                                  <img
                                    src={showPassword}
                                    alt=""
                                    onClick={() =>
                                      setInputType({
                                        ...inputType,
                                        old_password: 'password',
                                      })
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            <div className="grid-input">
                              <label>Nova Senha</label>
                              <div>
                                <input
                                  type={inputType.password}
                                  placeholder="******"
                                  className={
                                    password.length > 7 ? '' : 'incorrect'
                                  }
                                  value={password}
                                  onChange={e => setPassword(e.target.value)}
                                />
                                {inputType.password === 'password' ? (
                                  <img
                                    src={hidePassword}
                                    alt=""
                                    onClick={() =>
                                      setInputType({
                                        ...inputType,
                                        password: 'text',
                                      })
                                    }
                                  />
                                ) : (
                                  <img
                                    src={showPassword}
                                    alt=""
                                    onClick={() =>
                                      setInputType({
                                        ...inputType,
                                        password: 'password',
                                      })
                                    }
                                  />
                                )}
                              </div>
                              <div className="grid-input" />
                              <label>Confirmar Nova Senha</label>
                              <div>
                                <input
                                  type={inputType.password_confirmation}
                                  placeholder="******"
                                  value={password_confirmation}
                                  className={
                                    password_confirmation === password
                                      ? ''
                                      : 'incorrect'
                                  }
                                  onChange={e => {
                                    setPasswordConfirmation(e.target.value);
                                  }}
                                />
                                {inputType.password_confirmation ===
                                'password' ? (
                                  <img
                                    src={hidePassword}
                                    alt=""
                                    onClick={() =>
                                      setInputType({
                                        ...inputType,
                                        password_confirmation: 'text',
                                      })
                                    }
                                  />
                                ) : (
                                  <img
                                    src={showPassword}
                                    alt=""
                                    onClick={() =>
                                      setInputType({
                                        ...inputType,
                                        password_confirmation: 'password',
                                      })
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="grid-button">
                            <button
                              type="submit"
                              onClick={handlePasswordChange}
                            >
                              Confirmar
                              <img src={send} alt="send" />
                            </button>
                            <button
                              type="button"
                              onClick={() => setIsPassword(!isPassword)}
                            >
                              Cancelar
                              <img src={edit} alt="edit" />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="input-information">
                          <div className="grid-input">
                            <label>E-mail</label>
                            <span>{data.email}</span>
                          </div>
                          <button
                            type="button"
                            onClick={() => setIsPassword(!isPassword)}
                          >
                            Alterar Senha
                            <img src={edit} alt="edit" />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <h2>Gerencie seus Dados</h2>
            <div className="grid-manage-data">
              <Link to="profile/user-info">
                <img src={buttonUser} alt="buttonUser" />
                <span>Dados pessoais</span>
              </Link>
              <Link to="profile/bank-data">
                <img src={buttonBank} alt="buttonBank" />
                <span>Dados Bancários</span>
              </Link>
              <Link to="profile/legal-documents">
                <img src={contract} alt="buttonUser" />
                <span>Documentos Legais</span>
              </Link>
              <div className="bottom">
                {' '}
                <Link to="profile/FAQ" id="button-help">
                  <img src={buttonHelp} alt="buttonHelp" />
                  <div>
                    <span>Central de ajuda</span>
                    <img src={arrow} alt="arrow" />
                  </div>
                </Link>
              </div>
            </div>
            <button type="button" id="logout" onClick={() => signOut()}>
              Deslogar
            </button>
          </section>
        )}
      </UserProfileContainer>
    </ProfileGenericContainer>
  );
};

export default UserProfile;
