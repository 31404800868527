/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Intro from '../../../components/Intro';

import contract from '../../../Assets/icons/contract.svg';

import { Container } from './styles';
import { useToast } from '../../../hooks/toast';
import { useEditProfile, useGetProfile } from '../../../services/api';

const Contract: React.FC = () => {
  const history = useHistory();
  const scrollRef = useRef<HTMLDivElement>(document.createElement('div'));
  const [isFinalScroll, setIsFinalScroll] = useState(true);
  const [checkbox, setCheckbox] = useState(true);
  const [isAccepted, setIsAccepted] = useState(false);

  const { addToast } = useToast();

  const editProfile = useEditProfile();
  const getProfile = useGetProfile();

  const handleAgreement = async () => {
    try {
      await editProfile({
        adherence_agreement: true,
      });
      addToast({
        type: 'success',
        title: '',
        description: 'O Contrato foi aceito',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro',
        description: `${error}`,
      });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getProfile();
        console.log('resultado getprofile', res);
        if (res.adherence_agreement) {
          setIsAccepted(true);
        }
      } catch (error) {}
    };
    fetchData();
  }, []);
  const onScroll = () => {
    if (
      Math.floor(
        scrollRef.current.scrollHeight - scrollRef.current.scrollTop,
      ) === scrollRef.current.offsetHeight
    ) {
      setIsFinalScroll(false);
    }
  };
  return (
    <Container>
      <Intro
        image={contract}
        name="Termo de Adesão"
        text="Condições de Adesão ao Programa"
      />
      <section className="wrapper-text">
        <div className="container" onScroll={onScroll}>
          <p>
            <strong>Termos de Uso EDUCACIONAL WUP</strong>
          </p>
          <p>
            O aceite destes Termos de Uso configura uma licença de uso não
            exclusiva das ferramentas tecnológicas contidas na Educacional WUP
            Ltda aos Usuários. As ferramentas tecnológicas são licenciadas no
            estado em que se encontram, podendo ser modificadas, substituídas ou
            removidas da Educacional WUP Ltda a qualquer momento, sem aviso
            prévio. O respeito às condições destes Termos de Uso é essencial
            para o uso legítimo de tais ferramentas.
          </p>
          <p>
            O Usuário, ao fazer uso do escritório virtual, expressamente declara
            que tem conhecimento e concorda com os Termos de Uso da Educacional
            WUP Ltda, inclusive com a Política de Privacidade e a Política de
            Cookies, que, apesar de apresentadas em texto separado, são parte
            integrante deste.
          </p>
          <p>
            O uso das ferramentas tecnológicas contidas na Educacional WUP Ltda
            em discordância com os termos a seguir poderá implicar, a critério
            da EDUCACIONAL WUP LTDA, a suspensão do acesso à conta ou o
            cancelamento do cadastro do Usuário, com consequente e imediato
            encerramento da licença de uso previamente existente e, se for o
            caso, rescisão do correspondente Contrato de Agência, sem prejuízo
            de eventuais outras sanções, conforme adiante detalhado.
          </p>
          <p>
            <strong>CARACTERÍSTICAS GERAIS</strong>
          </p>
          <p>
            Educacional WUP Ltda com sede na Cidade de Pinhais, Estado do
            Paraná, na rua Rio Solimões 160, cep 83322150 regularmente inscrita
            no CNPJ/ME sob o nº.18.215.802/0001-84, doravante designada como
            “Educacional WUP”, é uma Edtech de cursos de graduação,
            pós-graduação, idiomas e profissões.
          </p>
          <p>
            Para tanto, possibilita que pessoas jurídicas ou em processo,
            doravante designadas como “Consultores Acadêmicos” (e, a depender do
            contexto em que são utilizados, “Usuários”), voluntariamente,
            promovam e fomentem vendas do curso
            <em>da Educacional WUP Ltda</em>, de acordo com as regras e
            condições previstas neste Termo de Uso, na Política de Privacidade e
            na Política de Cookies.
          </p>
          <p>
            O Usuário interessado em tornar-se Consultor acadêmico não pode ser
            empregado da Educacional WUP Ltda, exceto em caso de prévia
            autorização por escrito.
          </p>
          <p>
            O Usuário, ao cadastrar-se no
            <em>site</em>, manifesta ciência das condições aos quais estará
            vinculado, manifestando sua concordância através da marcação de um
            check box decorrente do texto abaixo e clicando no botão de
            confirmação.
          </p>
          <p>
            Li, compreendi e manifesto expressamente minha ciência e
            concordância com as disposições contidas no TERMO DE USO, na
            POLÍTICA DE PRIVACIDADE e na POLÍTICA DE COOKIES, aos quais estarei
            sujeito caso meu cadastro e perfil sejam aprovados pela Educacional
            WUP.
          </p>
          <p>
            <strong>DEFINIÇÕES</strong>
          </p>
          <p>
            Para efeitos destes Termos de Uso, devem ser consideradas as
            seguintes definições:
          </p>
          <p>
            (a) Área Restrita: página exclusiva na
            <em>internet</em>
            e/ou aplicativo, acessada mediante o fornecimento de
            <em>login</em>e senha, na qual o consultor acadêmico poderá efetuar
            a administração de seu perfil de vendas;
          </p>
          <p>
            (b) Comissão: quantia devida ao consultor em contrapartida à
            efetivação de negócio, conforme determinado no correspondente
            Contrato de Agência;
          </p>
          <p>
            (c)Chargeback: é o cancelamento de venda efetuada com cartão de
            crédito;
          </p>
          <p>
            (d) Confidencialidade: grau de proteção que se quer conferir ao
            sigilo do conteúdo de uma determinada informação, visando a
            limitação de seu acesso e uso apenas às pessoas para quem elas são
            destinadas;
          </p>
          <p>
            (e) Conteúdo: vídeos, textos, fotografias, imagens, desenhos,
            marcas, logotipos, animações, melodias ou vozes exibidos ou
            publicados no escritório virtual;
          </p>
          <p>
            (f) Dados Cadastrais: conjunto de informações pessoais do Usuário,
            de modo que seja possível sua individualização (inclusive o endereço
            eletrônico), e a qualificação pessoal, entendida como nome, prenome,
            estado civil, profissão do Usuário e dados bancários, que devem ser
            todas verdadeiras, válidas e corretas;
          </p>
          <p>
            (g)
            <em>Login</em>: nome de usuário utilizado pelo CONSULTOR para acesso
            à Área Restrita;
          </p>
          <p>
            (h)
            <em>Malwares</em>:<em>softwares</em>
            maliciosos que podem danificar ou impedir o normal funcionamento da
            rede, do sistema ou dos equipamentos informáticos (
            <em>hardware e software</em>) de terceiros ou que possam danificar
            os documentos eletrônicos e arquivos armazenados nestes equipamentos
            informáticos;
          </p>
          <p>
            (i) Senha: conjunto de caracteres, de conhecimento único do
            consultor acadêmico, de caráter pessoal e intransferível, utilizado
            no processo de verificação de sua identidade, assegurando que ele é
            realmente quem diz ser, sendo vedado seu compartilhamento com
            terceiros;
          </p>
          <p>
            (j)
            <em>Spam</em>: comunicação ou mensagem eletrônica enviada a um
            grande número de pessoas, sem o seu consentimento;
          </p>
          <p>
            (k)
            <em>Site</em>: representa o domínio de propriedade da Educacional
            WUP Ltda
          </p>
          <p>(o) Usuário: é o Consultor Acadêmico</p>
          <p>
            <strong>ADESÃO</strong>
          </p>
          <p>
            A adesão do Usuário como consultor se efetivará mediante o
            cumprimento das seguintes etapas:
          </p>
          <p>
            I - Fornecimento de Dados Cadastrais, pelo Usuário, no processo de
            cadastramento;
            <br /> II - Verificação e análise, pela Educacional WUP Ltda ou por
            terceiro a ela vinculado, dos Dados Cadastrais fornecidos pelo
            Usuário;
            <br /> III - Aprovação, pela Educacional WUP, dos Dados Cadastrais
            fornecidos pelo Usuário.
          </p>
          <p>
            A Educacional WUP Ltda, durante a etapa de verificação e análise,
            poderá solicitar dados adicionais e informações do Usuário.
          </p>
          <p>
            A Educacional WUP Ltda efetuará comunicação ao Usuário,
            preferencialmente por e-mail, caso seu cadastro seja aprovado,
            podendo, desde que obedecidas as formalidades estabelecidas, ser um
            Consultor Acadêmico Licenciado, conforme planos de Licença WUP.
          </p>
          <p>
            <strong>OBRIGAÇÕES DO Consultor</strong>
          </p>
          <p>
            Constituem obrigações do consultor, entre outras previstas em lei e
            em Contrato:
          </p>
          <p>
            (A) Fornecer, no processo de cadastro, dados verdadeiros e
            completos, mantendo-os sempre atualizados, devendo atualizar, em sua
            área restrita, qualquer alteração de endereço e/ou de
            <em>e-mail</em>
            e/ou qualquer canal de contato, bem como dos dados bancários para
            pagamento;
          </p>
          <p>
            (B) Enquadrar-se, nos termos da lei, como MEI ou em processo de
            tornar-se, sociedade limitada ou outros tipos societários
            aplicáveis; emitir Nota Fiscal com a descrição correta dos serviços
            prestados, em consonância ao CNAE sugerido para a atividade; e
            efetuar o recolhimento de todos os tributos a que está obrigado,
            conforme legislação tributária vigente;
          </p>
          <p>
            (C) Zelar pela segurança e confidencialidade do
            <em>login</em>e senha de acesso à Área Restrita, os quais são de
            caráter pessoal e intransferível, sendo vedado seu compartilhamento
            com terceiros e sendo o consultor o único e exclusivo responsável
            pela sua utilização e guarda, assumindo todos os ônus e
            responsabilidades decorrentes de seus atos e de sua conduta,
            respondendo, ainda, pelos atos que terceiros praticarem em seu nome,
            por meio do uso de seu
            <em>login</em>e da sua senha de acesso;
          </p>
          <p>
            (D) Não criar campanhas com nomes, marcas ou qualquer outro sinal de
            semelhança referente aos parceiros fornecedores de produtos ou
            serviços representados pela Educacional WUP Ltda, tal prática além
            de implicar em “banimento” da sua licença incorrerá em penalidades
            nos termos da lei por uso indevido de marca ou nome ou semelhante de
            propriedade de terceiros, alheios a este contrato.
          </p>
          <p>
            (F) Assumir todos os ônus e responsabilidades decorrentes de seus
            atos, respondendo, ainda, pelos atos que terceiros praticarem em seu
            nome, garantindo a Educacional WUP Ltda o direito de regresso caso
            venha a ser responsabilizada, administrativa ou judicialmente, em
            decorrência de ato praticado;
          </p>
          <p>
            (H) Atualizar, em sua área restrita, no prazo de 10 (dez) dias,
            qualquer alteração que promova nos seus quadros societários e que
            venha a modificar o(s) responsável(is) pelo gerenciamento da
            empresa, ou mesmo, a admitir novo(s) sócio(s).
          </p>
          <p>
            Sem prejuízo de outras regras previstas nestes Termos de Uso ou em
            Contrato, o consultor se compromete a não:
          </p>
          <p>
            (i) COPIAR, MODIFICAR, REPRODUZIR, DISTRIBUIR, FAZER DOWNLOAD,
            ARMAZENAR, TRANSMITIR, VENDER, REVENDER, FAZER OU PERMITIR
            ENGENHARIA REVERSA, TRADUZIR, DECOMPILAR, COPIAR, ALUGAR, EMPRESTAR,
            LICENCIAR OU, DE OUTRA MANEIRA, DISPOR OU CRIAR PRODUTOS DERIVADOS
            DE QUALQUER CONTEÚDO DISPONÍVEL NO ESCRITÓRIO VIRTUAL, TAIS COMO
            <em>TRADE DRESS</em>,<em>LAYOUTS</em>, ÍCONES, JANELAS DE NAVEGAÇÃO,
            <em>SOFTWARES</em>, APLICATIVOS, PROGRAMAS, CÓDIGOS-FONTE, LINHA DE
            CÓDIGO, BANCOS DE DADOS, ESQUEMAS, DOCUMENTAÇÕES E QUAISQUER
            CONTEÚDOS DISPONIBILIZADOS PELA PLATAFORMA E PROTEGIDOS POR DIREITOS
            AUTORAIS, TAIS COMO MARCAS E LOGOS, SEM A DEVIDA AUTORIZAÇÃO PRÉVIA,
            EXPRESSA E POR ESCRITO;
          </p>
          <p>
            (ii) violar ou tentar violar quaisquer medidas de segurança do
            escritório virtual, tirar proveito de qualquer inconsistência do
            sistema para obter vantagem para si próprio ou para terceiros, ou
            causar prejuízo à EDUCACIONAL WUP LTDA e/ou a terceiros,
            especialmente ao acessar ou tentar acessar qualquer conta de Usuário
            do ESCRITÓRIO VIRTUAL, ou, ainda, obter e divulgar a senha de
            Usuário a terceiros;
          </p>
          <p>
            (iii) submeter à EDUCACIONAL WUP LTDA qualquer informação falsa,
            imprecisa ou incompleta;
          </p>
          <p>(iv) manipular conteúdo;</p>
          <p>(v) interferir nas transações realizadas por outros Usuários;</p>
          <p>(vi) passar-se por outra pessoa ou organização;</p>
          <p>
            (vii) usar
            <em>spam</em>
            ou qualquer outra prática de divulgação ou captação de informação
            inapropriada, caluniosa ou ilegal para promover qualquer CONTEÚDO DE
            PROPRIEDADE DA EDUCACIONAL WUP LTDA
          </p>
          <p>
            (viii) comprar CONTEÚDO através de seu próprio
            <em>link</em>
            de CONSULTOR (SALVO AUTORIZADO PELA EDUCACIONAL WUP EM SUAS REUNIÕES
            OU POR ESCRITO) e/ou substituir o<em>link</em>
            de CONSULTOR constante de uma página de vendas, em benefício próprio
            ou de terceiros;
          </p>
          <p>
            (ix) agredir, caluniar, injuriar ou difamar outros Usuários e/ou
            terceiros, bem como possíveis clientes, ou de qualquer forma fazer
            uso de linguagem imprópria ou ofensiva, ou de qualquer modo praticar
            atos que sejam prejudiciais à marca e/ou à reputação da EDUCACIONAL
            WUP LTDA;
          </p>
          <p>
            (x) compartilhar dados do seu cadastro ou da EDUCACIONAL WUP LTDA em
            redes sociais, de forma orgânica.
          </p>
          <p>
            <strong>OBRIGAÇÕES DA EDUCACIONAL WUP LTDA</strong>
          </p>
          <p>
            Constituem obrigações da EDUCACIONAL WUP LTDA, entre outras
            previstas em lei e em Contrato:
          </p>
          <p>
            (A) Disponibilizar o acesso do CONSULTOR à Área Restrita, mediante o
            fornecimento de
            <em>login</em>e senha de acesso, caso os Dados Cadastrais do
            CONSULTOR sejam aprovados;
          </p>
          <p>
            (B) Disponibilizar as informações, instruções, orientações e
            ferramentas necessárias para que o CONSULTOR possa criar e
            administrar seu cadastro no ESCRITÓRIO VIRTUAL WUP.
          </p>
          <p>
            (C) Na hipótese de o CONSULTOR optar por encerrar seu cadastro no
            ESCRITÓRIO VIRTUAL WUP ou, por qualquer outro motivo, extinguir-se o
            Contrato, a EDUCACIONAL WUP LTDA ficará obrigada a repassar o valor
            de crédito remanescente no perfil do CONSULTOR, independentemente da
            quantia, respeitando-se os prazos definidos para pagamento.
          </p>
          <p>
            (D) Também na hipótese de encerramento do cadastro a EDUCACIONAL WUP
            LTDA manterá os dados do CONSULTOR e seu históricos de vendas e
            cancelamentos por 5 anos (prazo equivalente à prescrição para
            cobrança de dívidas líquidas), armazenando de forma criptografada
            esses dados e outros que não sejam essenciais para eventual defesa
            da EDUCACIONAL WUP LTDA em eventual litígio envolvendo o contrato,
            tais como: dados de navegação e interação com outras aplicações de
            terceiros que poderiam ser anonimizados, para eventual uso de
            aperfeiçoamento da EDUCACIONAL WUP LTDA.
          </p>
          <p>
            Havendo divergências ou inconsistências dos Dados Cadastrais do
            CONSULTOR, a EDUCACIONAL WUP LTDA, sem que isso represente qualquer
            inadimplemento, ficará autorizada a reter pagamento(s) até que tais
            divergências ou inconsistências sejam devidamente sanadas pelo
            CONSULTOR, o que deverá ser feito no prazo de 90 DIAS, sob pena de o
            referido valor ser revertido, de forma definitiva e automática, ao
            caixa da EDUCACIONAL WUP LTDA.
          </p>
          <p>
            <strong>SUSPENSÃO E BLOQUEIO</strong>
          </p>
          <p>
            A EDUCACIONAL WUP LTDA, a qualquer momento e independentemente de
            notificação, adicionalmente poderá suspender ou bloquear as
            atividades do CONSULTOR, na ocorrência de qualquer das seguintes
            hipóteses:
          </p>
          <p>
            (A)publicação dos conteúdos mencionados neste Termo como proibidos
            em quaisquer meios de publicação;
          </p>
          <p>
            (B)não devolução dos valores totais recebidos nos casos de
            chargeback, cancelamento, fraude, dentre outros;
          </p>
          <p>
            A suspensão inclui a exclusão do cadastro do CONSULTOR e o bloqueio
            do acesso à Área Restrita.
          </p>
          <p>
            A hipótese de suspensão prevista no item “A”, não exclui a
            possibilidade de rescisão imediata.
          </p>
          <p>
            O CONSULTOR não poderá pleitear qualquer indenização por perdas e
            danos em decorrência da suspensão prevista no itens ”A“ e ”B”.
          </p>
          <p>
            <strong>PROPRIEDADE INTELECTUAL</strong>
          </p>
          <p>
            A EDUCACIONAL WUP LTDA é a única e exclusiva titular dos direitos de
            propriedade intelectual sobre os layouts, websites, softwares,
            aplicativos, programas, códigos-fonte, bancos de dados, esquemas e
            documentações relacionados ao ESCRITÓRIO VIRTUAL E MODELO DE
            NEGÓCIOS, INCLUINDO PLANO DE CARREIRA E LICENÇAS, bem como
            licenciada ou cessionária de eventuais direitos de propriedade
            intelectual de terceiros, que criam os conteúdos protegidos
            disponibilizados pela EDUCACIONAL WUP LTDA ao CONSULTOR, para
            publicação em seu perfil.
          </p>
          <p>
            As marcas, nomes empresariais e de domínio estão protegidas pelas
            Leis de
            <em>Software</em>, de Direito Autoral e de Propriedade Industrial,
            sendo vedado ao CONSULTOR se identificar como proprietário ou
            solicitar o registro de qualquer direito sobre tais marcas e nomes.
          </p>

          <p>
            <strong>INDISPONIBILIDADE</strong>
          </p>
          <p>
            A EDUCACIONAL WUP LTDA não se responsabiliza por quaisquer danos ou
            prejuízos decorrentes da indisponibilidade total ou parcial do
            cadastro do CONSULTOR, em decorrência de:
          </p>
          <p>(A)manutenção técnica e/ou operacional;</p>
          <p>
            (B)indisponibilidade de acesso ao
            <em>Site</em>;
          </p>
          <p>
            (C)alteração, pelas redes sociais, de suas políticas e diretrizes
            com relação a Aplicativos;
          </p>
          <p>
            (D)interrupção ou suspensão dos serviços fornecidos por empresas que
            disponibilizam acesso à<em>Internet</em>;
          </p>
          <p>
            (E)caso fortuito ou eventos de força maior, incluindo, mas não se
            limitando a quedas de energia, ataques de
            <em>malwares</em>e ações de terceiros que impeçam a sua
            disponibilidade, a exemplo da retirada do Aplicativo do ar pela
            respectiva controladora da plataforma de rede social.
          </p>
          <p>
            <strong>DISPOSIÇÕES GERAIS</strong>
          </p>
          <p>
            O CONSULTOR é o único e exclusivo responsável por quaisquer dicas e
            comentários que vier a efetuar, da mesma forma que qualquer
            informação que veicular sobre os produtos da EDUCACIONAL WUP LTDA,
            bem como pelos custos e despesas que vier a realizar para tais fins.
          </p>
          <p>
            O CONSULTOR reconhece e concorda que a EDUCACIONAL WUP LTDA poderá,
            a qualquer momento e independentemente de prévio aviso, inserir
            campanhas publicitárias no ESCRITÓRIO VIRTUAL
          </p>
          <p>
            A EDUCACIONAL WUP LTDA poderá, a qualquer tempo e independentemente
            de prévio aviso, alterar suas políticas comerciais, incluindo, mas
            não se limitando, a políticas relativas ao preço e disponibilidade
            de cursos.
          </p>
          <p>
            A EDUCACIONAL WUP LTDA poderá, a qualquer tempo e independentemente
            de prévio aviso, encerrar a EDUCACIONAL WUP LTDA, não sendo devida
            nenhuma indenização ao CONSULTOR em decorrência desta decisão,
            exceto os valores decorrentes de negócios efetivamente realizados e
            ainda não pagos.
          </p>
          <p>
            A tolerância quanto a eventual descumprimento de quaisquer das
            cláusulas e condições deste Termo não será considerada ou
            interpretada como moratória ou novação das obrigações estipuladas e
            tampouco impedirá ou inibirá a exigibilidade da satisfação integral
            posteriormente ou no caso de idêntica ocorrência.
          </p>
          <p>
            A EDUCACIONAL WUP LTDA poderá, a qualquer tempo, alterar quaisquer
            das cláusulas e condições do presente Termo, mediante comunicação ao
            CONSULTOR, que poderá se opor à alteração no prazo de até 3
            (três)dias úteis, contados a partir do envio da comunicação pela
            EDUCACIONAL WUP LTDA ou comunicação na Área Restrita, podendo o
            CONSULTOR se opor em igual prazo.
          </p>
          <p>
            A ausência de oposição do CONSULTOR no prazo estabelecido será
            interpretada como aceitação da alteração.
          </p>
          <p>
            Caso o CONSULTOR se oponha, deverá deixar de ser CONSULTOR, sem
            prejuízo da comissão a que tem direito. Caso não deixe de ser
            CONSULTOR, as alterações havidas valerão para e perante o mesmo.
          </p>
          <p>
            O CONSULTOR concorda que a EDUCACIONAL WUP LTDA poderá, no momento
            de uma fusão, aquisição ou venda, ceder os seus direitos e
            obrigações quanto ao acesso à ESCRITÓRIO VIRTUAL a um proprietário
            ou operador subsequente aplicável. No caso de uma fusão, aquisição
            ou venda, o seu uso continuado do acesso significa a concordância do
            Usuário ao Termo de Uso, Política de Privacidade e Política de
            Cookies do proprietário ou operador subsequente.
          </p>
          <p>
            Se você tiver alguma pergunta ou dúvida acerca do Termo de Uso, da
            Política de Privacidade ou da Política de Cookies, visite a página
            de perguntas e respostas ou acesse o chat disponível em nosso
            <em>Site</em>. Todos os direitos não garantidos expressamente aqui
            são reservados.
          </p>
          <p>Termos de Uso - PINHAIS 07 DE JANEIRO DE 2020</p>
        </div>
        <div className="container-button">
          <div>
            {isAccepted ? (
              <label htmlFor="agree">
                Os <span>Termos de uso</span> já foram aceitos
              </label>
            ) : (
              <>
                <input
                  type="checkbox"
                  id="agree"
                  name="agree"
                  disabled={isFinalScroll}
                  onChange={e => {
                    if (e.target.checked) {
                      setCheckbox(false);
                    } else {
                      setCheckbox(true);
                    }
                  }}
                />
                <label htmlFor="agree">
                  Li e aceito os <span>Termos de uso</span>
                </label>
              </>
            )}
          </div>
          <div>
            {!isAccepted && (
              <>
                <button
                  type="button"
                  onClick={() => history.goBack()}
                  id="back"
                >
                  Voltar
                </button>
                <button
                  type="button"
                  disabled={checkbox}
                  onClick={handleAgreement}
                  id="confirm"
                >
                  Confirmar
                </button>
              </>
            )}
          </div>
        </div>
      </section>
    </Container>
  );
};

export default Contract;
