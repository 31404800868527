import styled from 'styled-components';

export const Background = styled.div`
  background-color: var(--black-background);
  color: #fff;

  max-width: 100vw;
  min-height: 100vh;
  padding: 40px 0;
  #logo {
    display: block;
    margin: 0 auto;
  }
  #help {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: var(--black-background);
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    position: fixed;
    right: 10px;
    bottom: 40px;
    padding: 4px 20px;
    img {
      margin-right: 4px;
    }
  }
`;

export const Container = styled.main`
  background-color: var(--black-background);

  display: flex;
  flex-direction: column;

  max-width: 600px;
  margin: 26px auto 0;
  @media (max-width: 600px) {
    max-width: 500px;
  }
  @media (max-width: 480px) {
    max-width: 400px;
  }
  @media (max-width: 420px) {
    max-width: 340px;
  }
  @media (max-width: 330px) {
    max-width: 310px;
  }
  #text-intro {
    font-weight: 800;
    font-size: 13px;
    line-height: 13px;
    text-align: center;

    margin-top: 16px;
    span {
      color: var(--primary-color);
    }
  }
  h1 {
    color: var(--primary-color);
    font-size: 37px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;

    max-width: 440px;
    margin: 22px auto 0;
    @media (max-width: 600px) {
      font-size: 34px;
      line-height: 46px;
    }
    @media (max-width: 480px) {
      font-size: 28px;
      line-height: 36px;
    }
    @media (max-width: 420px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  .final-registration {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    color: var(--black-background);
    font-weight: 800;
    font-size: 16px;
    line-height: 17px;

    width: 440px;
    padding: 8px 16px;
    margin: 0 auto 16px;
    outline: none;
    cursor: pointer;
    @media (max-width: 480px) {
      width: 300px;
    }
    @media (max-width: 420px) {
      width: 260px;
    }
  }
  .copy-clipboard {
    display: flex;
    margin: 20px auto 20px;
    input {
      border-radius: 10px 0 0 10px;
      border: 1px solid var(--primary-color);
      font-size: 25px;
      line-height: 26px;

      width: 400px;
      padding: 0 8px;
      outline: none;
      @media (max-width: 480px) {
        font-size: 16px;
        width: 260px;
      }
      @media (max-width: 420px) {
        font-size: 14px;
        width: 218px;
      }
    }
    button {
      background: var(--primary-color);
      border: 1px solid var(--primary-color);
      border-radius: 10px;
      font-size: 12px;
      line-height: 12px;

      padding: 6px 16px;
      margin-left: -8px;
      outline: none;
      cursor: pointer;
    }
  }
  .legal-advise {
    font-size: 1.5rem;
    align-self: center;
    padding: 1rem;
  }
  .completed {
    h1 {
      color: #ffffff;
      margin: 8px auto 16px;
    }
    img {
      display: block;
      margin: 16px auto 16px;
      @media (max-width: 480px) {
        max-width: 70px;
      }
    }
  }
`;
