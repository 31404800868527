import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { useToast } from '../../../hooks/toast';

import copy from '../../../Assets/icons/copy.svg';
import copyGreen from '../../../Assets/icons/copy-green.svg';
import completed from '../../../Assets/icons/completed.svg';
import { useSaleCheckout } from '../../../hooks/saleCheckout';

import { Container } from './styles';
import { CourseCategoryEnum } from '../../../models/Course';

const GenerateLink: React.FC = () => {
  const { saleCheckout } = useSaleCheckout();
  const { addToast } = useToast();

  const [examClicked, setExamClicked] = useState(false);
  const [isClick, setIsClick] = useState(true);

  const handleCopyLink = () => {
    setIsClick(false);
    addToast({
      type: 'success',
      title: '',
      description: 'Link copiado!',
    });
  };

  return (
    <Container>
      {saleCheckout?.course.category === CourseCategoryEnum.Grad && (
        <>
          <h1>Copie o link abaixo para fazer seu vestibular</h1>
          <div className="copy-clipboard">
            <input
              readOnly
              type="text"
              value="https://docs.google.com/forms/d/e/1FAIpQLSf3GW3s8i0pAC4o43FJ6aKCxTMaaul1ZT-TpOv02bmqF8STjA/viewform"
            />
            <CopyToClipboard text="https://docs.google.com/forms/d/e/1FAIpQLSf3GW3s8i0pAC4o43FJ6aKCxTMaaul1ZT-TpOv02bmqF8STjA/viewform">
              <button
                type="button"
                onClick={handleCopyLink}
                style={{
                  background: isClick
                    ? 'var(--primary-color)'
                    : 'var(--black-background)',
                }}
              >
                {isClick ? (
                  <img src={copy} alt="copy" />
                ) : (
                  <img src={copyGreen} alt="copyGreen" />
                )}
              </button>
            </CopyToClipboard>
          </div>
        </>
      )}

      <>
        {saleCheckout?.course.category === CourseCategoryEnum.PostGrad ? (
          <>
            <div className="completed">
              <img src={completed} alt="completed" />
              <h1>Seu cadastro foi Concluído!</h1>
            </div>
            <h2 style={{ textAlign: 'center' }}>
              Parabéns! Sua matrícula foi realizada com sucesso
              <br />
              Em breve um dos nossos consultores entrará em contato por telefone
              ou e-mail com informações do parcelamento do seu curso e conclusão
              do pagamento.
            </h2>
          </>
        ) : (
          <>
            {examClicked ? (
              <>
                <div className="completed">
                  <img src={completed} alt="completed" />
                  <h1>Seu cadastro foi Concluído!</h1>
                </div>
                <h2 style={{ textAlign: 'center' }}>
                  Parabéns! Sua matrícula foi realizada com sucesso
                  <br />
                  Em breve um dos nossos consultores entrará em contato por
                  telefone ou e-mail com informações do parcelamento do seu
                  curso e conclusão do pagamento.
                </h2>
              </>
            ) : (
              <button
                type="button"
                onClick={() => {
                  if (window !== undefined) {
                    window.open(
                      'https://docs.google.com/forms/d/e/1FAIpQLSf3GW3s8i0pAC4o43FJ6aKCxTMaaul1ZT-TpOv02bmqF8STjA/viewform',
                    );
                  }
                  setExamClicked(true);
                }}
                className="final-registration"
              >
                Clique para realizar seu vestibular
              </button>
            )}

            <span className="legal-advise">
              A não conclusão do vestibular não anula o serviço contratado
            </span>
          </>
        )}
      </>
    </Container>
  );
};

export default GenerateLink;
