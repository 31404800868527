import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../../Assets/whiteLogo.png';
import showPassword from '../../Assets/icons/show_password.svg';
import hidePassword from '../../Assets/icons/hide_password.svg';

import { useToast } from '../../hooks/toast';
import { useResetPassword } from '../../services/api';
import { Container } from './styles';

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const token = history.location.search.replace('?token=', '');

  const [inputType, setInputType] = useState({
    password: 'password',
    password_confirmation: 'password',
  });
  const [data, setData] = useState({
    password: '',
    password_confirmation: '',
    token,
  });
  const resetPassword = useResetPassword();
  const handleSubmit = async () => {
    try {
      if (data.password.length > 7) {
        if (data.password === data.password_confirmation) {
          await resetPassword(data);
        } else {
          throw new Error('As senhas não correspondem');
        }
      } else {
        throw new Error('A senha deve ter no mínimo 8 caracteres');
      }
      addToast({
        type: 'success',
        title: '',
        description: 'Seus senha foi atualizada',
      });
      history.push('/');
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro',
        description: `${error.message}`,
      });
    }
  };

  return (
    <Container>
      <img width="176" src={logo} alt="Logo Fael" className="animeTop" />
      <form className="animeTop">
        <div>
          <input
            type={inputType.password}
            value={data.password}
            placeholder="Nova Senha"
            onChange={e => setData({ ...data, password: e.target.value })}
          />
          {inputType.password === 'password' ? (
            <img
              src={hidePassword}
              alt=""
              onClick={() => setInputType({ ...inputType, password: 'text' })}
            />
          ) : (
            <img
              src={showPassword}
              alt=""
              onClick={() =>
                setInputType({ ...inputType, password: 'password' })
              }
            />
          )}
        </div>
        <div>
          <input
            type={inputType.password_confirmation}
            value={data.password_confirmation}
            placeholder="Repetir Nova Senha"
            onChange={e =>
              setData({ ...data, password_confirmation: e.target.value })
            }
          />
          {inputType.password_confirmation === 'password' ? (
            <img
              src={hidePassword}
              alt=""
              onClick={() =>
                setInputType({ ...inputType, password_confirmation: 'text' })
              }
            />
          ) : (
            <img
              src={showPassword}
              alt=""
              onClick={() =>
                setInputType({
                  ...inputType,
                  password_confirmation: 'password',
                })
              }
            />
          )}
        </div>
        <button type="button" onClick={handleSubmit}>
          Entrar
        </button>
      </form>
    </Container>
  );
};

export default ResetPassword;
