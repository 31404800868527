import styled from 'styled-components';

export const RankingContainer = styled.div`
  padding: 0rem 8rem 2rem;
  max-width: 120rem;
  margin: 0px auto;
  @media (max-width: 991px) {
    padding: 0;
  }
`;

export const Results = styled.section`
  background-color: #fff;
  border-radius: 2rem;
  color: var(--black-background);

  padding: 5.2rem;
  @media (max-width: 991px) {
    border-radius: 0;
  }
  @media (max-width: 420px) {
    padding: 3.2rem;
  }
  h1 {
    font-weight: 800;
    font-size: 2.3rem;
    line-height: 2.4rem;
    text-align: center;

    margin-bottom: 2.6rem;
  }
`;

interface IRankingProps {
  isSelect?: boolean;
}

export const Table = styled.table<IRankingProps>`
  border-spacing: 0;
  color: var(--black-background);
  text-align: center;
  display: block;
  span {
    font-size: 2rem;
    line-height: 8rem;
  }
  max-width: 70rem;
  max-height: 60rem;
  padding-bottom: 0.8rem;
  margin: 2.8rem auto 0;
  overflow: auto;
  ::-webkit-scrollbar-track {
    background-color: #c4c4c4;
    border-radius: 2rem;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    @media (max-width: 800px) {
      width: 4px;
      height: 4px;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: #646464;
    border-radius: 2rem;
  }
  thead tr,
  tbody tr {
    display: grid;
    grid-template-columns: 1fr 1fr 5fr 4fr 3fr 3fr;
    align-items: center;

    width: 69.4rem;
    padding: 1rem 0;
    @media (max-width: 560px) {
      width: 60rem;
    }
    @media (max-width: 460px) {
      width: 48rem;
    }
  }
  tbody tr {
    &:hover {
      background-color: #eee;
      border-radius: 1rem;
    }
  }
  th {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  td {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
  .left {
    text-align: left;
  }
  .center {
    text-align: center;
  }
  .font-light {
    font-weight: 400;
  }
  .level {
    background-color: #646464;
    border-radius: 1rem;
    color: #fff;
    font-size: 1.2rem;

    min-width: 6rem;
    max-width: 6rem;
    padding: 1rem 1.2rem 0.6rem;
    margin: 0 auto;
  }
`;
