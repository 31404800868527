import styled from 'styled-components';

export const HomeContainer = styled.div`
  .top-section {
    border-bottom: 1px solid var(--primary-color);

    padding: 1.6rem 1.6rem 5.2rem 1.6rem;
    margin: 0 auto;
    .data-course {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1.6rem;

      margin: 2rem 0;
      @media (max-width: 740px) {
        display: flex;
        flex-direction: column;
        max-width: 46rem;
        margin: 0 auto;
      }
      .course-name {
        background-color: var(--black-background);
        border: 2px solid var(--primary-color);
        border-radius: 1rem;
        height: 15rem;
        @media (max-width: 740px) {
          padding-bottom: 0.8rem;
          order: 1;
        }
        h3 {
          font-size: 1.4rem;
          margin: 1.6rem 0;
          text-align: center;
          @media (min-width: 1600px) {
            font-size: 1.6rem;
          }
          @media (max-width: 901px) {
            font-size: 1.8rem;
          }
          @media (max-width: 801px) {
            font-size: 1.6rem;
          }
          @media (max-width: 701px) {
            font-size: 1.5rem;
          }
        }
        p {
          font-size: 1.2rem;

          max-height: 7rem;
          padding: 0 2.6rem;
          overflow: auto;
          ::-webkit-scrollbar-track {
            background-color: #c4c4c4;
            border-radius: 20px;
          }
          ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            padding: 10px 0;
          }
          ::-webkit-scrollbar-thumb {
            background: #646464;
            border-radius: 20px;
          }
          @media (min-width: 1600px) {
            font-size: 1.4rem;
          }
          @media (max-width: 901px) {
            font-size: 1.4rem;
            padding: 0 1rem;
          }
          @media (max-width: 801px) {
            font-size: 1.2rem;
            line-height: 1.2rem;
            padding: 0 1.2rem;
          }
          @media (max-width: 701px) {
            font-size: 1.1rem;
          }
        }
      }
      iframe {
        border-radius: 1rem;
        width: 100%;
      }
      .course-prices {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        @media (max-width: 740px) {
          order: 2;
        }
        p {
          font-size: 1.6rem;
          font-weight: 800;
          line-height: 1.6rem;
          @media (max-width: 1400px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
          }
          @media (max-width: 1100px) {
            font-size: 1.2rem;
            line-height: 1.2rem;
          }
          @media (max-width: 960px) {
            font-size: 0.9rem;
            line-height: 1rem;
          }
          @media (max-width: 740px) {
            font-size: 1.2rem;
            line-height: 1.2rem;
          }
        }
      }
      .price {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media (max-width: 740px) {
          height: 4rem;
          margin-top: 0.8rem;
        }
      }
      .full-price {
        font-weight: 800;
        font-size: 1.8rem;
        line-height: 1.8rem;
        @media (max-width: 1400px) {
          font-size: 1.6rem;
          line-height: 1.6rem;
        }
        @media (max-width: 1100px) {
          font-size: 1.4rem;
          line-height: 1.4rem;
        }
        @media (max-width: 960px) {
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
        @media (max-width: 740px) {
          font-size: 1.4rem;
          line-height: 1.4rem;
        }
      }
      .discount-price {
        color: #ff0000;
        font-weight: 800;
        font-size: 1.8rem;
        line-height: 1.8rem;
        @media (max-width: 1400px) {
          font-size: 1.6rem;
          line-height: 1.6rem;
        }
        @media (max-width: 1200px) {
          font-size: 1.4rem;
          line-height: 1.4rem;
        }
        @media (max-width: 960px) {
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
        @media (max-width: 740px) {
          font-size: 1.4rem;
          line-height: 1.4rem;
        }
      }
      .final-price {
        color: var(--primary-color);
        font-weight: 800;
        font-size: 2.2rem;
        line-height: 2.2rem;
        @media (max-width: 1400px) {
          font-size: 1.8rem;
          line-height: 1.8rem;
        }
        @media (max-width: 1100px) {
          font-size: 1.6rem;
          line-height: 1.6rem;
        }
        @media (max-width: 960px) {
          font-size: 1.4rem;
          line-height: 1.4rem;
        }
        @media (max-width: 740px) {
          font-size: 1.6rem;
          line-height: 1.6rem;
        }
      }
    }
  }
  .time {
    background: var(--black-background);
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    text-align: center;

    padding: 8px 32px;
    margin: -36px auto 0;
    max-width: 75%;
    align-self: center;

    @media (max-width: 901px) {
      max-width: 90%;
      padding: 8px 16px;
    }
    h3 {
      margin-bottom: 6px;
      @media (max-width: 901px) {
        font-size: 16px;
        line-height: 16px;
      }
      @media (max-width: 701px) {
        font-size: 14px;
        line-height: 14px;
      }
      @media (max-width: 420px) {
        font-size: 12px;
        line-height: 12px;
      }
    }
    h2 {
      font-size: 24px;
      line-height: 24px;

      @media (max-width: 901px) {
        font-size: 20px;
        line-height: 20px;
      }
      @media (max-width: 701px) {
        font-size: 16px;
        line-height: 16px;
      }
      @media (max-width: 420px) {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
  .bottom-section {
    max-height: calc(100vh - 33rem);
    padding: 2rem 0;
    margin-bottom: 1rem;
    overflow: auto;
    @media (max-width: 740px) {
      max-height: calc(100vh - 36rem);
    }
    ::-webkit-scrollbar-track {
      background-color: #c4c4c4;
      border-radius: 20px;
    }
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      padding: 10px 0;
    }
    ::-webkit-scrollbar-thumb {
      background: #646464;
      border-radius: 20px;
    }
  }
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 28px;

  padding: 16px 32px 0 32px;
  @media (max-width: 1000px) {
    padding: 16px 16px 0 16px;
  }
  @media (max-width: 701px) {
    padding: 24px 16px 0 16px;
  }
  @media (max-width: 680px) {
    display: flex;
    flex-direction: column;
    max-width: 460px;
    margin: 0 auto;
  }
  label {
    display: block;

    color: #fff;
    font-size: 12px;
    font-weight: 800;
    line-height: 12px;

    margin-bottom: 6px;
  }
  input {
    background: #fff;
    border: solid 1px #eee;
    border-radius: 10px;
    font-size: 12px;

    display: block;

    width: 100%;
    height: 26px;
    padding: 4px;
    margin-bottom: 12px;
    transition: 0.2s;
    &::placeholder {
      font-size: 14px;
    }
    @media (max-width: 420px) {
      height: 32px;
      padding: 8px;
    }
  }
  input:hover,
  input:focus {
    background-color: #fff;
    border: 1px solid var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
    outline: none;
  }
  .data-card {
    display: flex;
    align-items: center;
    @media (max-width: 801px) {
      flex-direction: column;
      flex: 1;
      min-width: 100%;
    }
    .date {
      margin-right: 8px;
      @media (max-width: 680px) {
        display: flex;
        flex-direction: column;
        min-width: 100%;
      }
      .input-date {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;

        width: 140px;
        @media (max-width: 801px) {
          width: 100%;
        }
      }
    }
    .security-code {
      @media (max-width: 801px) {
        width: 100%;
      }
    }
  }
  select {
    background: #fff;
    border: solid 1px #eee;
    border-radius: 10px;
    font-size: 12px;

    display: block;

    width: 100%;
    height: 26px;
    padding: 4px;
    margin-bottom: 12px;
    transition: 0.2s;
    &::placeholder {
      font-size: 14px;
    }
    &:hover,
    &:focus {
      background-color: #fff;
      border: 1px solid var(--primary-color);
      box-shadow: 0 0 0 1px var(--primary-color);
      outline: none;
    }
    @media (max-width: 420px) {
      height: 32px;
      padding: 8px;
    }
  }
  .info-boleto {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    max-height: 100%;
    padding-top: 16px;

    p {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 1.5rem;

      &::before {
        content: '';
        display: inline-block;

        background-color: var(--primary-color);
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin-right: 8px;
      }
      @media (max-width: 901px) {
        margin-bottom: 4px;
      }
      @media (max-width: 680px) {
        order: 2;
      }
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    img {
      @media (max-width: 680px) {
        margin-bottom: 1.6rem;
      }
    }
    .checked-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .checkbox {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 1.6rem 0;
      input {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.5rem;
        cursor: pointer;
      }
      label {
        font-size: 1.1rem;

        width: 100%;
        text-align: justify;
        cursor: pointer;
      }
    }
    button {
      background: var(--primary-color);
      border: 1px solid var(--primary-color);
      border-radius: 10px;
      color: var(--black-background);

      font-weight: 800;
      font-size: 16px;
      line-height: 17px;

      width: 200px;
      padding: 4px;
      margin-bottom: 10px;
      &:disabled {
        color: white;
        border-color: grey;
        background-color: grey;
      }
    }
  }
`;
